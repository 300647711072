export const MonthList = [
    { key: 'janvier', value: 'janvier', text: 'EN JANVIER' },
    { key: 'fevrier', value: 'fevrier', text: 'EN FEVRIER' },
    { key: 'mars', value: 'mars', text: 'EN MARS' },
    { key: 'avril', value: 'avril', text: 'EN AVRIL' },
    { key: 'mai', value: 'mai', text: 'EN MAI' },
    { key: 'juin', value: 'juin', text: 'EN JUIN' },
    { key: 'juillet', value: 'juillet', text: 'EN JUILLET' },
    { key: 'aout', value: 'aout', text: 'EN AOUT' },
    { key: 'septembre', value: 'septembre', text: 'EN SEPTEMBRE' },
    { key: 'octobre', value: 'octobre', text: 'EN OCTOBRE' },
    { key: 'novembre', value: 'novembre', text: 'EN NOVEMBRE' },
    { key: 'decembre', value: 'decembre', text: 'EN DECEMBRE' },
];
