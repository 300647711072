import React, { Component } from 'react';
import { render } from 'react-dom';

import { config } from 'config';
import { HtmlContainer } from 'modules/shared-ui/components';

import { MapProps } from './map-type';

import styles from './map-component.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;

const createInfoWindow = (e: google.maps.MapMouseEvent, map: google.maps.Map, content: string): void => {
    const infoWindow = new google.maps.InfoWindow({
        content: '<div id="infoWindow" />',
        position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    });
    infoWindow.addListener('domready', () => {
        render(<HtmlContainer htmlContent={content} />, document.getElementById('infoWindow'));
    });
    infoWindow.open(map);
};

class Map extends Component<MapProps> {
    constructor(props: MapProps) {
        super(props);
        this.onScriptLoad = this.onScriptLoad.bind(this);
    }

    componentDidMount(): void {
        if (typeof google === 'undefined') {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?key=${config.apiKey.googleMap}`;
            const x = document.getElementsByTagName('script')[0];
            if (x && x.parentNode) {
                x.parentNode.insertBefore(s, x);
                // Below is important.
                // We cannot access google.maps until it's finished loading
                s.addEventListener('load', () => {
                    this.onScriptLoad();
                });
            }
        } else {
            this.onScriptLoad();
        }
    }

    onScriptLoad(): void {
        const { id, options, markers } = this.props;
        const map = new google.maps.Map(document.getElementById(id), {
            ...options,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
        });
        if (markers && markers.length > 0) {
            markers.forEach(m => {
                const { title, lat, lng, content, icon } = m;

                const marker = new google.maps.Marker({
                    position: { lat, lng },
                    map,
                    title,
                    icon,
                });

                if (content) {
                    marker.addListener('click', (e: google.maps.MapMouseEvent) => {
                        createInfoWindow(e, map, content);
                    });
                }
            });
        }
    }

    render(): React.ReactNode {
        const { id } = this.props;
        return <div className={styles.map} id={id} />;
    }
}

export { Map };
