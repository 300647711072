import React, { ComponentType } from 'react';
import { SEO } from '../../components';

export const WithSEO = <P extends {}>(
    getPageTitle?: (props?: P) => string | undefined,
    getDescription?: (props?: P) => string | undefined,
    getKeywords?: (props?: P) => string[] | undefined,
    getImage?: (props?: P) => string | undefined,
) => {
    return (Component: ComponentType<P>) => {
        const WithSeoComponent: React.FC<P> = (props: P) => {
            const pageTitle = getPageTitle ? getPageTitle(props) : undefined;
            const description = getDescription ? getDescription(props) : undefined;
            const keywords = getKeywords ? getKeywords(props) : undefined;
            const image = getImage ? getImage(props) : undefined;

            return (
                <React.Fragment>
                    <SEO pageTitle={pageTitle} description={description} keywords={keywords} img={image} />
                    <Component {...props} />
                </React.Fragment>
            );
        };

        return WithSeoComponent;
    };
};
