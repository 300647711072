import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { tracerModule } from '../../redux';
import { WithRemoteActions, WithStatelessDatasFetching } from '../../hocs';
import { LightStore } from '../../models';
import { newsletterService, storeService } from '../../services/API/backend';
import { cacheQueryService } from '../../services';

import { FooterDispatchProps, FooterInjectedProps, NewsletterRemoteActionsProps } from './footer-type';

const mapDispatchToProps = (dispatch: Dispatch): FooterDispatchProps => ({
    showAdminPanel: () => dispatch(tracerModule.action.adminPanel.show()),
});

const reduxConnect = connect<{}, FooterDispatchProps, {}, {}>(undefined, mapDispatchToProps);

const enhancer = compose<React.ComponentType>(
    WithStatelessDatasFetching<LightStore[], FooterInjectedProps>(
        cacheQueryService.cache('getStores', storeService.getAll, true),
        undefined,
        'error',
        'stores',
    ),
    WithRemoteActions<NewsletterRemoteActionsProps>({
        createNewsletterLight: {
            action: newsletterService.createLight,
            successMessage: 'Vous êtes maintenant inscrit à la newsletter',
            errorMessages: [
                {
                    message: 'Erreur à la création du dossier',
                    code: 10.105,
                },
            ],
        },
    }),
    reduxConnect,
);

export default enhancer;
