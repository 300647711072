import { createCookie, readCookie } from '../utils';
import { TracerConsent, TracerTypeValues, TracerType } from '../models/tracer';

export const cookieConsentMap = {
    2: 'plistes-analytics',
    3: 'plistes-marketing',
};

export const tracerConsentService = {
    getByType(type: TracerType): TracerConsent {
        if (type === TracerTypeValues.UKNOWN || type === TracerTypeValues.FUNCTIONNAL_TRACER_TYPE) {
            return { type, value: true, readonly: true };
        }

        const cookieName: string = cookieConsentMap[type];

        if (cookieName) {
            const cookie = readCookie(cookieName);
            if (cookie) {
                return { type, value: cookie.value === 'true' };
            }
        }

        return { type, value: undefined };
    },
    getAll(): TracerConsent[] {
        const result: TracerConsent[] = [];
        Object.values(TracerTypeValues).forEach(tracerType => {
            if (tracerType === TracerTypeValues.UKNOWN || tracerType === TracerTypeValues.FUNCTIONNAL_TRACER_TYPE) {
                result.push({ type: tracerType, value: true, readonly: true });
                return;
            }

            const cookieName: string = cookieConsentMap[tracerType];

            if (cookieName) {
                const cookie = readCookie(cookieName);
                if (cookie) {
                    result.push({ type: tracerType, value: cookie.value === 'true' });
                    return;
                }
            }

            result.push({ type: tracerType, value: undefined });
        });

        return result;
    },
    update(tracerType: TracerType, state: boolean): void {
        if (tracerType === TracerTypeValues.UKNOWN || tracerType === TracerTypeValues.FUNCTIONNAL_TRACER_TYPE) {
            return;
        }

        const cookieName: string = cookieConsentMap[tracerType];
        if (state) {
            // Cookies de consentement : validité 6 mois
            createCookie(cookieName, 'true', 180);
        } else {
            createCookie(cookieName, 'false');
        }
    },
    acceptAll(): void {
        Object.values(TracerTypeValues).forEach(v => {
            tracerConsentService.update(v, true);
        });
    },
    declineAll(): void {
        Object.values(TracerTypeValues).forEach(v => {
            tracerConsentService.update(v, false);
        });
    },
};
