import { useCallback, useState } from 'react';

export const useForceUpdate = (): (() => void) => {
    const [, dispatch] = useState<{}>(Object.create(null));

    const memoizedDispatch = useCallback((): void => {
        dispatch(Object.create(null));
    }, [dispatch]);
    return memoizedDispatch;
};
