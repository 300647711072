import { Dispatch, AnyAction, MiddlewareAPI } from 'redux';

import { REQUEST_REGISTER } from '../modules/authentication/sub-modules';
import { authenticationModule } from '../modules';

export const authenticationMiddleware = ({ dispatch }: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    switch (action.type) {
        case REQUEST_REGISTER:
            // TODO: voir a deconnecter au debut du tunnel de création de liste
            dispatch(authenticationModule.action.logout());
            break;
        default:
            break;
    }

    return next(action);
};
