import { useState, useEffect } from 'react';
import { printReadyEventName } from '../utils';

export const usePrintReady = (isReadyToPrint: boolean): void => {
    const [isEventDispatched, setIsEventDispatched] = useState(false);

    useEffect(() => {
        if (isReadyToPrint && !isEventDispatched) {
            // -- dès que la liste de produit est disponible, on dispatch un event pour indiquer que la page est prête à être imprimée
            window.dispatchEvent(new Event(printReadyEventName));
            setIsEventDispatched(true);
        }
    }, [isReadyToPrint, isEventDispatched]);
};

interface UseDelayedPrintReadyProps {
    dataLoaded: (isReady: boolean) => void;
}

export const useDelayedPrintReady = (): UseDelayedPrintReadyProps => {
    const [isEventDispatched, setIsEventDispatched] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (isDataLoaded && !isEventDispatched) {
            // -- dès que la liste de produit est disponible, on dispatch un event pour indiquer que la page est prête à être imprimée
            window.dispatchEvent(new Event(printReadyEventName));
            setIsEventDispatched(true);
        }
    }, [isDataLoaded, isEventDispatched]);

    return {
        dataLoaded: setIsDataLoaded,
    };
};
