import { structureService as cmsStructureService } from './API/cms';
import { structureService as catalogueStructureService } from './API/backend';
import { Structure as GlobalStructure, Node as GlobalNode } from '../models';
import { Structure as CatalogueStructure, Node as CatalogueNode } from './API/backend/internal-models';

import { createPath, createPathId } from '../utils';

const convertCatalogueNodesToGlobalNodes = (catalogueNodes: CatalogueNode[], type: number): GlobalNode[] => {
    const result: GlobalNode[] = [];

    if (!catalogueNodes || catalogueNodes.length === 0) {
        return result;
    }

    for (let i = 0; i < catalogueNodes.length; i += 1) {
        const catalogueNode = catalogueNodes[i];

        const namePath = createPath(catalogueNode.name);

        result.push({
            name: catalogueNode.name,
            path: namePath,
            pathId: `${createPathId(namePath, catalogueNode.id)}`,
            nodes: convertCatalogueNodesToGlobalNodes(catalogueNode.nodes, type),
            isDynamic: true,
        });
    }

    return result;
};

const nodesEnhancer = {
    enhance: (node: GlobalNode, catalogueStructure: CatalogueStructure | undefined): GlobalNode[] => {
        const result: GlobalNode[] = [];
        if (!node) {
            return result;
        }

        const currentNode = node;
        const enhancedNodes = nodesEnhancer.enhanceMany(node.nodes, catalogueStructure);
        let additionalNodes: GlobalNode[] | undefined;

        if (!currentNode.isCMS) {
            switch (currentNode.contentTemplate) {
                case 'CatalogueEntry':
                case 'CreationListeEntry':
                case 'PartenairesEntry':
                case 'DonsEntry':
                case 'EspacePersoEntry':
                case 'MariageEntry':
                case 'SignEntry':
                case 'ListesEntry':
                    // Entrée de module
                    currentNode.isModuleEntry = true;
                    break;
                case 'UniverseEntry':
                    // On pousse la sous hierarchie
                    currentNode.isSubModuleEntry = true;
                    if (catalogueStructure && catalogueStructure.universes) {
                        additionalNodes = convertCatalogueNodesToGlobalNodes(catalogueStructure.universes, 1);
                    }
                    break;
                case 'BrandEntry':
                    // On pousse la sous hierarchie
                    if (catalogueStructure && catalogueStructure.brands) {
                        additionalNodes = convertCatalogueNodesToGlobalNodes(catalogueStructure.brands, 2);
                    }
                    break;
                // Autres cas
                default:
                    break;
            }
        }

        currentNode.nodes = [];
        if (additionalNodes && additionalNodes.length > 0) {
            currentNode.nodes = currentNode.nodes.concat(additionalNodes);
        }

        if (enhancedNodes && enhancedNodes.length > 0) {
            currentNode.nodes = currentNode.nodes.concat(enhancedNodes);
        }

        result.push(currentNode);

        return result;
    },

    enhanceMany: (
        nodes: GlobalNode[] | undefined,
        catalogueStructure: CatalogueStructure | undefined,
    ): GlobalNode[] | undefined => {
        if (!nodes || nodes.length === 0) {
            return nodes;
        }

        let finalResult: GlobalNode[] = [];
        for (let i = 0; i <= nodes.length; i += 1) {
            const enhancedNodes = nodesEnhancer.enhance(nodes[i], catalogueStructure);
            if (enhancedNodes) {
                finalResult = finalResult.concat(enhancedNodes);
            }
        }

        return finalResult;
    },
};

export const structureService = {
    get: async (): Promise<GlobalStructure> => {
        // On récupère les hierarchies depuis les différents systèmes
        const structures = await Promise.all([cmsStructureService.get(), catalogueStructureService.get()]);
        if (!structures[0]) {
            return structures[0];
        }

        structures[0].sectionNodes = nodesEnhancer.enhanceMany(structures[0].sectionNodes, structures[1]);
        return structures[0];
    },
};
