import React from 'react';

import { Trans } from 'react-i18next';

import { Container } from '../base-components';

export const GDPRListCreation: React.FC<{ lastPage?: boolean }> = ({ lastPage = false }: { lastPage?: boolean }) => {
    return (
        <Container>
            <p>
                {!lastPage && (
                    <span>
                        <Trans i18nKey="gdpr.listCreation.part1">
                            part1
                            <a
                                href="https://www.printemps.com/fr/fr/protection-donnees-personnelles"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ici
                            </a>
                            part2
                        </Trans>
                        <br />
                    </span>
                )}
                <Trans i18nKey="shared.mentions.requiredFields" />
                {!lastPage && (
                    <span>
                        <br />
                        <Trans i18nKey="gdpr.listCreation.part2" />
                    </span>
                )}
            </p>
        </Container>
    );
};
