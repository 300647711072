import React, { useCallback } from 'react';

import { BannerGroup } from './components';

import { BannerProps } from './banner-type';
import enhancer from './banner-enhancer';

const banner: React.FC<BannerProps> = React.memo(({ ids, onLoaded, sessionData, updateSessionData }: BannerProps) => {
    const onClose = useCallback(
        (id: string) => {
            if (!id) {
                return;
            }

            const bannerState =
                sessionData && sessionData.bannersState
                    ? sessionData.bannersState.find(b => b.bannerId === id)
                    : undefined;
            if (bannerState) {
                bannerState.isClosed = true;
                updateSessionData(sessionData);
            } else {
                updateSessionData({
                    bannersState: [
                        ...(sessionData && sessionData.bannersState ? sessionData.bannersState : []),
                        { bannerId: id, isClosed: true },
                    ],
                });
            }
        },
        [sessionData, updateSessionData],
    );

    if (!ids || ids.length === 0) {
        return <React.Fragment />;
    }

    let filteredId;
    if (sessionData && sessionData.bannersState) {
        filteredId = ids.filter(i => sessionData.bannersState.findIndex(b => b.bannerId === i && b.isClosed) === -1);
    } else {
        filteredId = ids;
    }

    if (!filteredId.length) {
        return <React.Fragment />;
    }

    return (
        <BannerGroup
            filterParams={[{ key: 'ids', value: filteredId }]}
            bannerOrderList={filteredId}
            onLoaded={onLoaded}
            onClose={onClose}
        />
    );
});

export const Banner = enhancer(banner);
