import React from 'react';
import { Route } from 'react-router-dom';

import { DefaultLayout } from 'modules/shared/layouts';
import { UnknownContent } from 'modules/shared/components';

import { HomeAltPage, HomePage } from 'modules/bootstrap/pages';

export const ContentSelector: React.FC<{ template: string }> = ({ template, ...rest }: { template: string }) => {
    switch (template) {
        case 'home':
            return (
                <Route
                    exact
                    render={() => (
                        <DefaultLayout>
                            <HomePage {...rest} />
                        </DefaultLayout>
                    )}
                />
            );
        case 'home-v2':
            return (
                <Route
                    exact
                    render={() => (
                        <DefaultLayout>
                            <HomeAltPage {...rest} />
                        </DefaultLayout>
                    )}
                />
            );
        default:
            return <UnknownContent />;
    }
};
