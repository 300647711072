import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { RootState } from '../../redux/store';
import { NavigationElement } from '../../models';

import { BannerOwnProps, BannerStateProps, BannerSessionProps } from './banner-type';
import { WithSessionStorage } from '../../hocs';

const getBannerIds = (navigationElement?: NavigationElement): string[] => {
    const result: string[] = [];
    if (!navigationElement) {
        return result;
    }

    if (navigationElement.bannerId) {
        result.push(navigationElement.bannerId);
    }

    if (!navigationElement.parent) {
        return result;
    }

    return result.concat(getBannerIds(navigationElement.parent));
};

const mapStateToProps = (state: RootState): BannerStateProps => {
    const ids = getBannerIds(state && state.navigation ? state.navigation.currentPage : undefined);
    return {
        ids,
    };
};

const reduxConnect = connect<BannerStateProps, undefined, BannerOwnProps, RootState>(mapStateToProps);

const enhancer = compose<ComponentType<BannerOwnProps>>(
    WithSessionStorage<BannerSessionProps>('banners'),
    reduxConnect,
);

export default enhancer;
