import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { Redirect } from 'react-router-dom';
import { Module, FetchState, FetchActionDescriptor, ThunkDispatch } from '../../../redux/utils/type';

import { ErrorMessage } from '../../../components';

interface DispatchProps<U = undefined> {
    fetchDatas: (params: U) => void;
}

interface StateProps<T> {
    data: T | null;
    isLoading: boolean;
    error: Error | string | null;
}

export interface WithDatasFetchingInjectedProps<T> {
    data: T | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WithDatasFetchingProps<T, U = undefined> = StateProps<T> & DispatchProps<U> & U & any;

export const WithDatasFetching = <T, U = undefined>(
    module: Module<FetchState<T>, FetchActionDescriptor<T, ThunkAction<Promise<void>, {}, {}, AnyAction>>>,
    remoteFetchAction: (params: U) => Promise<T>,
    redirectOnNotFound: boolean = false,
) => {
    return <P extends WithDatasFetchingInjectedProps<T>>(Component: React.ComponentType<P>) => {
        class WithDatasFetchingWrapper extends React.Component<WithDatasFetchingProps<T, U> & P> {
            componentDidMount(): void {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { data, isLoading, fetchDatas, error, children, ...rest } = this.props;
                if (fetchDatas) {
                    if (!data && !isLoading && !error) {
                        fetchDatas(rest);
                    }
                }
            }

            render(): React.ReactNode {
                const { error, ...rest } = this.props;

                if (error) {
                    if (error.code === 404 && redirectOnNotFound) {
                        return <Redirect to="/nofound" />;
                    }

                    const errorMessage: string = typeof error === 'string' ? error : error.message;
                    return <ErrorMessage error={errorMessage} />;
                }
                return <Component {...(rest as P)} />;
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mapStateToProps = (states: any): StateProps<T> => {
            const subState = states[module.storeName] as FetchState<T>;
            return {
                data: subState.data,
                isLoading: subState.isLoading,
                error: subState.error,
            };
        };

        const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps<U> => ({
            fetchDatas: (params: U) => dispatch(module.action.fetchAction(() => remoteFetchAction(params))),
        });

        return connect<StateProps<T>, DispatchProps<U>>(
            mapStateToProps,
            mapDispatchToProps,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )(WithDatasFetchingWrapper);
    };
};
