import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { config } from 'config';
import { concatUrl } from 'modules/shared/utils';
import { cmsTokenService } from './sub-services';

const httpLink = createHttpLink({
    uri: concatUrl([config.cms.baseUrl, config.cms.graphql.url]),
});

const authLink = setContext(
    async (_, { headers }): Promise<object> => {
        const token = await cmsTokenService.getToken();
        // TODO: make it cleaner
        const previewing = window.location.pathname.includes('preview');
        if (previewing) {
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : '',
                    'x-unpublished': true,
                },
            };
        }
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    },
);

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
