import React from 'react';

import { ClassicButton } from 'modules/shared-ui/components';

import { getPlistesCDNUrl } from '../../utils';
import { useRouter } from '../../hooks';

import styles from './not-found-component.module.css';

export const NotFound: React.FC = () => {
    const { history } = useRouter();
    if (window.location.search !== '?errorType=404') {
        window.location.replace(`${window.location.href}?errorType=404`);
    }

    return (
        <React.Fragment>
            <div className={styles.invisibleDiv}>
                <picture>
                    <source
                        media="(max-width: 767px)"
                        srcSet={getPlistesCDNUrl('/img/commun/autres/visuel-notfound-mobile.jpg')}
                    />
                    <source
                        media="(max-width: 1024px)"
                        srcSet={getPlistesCDNUrl('/img/commun/autres/visuel-notfound-tablet.jpg')}
                    />
                    <img
                        src={getPlistesCDNUrl('/img/commun/autres/visuel-notfound-desktop.jpg')}
                        alt="Not Found"
                        className={styles.backgroundImage}
                    />
                </picture>
            </div>
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <picture>
                        <source
                            media="(max-width: 767px)"
                            srcSet={getPlistesCDNUrl('/img/commun/autres/visuel-notfound-mobile.jpg')}
                        />
                        <source
                            media="(max-width: 1024px)"
                            srcSet={getPlistesCDNUrl('/img/commun/autres/visuel-notfound-tablet.jpg')}
                        />
                        <img
                            src={getPlistesCDNUrl('/img/commun/autres/visuel-notfound-desktop.jpg')}
                            alt="Not Found"
                            className={styles.backgroundImage}
                        />
                    </picture>
                    <div className={styles.midLayer} />
                    <div className={styles.content}>
                        <div className={styles.errorCode}>404</div>
                        <div className={styles.title}>OUUPS... CETTE PAGE AURAIT-ELLE PRIS LA FUITE ?</div>
                        <div className={styles.subTitle}>
                            Pendant que nous partons à sa recherche, trouvez l&apos;inspiration pour votre liste.
                        </div>
                        <div className={styles.homeBtn}>
                            <ClassicButton
                                text="Retourner à l'accueil"
                                onClick={() => {
                                    history.push('/');
                                }}
                            />{' '}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
