import { compose } from 'redux';
import { WithFetchDatasFromCMS, WithFetchDatasFromCMSInputProps, WithCMSSEO } from 'modules/shared-cms/hocs';

import { QUERY } from './home-query';
import { HomeLoader } from './home-loader';
import { HomeProps } from '.';

const enhancer = compose<React.ComponentType<WithFetchDatasFromCMSInputProps>>(
    WithFetchDatasFromCMS<HomeProps>(QUERY, HomeLoader, 'queryHomeContents'),
    WithCMSSEO(undefined, true),
);
export default enhancer;
