import React from 'react';
import { Container } from 'vendors/semantic-ui';
import { Header, Footer, Breadcrumb, BackToTop } from '../../components';

import styles from './default-layout.module.css';

export const DefaultLayout: React.FC = ({ children }) => {
    return (
        <React.Fragment>
            <BackToTop />
            <Header />
            <Container className={`fluid ${styles.mainContainer}`}>
                <Breadcrumb />
                {children}
            </Container>
            <Footer />
        </React.Fragment>
    );
};
