import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Header, Icon } from 'vendors/semantic-ui';
import { ConfirmProps, ConfirmState } from './confirm-type';

import styles from './confirm-component.module.css';

let resolve: (result: boolean) => void;
const defaultProps = {
    title: 'Confirmation',
    message: 'Are you sure?',
};

class ConfirmDialog extends Component<ConfirmProps, ConfirmState> {
    static create(): ConfirmDialog {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        const element = React.createRef<ConfirmDialog>();
        ReactDOM.render(<ConfirmDialog ref={element} />, containerElement);
        if (!element.current) {
            throw new Error('Error on confirm initialisation');
        }

        return element.current;
    }

    constructor(props: ConfirmProps) {
        super(props);
        this.state = {
            isOpen: false,
            message: defaultProps.message,
        };
    }

    handleCancel = (): void => {
        this.setState({ isOpen: false });
        resolve(false);
    };

    handleConfirm = (): void => {
        this.setState({ isOpen: false });
        resolve(true);
    };

    show = (message: string): Promise<boolean> => {
        this.setState({ isOpen: true, message });
        return new Promise(res => {
            resolve = res;
        });
    };

    render = (): JSX.Element => {
        const { isOpen, message } = this.state;
        return (
            <Modal size="small" open={isOpen}>
                <Header icon="question circle" content={defaultProps.title} className={styles.header} />
                <Modal.Content>
                    <p className={styles.msg}>{message}</p>
                </Modal.Content>
                <Modal.Actions className={styles.actions}>
                    <button
                        type="button"
                        className={`${styles.classicButton} ${styles.cancelButton}`}
                        onClick={this.handleCancel}
                    >
                        <Icon name="remove" /> Annuler
                    </button>
                    <button type="button" className={`${styles.classicButton}`} onClick={this.handleConfirm}>
                        <Icon name="checkmark" /> Oui
                    </button>
                </Modal.Actions>
            </Modal>
        );
    };
}

export default ConfirmDialog;
