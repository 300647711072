// eslint-disable-next-line @typescript-eslint/no-explicit-any
let datas: { key: string; payload: any; persistOnRouteChange?: boolean }[] = [];

export const cacheQueryService = {
    cache: <T>(key: string, query: () => Promise<T>, persistOnRouteChange?: boolean): (() => Promise<T> | T) => {
        return async () => {
            const cache = datas.find(f => f.key === key);
            if (cache) {
                return cache.payload;
            }

            const data = await query();

            datas.push({ key, payload: data, persistOnRouteChange });
            return data;
        };
    },
    cacheWithParams: <T, U>(
        key: string,
        query: (params: U) => Promise<T>,
        persistOnRouteChange?: boolean,
    ): ((params: U) => Promise<T> | T) => {
        return async (params: U) => {
            const fullKey = `${key}_${JSON.stringify(params)}`;
            const cache = datas.find(f => f.key === fullKey);
            if (cache) {
                return cache.payload;
            }

            const data = await query(params);

            datas.push({ key: fullKey, payload: data, persistOnRouteChange });
            return data;
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearRouteCache: () => {
        datas = datas.filter(d => d.persistOnRouteChange);
    },
};
