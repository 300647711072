import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { fr, en } from './locales';

const options = {
    interpolation: {
        escapeValue: false,
    },

    resources: {
        fr: {
            common: fr['fr-FR'],
        },
        en: {
            common: en.en,
        },
    },

    fallbackLng: 'fr',

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
    },
};

i18n.use(LanguageDetector).init(options);

export default i18n;
