/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Responsive } from 'vendors/semantic-ui';

import { configUI } from 'config';

import { layoutModule } from '../../redux/modules';
import { Menu, MenuItem, SectionMenuItem } from '../menu';
import { Logo, Banner, ContextualMenu } from '..';

import { useRouter } from '../../hooks';
import { Node, SectionNode } from '../../models';

import { Props } from './header-type';

import enhancer from './header-enhancer';

import styles from './header-component.module.css';
import { concatUrl } from '../../utils';

const findRemplacementChildNode = (workingNode: Node): Node | undefined => {
    const replacementChildNode = workingNode.nodes
        ? workingNode.nodes.find(n => !n.path || n.path === '/' || n.path === '')
        : undefined;
    if (replacementChildNode && (!!replacementChildNode.isHidden || !!replacementChildNode.isModuleEntry)) {
        return findRemplacementChildNode(replacementChildNode);
    }

    return replacementChildNode;
};

const convertStructureToMenuItems = (nodes?: Node[], parent?: MenuItem, parentPath?: string): MenuItem[] => {
    if (!nodes || nodes.length === 0) {
        return [];
    }

    let result: MenuItem[] = [];
    for (let i = 0; i < nodes.length; i += 1) {
        let workingNode: Node | undefined = nodes[i];
        let workingChildren = workingNode.nodes ? workingNode.nodes.slice() : undefined;
        let nodePath = workingNode.path;

        if (!!workingNode.isHidden || !!workingNode.isModuleEntry) {
            const replacementChildNode = findRemplacementChildNode(workingNode);
            if (replacementChildNode) {
                nodePath = concatUrl([nodePath || '/', replacementChildNode.path ? replacementChildNode.path : '/']);
                workingChildren = workingNode.nodes ? workingNode.nodes.filter(n => n !== replacementChildNode) : [];
                workingChildren = workingChildren.concat(
                    replacementChildNode.nodes ? replacementChildNode.nodes.slice() : [],
                );
                workingNode = replacementChildNode;
            } else {
                if (workingNode.nodes && workingNode.nodes.length > 0 && parent) {
                    result = result.concat(
                        convertStructureToMenuItems(
                            workingNode.nodes,
                            parent,
                            concatUrl([parentPath || '/', workingNode.path || '/']),
                        ),
                    );
                }

                workingNode = undefined;
            }
        }

        if (workingNode && workingNode.name && nodePath) {
            let ressourcePath = '';
            let levelPath = '';
            if (workingNode.isShortcut) {
                ressourcePath = workingNode.path || '/';
            } else {
                levelPath = concatUrl([parentPath || '/', nodePath]);
                if (workingNode.pathId) {
                    ressourcePath = concatUrl([parentPath || '/', workingNode.pathId]);
                } else {
                    ressourcePath = levelPath;
                }
            }

            const currentMenuItem: MenuItem = {
                name: workingNode.name,
                ressourcePath,
                levelPath,
                color: workingNode.color,
                isBold: workingNode.isBold,
                ctaStyle: workingNode.ctaStyle,
                parent,
                children: [],
            };

            currentMenuItem.children = convertStructureToMenuItems(workingChildren, currentMenuItem, levelPath);

            result.push(currentMenuItem);
        }
    }

    return result;
};

const convertStructureToSectionMenuItems = (nodes?: SectionNode[]): SectionMenuItem[] => {
    if (!nodes || nodes.length === 0) {
        return [];
    }

    const result: SectionMenuItem[] = [];
    for (let i = 0; i < nodes.length; i += 1) {
        const workingNode: SectionNode | undefined = nodes[i];

        if (workingNode && workingNode.name && workingNode.path && !workingNode.isHidden) {
            const path = concatUrl(['/', workingNode.path]);
            const currentMenuItem: SectionMenuItem = {
                name: workingNode.name,
                ressourcePath: path,
                levelPath: path,
                thumbUrl: workingNode.thumbUrl,
                thumbAssetUrl: workingNode.thumbAssetUrl,
                thumbCtaText: workingNode.thumbCtaText,
                thumbCtaStyle: workingNode.thumbCtaStyle,
                children: [],
            };

            currentMenuItem.children = convertStructureToMenuItems(
                workingNode.nodes,
                currentMenuItem,
                currentMenuItem.levelPath,
            );

            result.push(currentMenuItem);
        }
    }

    return result;
};

const InnerHeader: React.FC<Props> = React.memo(({ data }: Props) => {
    const { location } = useRouter();
    let parsedSearch = '';
    if (location.pathname.toLowerCase().startsWith('/recherche/')) {
        [parsedSearch] = location.pathname
            .toLowerCase()
            .replace('/recherche/', '')
            .split('?');
    }

    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState(parsedSearch);
    const [searchFieldVisible, setSearchFieldVisible] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const isFirstRun = useRef<boolean>(true);
    const headerRef = useRef<HTMLElement>(null);

    const { history } = useRouter();
    const dispatch = useDispatch();

    const updateHeaderHeight = useCallback((): void => {
        const newHeaderHeight = headerRef.current ? headerRef.current.clientHeight : 0;
        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.style.position = 'absolute';
            rootElement.style.top = `${newHeaderHeight}px`;
        }
        setHeaderHeight(newHeaderHeight);
        dispatch(layoutModule.action.updateHeaderHeight(newHeaderHeight));
    }, [dispatch, setHeaderHeight]);

    useEffect(() => {
        if (!isFirstRun.current) {
            updateHeaderHeight();
        }

        isFirstRun.current = false;
        return (): void => {
            const rootElement = document.getElementById('root');
            if (rootElement) {
                rootElement.style.top = 'initial';
                rootElement.style.position = 'initial';
            }
            dispatch(layoutModule.action.updateHeaderHeight(0));
        };
    }, [dispatch, updateHeaderHeight]);

    useEffect(() => {
        setVisible(false);
    }, [history.location.pathname]);

    const onSubElementLoaded = useCallback(() => {
        updateHeaderHeight();
    }, [updateHeaderHeight]);

    const menuElements = useMemo<SectionMenuItem[]>(() => {
        if (!data || !data.sectionNodes) {
            return [];
        }
        return convertStructureToSectionMenuItems(data.sectionNodes);
    }, [data]);

    const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
        setSearchValue(e.currentTarget.value);
    };

    const toggleSideNav: () => void = () => {
        if (searchFieldVisible) {
            toggleSearch();
        }
        setVisible(!visible);
    };

    const toggleSearch: () => void = () => {
        if (visible) {
            toggleSideNav();
        }
        setSearchFieldVisible(!searchFieldVisible);
    };

    return (
        <div className={`${styles.root} elementNotToPrint`}>
            <header ref={headerRef} className={`${styles.header} ui fixed`}>
                {/* Bannière  mobile */}
                <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                    <div className={styles.bannerContainer}>
                        <Banner onLoaded={onSubElementLoaded} />
                    </div>
                </Responsive>
                <Container id={styles.headerContainer} fluid className={styles.headerContainer}>
                    {/* header */}
                    <div className={`${styles.headerLeft} ${styles.headerContent}`}>
                        <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                            {/* Liens du menu en desktop */}
                            <Menu elements={menuElements} top={headerHeight} />
                        </Responsive>
                        <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                            {/* Bouton d'ouverture du menu en mobile */}
                            <button
                                type="button"
                                className={`${styles.menuBtn} ${visible ? styles.active : ''}`}
                                onClick={toggleSideNav}
                            />
                        </Responsive>
                    </div>
                    {/* Logo du printemps - renvoie vers la home */}
                    <div className={`${styles.headerCenter} ${styles.headerContent}`}>
                        <Logo onLoaded={onSubElementLoaded} />
                    </div>
                    <div className={`${styles.headerRight} ${styles.headerContent}`}>
                        <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                            <div className={styles.rightActionsContainer}>
                                {/* Champ de recherche */}
                                <form
                                    className={styles.searchForm}
                                    onSubmit={() => {
                                        history.push(`/recherche/${searchValue}`);
                                    }}
                                >
                                    <input
                                        placeholder="Rechercher un produit à ajouter à ma liste"
                                        value={searchValue}
                                        className={styles.searchInput}
                                        onChange={handleChange}
                                    />
                                    <button type="submit" disabled={searchValue === ''} className={styles.searchBtn} />
                                </form>
                                <ContextualMenu />
                            </div>
                        </Responsive>
                        <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                            <div className={styles.rightActionsContainer}>
                                <button
                                    type="button"
                                    className={searchFieldVisible ? styles.closeSearchBtn : styles.searchBtn}
                                    onClick={toggleSearch}
                                />
                                <ContextualMenu />
                            </div>
                        </Responsive>
                    </div>
                </Container>
            </header>
            {/* Bannière  desktop */}
            <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                <div className={styles.bannerContainer}>
                    <Banner onLoaded={onSubElementLoaded} />
                </div>
            </Responsive>
            {/* Menu déroulant */}
            <Responsive maxWidth={configUI.responsive.tablet.maxWidth}>
                <Menu elements={menuElements} visible={visible} top={headerHeight + 10} />
                {searchFieldVisible && (
                    <form
                        className={`${styles.searchForm}`}
                        style={{ paddingTop: `${headerHeight}` }}
                        onSubmit={() => {
                            if (searchValue !== '') {
                                history.push(`/recherche/${searchValue}`);
                                return true;
                            }
                            return false;
                        }}
                    >
                        <button type="submit" disabled={searchValue === ''} className={styles.searchBtn} />
                        <input
                            placeholder="Rechercher un produit à ajouter à ma liste"
                            value={searchValue}
                            className={styles.searchInput}
                            onChange={handleChange}
                        />
                    </form>
                )}
            </Responsive>
        </div>
    );
});

export const Header = enhancer(InnerHeader);
