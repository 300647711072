import React from 'react';

import styles from './link-button-component.module.css';

export const LinkButton: React.FC<React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>> = ({
    className,
    ...rest
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    return <button {...rest} type="button" className={`${styles['link-button']}${className ? ` ${className}` : ''}`} />;
};
