/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { CtaButton, ClickableContainer } from 'modules/shared-ui/components';

import { useHover } from '../../../../../../hooks';
import { chunk } from '../../../../../../utils';
import { GenericList } from '../../../../../generic-list';
import { MenuItem, SectionMenuItem } from '../../../../menu-type';

import { Props } from './panel-type';

import styles from './panel-component.module.css';

interface MenuPanelProps {
    elements: MenuItem[] | undefined | null;
}

const Level4MenuPanel: React.FC<MenuPanelProps> = ({ elements }: MenuPanelProps) => {
    const listContainerRef = useRef<HTMLDivElement>(null);

    const { bind: topBind, hovered: topIsHovered } = useHover();
    const topRef = useRef(topIsHovered);
    topRef.current = topIsHovered;

    const { bind: botBind, hovered: bottomIsHovered } = useHover();
    const botRef = useRef(bottomIsHovered);
    botRef.current = bottomIsHovered;

    const [scrolledToTop, setScrolledToTop] = useState(false);
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    const items = elements || [];
    const scrollable = items.length > 10;

    const isScrolledToTop = (): boolean => {
        if (listContainerRef && listContainerRef.current) {
            const refCurrent = listContainerRef.current;
            return refCurrent.scrollHeight - refCurrent.scrollTop === 0;
        }
        return false;
    };

    const isScrolledToBottom = (): boolean => {
        if (listContainerRef && listContainerRef.current) {
            const refCurrent = listContainerRef.current;
            return refCurrent.scrollHeight - refCurrent.scrollTop === refCurrent.clientHeight;
        }
        return false;
    };

    const scroll = (scrollDown: boolean): void => {
        if (listContainerRef && listContainerRef.current && items.length > 0) {
            const refCurrent = listContainerRef.current;

            if (scrollDown && !scrolledToBottom) {
                refCurrent.scrollTo(0, refCurrent.scrollTop + 4);
                setScrolledToTop(false);
                setScrolledToBottom(isScrolledToBottom());
            }
            if (!scrollDown && !scrolledToTop) {
                refCurrent.scrollTo(0, refCurrent.scrollTop - 4);
                setScrolledToBottom(false);
                setScrolledToTop(isScrolledToTop());
            }
        }
    };

    const loop = (down: boolean): void => {
        if (topRef.current === true || botRef.current === true) {
            scroll(down);
        }

        if (topRef.current === true || botRef.current === true) {
            setTimeout(() => loop(down), 20);
        }
    };

    const handleHover = (isHovered: boolean, down: boolean): void => {
        if (isHovered) {
            loop(down);
        }
    };

    useEffect(() => {
        handleHover(topIsHovered, false);
    }, [topIsHovered]);

    useEffect(() => {
        handleHover(bottomIsHovered, true);
    }, [bottomIsHovered]);

    if (elements) {
        return (
            <div className={styles.menuLvl4Container}>
                {scrollable && <div className={styles.menuLvl4Scrollbar} {...topBind} />}
                <div
                    ref={listContainerRef}
                    className={`${styles.menuLvl4ListContainer} ${scrollable ? styles.scrollable : ''}`}
                >
                    <GenericList
                        items={elements}
                        itemRenderer={(element: MenuItem, childKey: number) => {
                            return (
                                <NavLink
                                    to={element.ressourcePath}
                                    key={childKey}
                                    className={styles.menuLvl4}
                                    style={element.color ? { color: element.color } : {}}
                                >
                                    {element.name}
                                </NavLink>
                            );
                        }}
                    />
                </div>
                {scrollable && <div className={`${styles.menuLvl4Scrollbar} ${styles.bottom}`} {...botBind} />}
            </div>
        );
    }
    return <React.Fragment />;
};

const buildLevel3 = (elements: MenuItem[] | undefined | null): JSX.Element => {
    if (elements) {
        const dividedElements = chunk(elements, 8);
        return (
            <React.Fragment>
                <div className={styles.menuSubBlock}>
                    <GenericList
                        items={dividedElements}
                        itemRenderer={(childElements: MenuItem[], childsKey: number) => {
                            return (
                                <div key={childsKey} className={styles.column}>
                                    <GenericList
                                        items={childElements}
                                        itemRenderer={(childElement: MenuItem, childKey: number) => {
                                            if (childElement.ctaStyle) {
                                                if (childElement.ctaStyle === 'container') {
                                                    return <React.Fragment />;
                                                }
                                                return (
                                                    <div className={styles.ctaContainer} key={childKey}>
                                                        <CtaButton
                                                            ctaText={childElement.name}
                                                            ctaLink={childElement.ressourcePath}
                                                            ctaStyle={childElement.ctaStyle}
                                                            fullWidth
                                                        />
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div className={styles.menuLvl3Container} key={childKey}>
                                                    <NavLink
                                                        to={childElement.ressourcePath}
                                                        className={`${styles.menuLvl3} ${
                                                            childElement.isBold ? styles.bolded : ''
                                                        }`}
                                                        style={childElement.color ? { color: childElement.color } : {}}
                                                    >
                                                        {childElement.name}
                                                    </NavLink>
                                                    <Level4MenuPanel elements={childElement.children} />
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
    return <React.Fragment />;
};

const buildLevel2 = (element: MenuItem | undefined | null): JSX.Element => {
    if (element && element.children) {
        return (
            <GenericList
                items={element.children}
                itemRenderer={(childElement: MenuItem, key: number) => {
                    const isCtaContainer = childElement.ctaStyle && childElement.ctaStyle === 'container';
                    return (
                        <div
                            className={`${styles.menuBlock} ${isCtaContainer ? styles.menuCtaContainer : ''}`}
                            key={key}
                        >
                            {!isCtaContainer && (
                                <NavLink
                                    to={childElement.ressourcePath}
                                    className={styles.menuLvl2}
                                    style={childElement.color ? { color: childElement.color } : {}}
                                >
                                    {childElement.name}
                                </NavLink>
                            )}
                            {buildLevel3(childElement.children)}
                        </div>
                    );
                }}
            />
        );
    }
    return <React.Fragment />;
};

const buildLevel1 = (element: SectionMenuItem, top: number = 0, verticalDisplay: boolean = false): JSX.Element => {
    let thumb: JSX.Element;
    const disableThumbPlaceholder = element.thumbUrl === 'disabled';
    if (element && element.thumbAssetUrl) {
        const innerThumb = (
            <div className={styles.imgContainer}>
                <picture>
                    <img alt="" src={element.thumbAssetUrl} />
                </picture>
            </div>
        );

        if (element.thumbUrl) {
            if (element.thumbCtaText) {
                thumb = (
                    <div className={styles.thumbContainer}>
                        {innerThumb}
                        <div className={styles.thumbCtaContainer}>
                            <CtaButton
                                ctaText={element.thumbCtaText}
                                ctaStyle={element.thumbCtaStyle}
                                ctaLink={element.thumbUrl}
                            />
                        </div>
                    </div>
                );
            } else {
                thumb = <ClickableContainer link={element.thumbUrl}>{innerThumb}</ClickableContainer>;
            }
        } else {
            thumb = innerThumb;
        }
    } else {
        thumb = <React.Fragment />;
    }

    if (element && element.children) {
        return (
            <div className={styles.megaMenu} style={{ top }}>
                <div className={`${styles.megaMenuContent} ${verticalDisplay ? styles.verticalMegaMenuContent : ''}`}>
                    <div className={`${styles.navBlock} ${verticalDisplay ? styles.verticalNavBlock : ''}`}>
                        {buildLevel2(element)}
                    </div>
                    {!disableThumbPlaceholder && <div className={styles.promoContainer}>{thumb}</div>}
                </div>
            </div>
        );
    }
    return <React.Fragment />;
};

export const DesktopPanel: React.FC<Props> = ({ element, top, verticalDisplay }: Props) => {
    const { bind, hovered } = useHover();

    return (
        <div role="menu" className={`${styles.topMenuItem} ${hovered ? styles.topMenuItemHovered : ''}`} {...bind}>
            <NavLink
                to={element.ressourcePath}
                className={`${styles.menuItem}`}
                activeClassName={styles.activeMenuItem}
            >
                {element.name}
            </NavLink>
            {buildLevel1(element, top, verticalDisplay)}
        </div>
    );
};
