import { LightList, FastUpsertListElement, Error } from '../../../models';

export interface ListState {
    currentList: LightList | null;
    error: Error | null;
    updateTimeout: NodeJS.Timeout | undefined;
    isUpdating: boolean;
    refreshInterval: NodeJS.Timeout | undefined;
    isRefreshing: boolean;
    bufferList: FastUpsertListElement[];
}

export const initialState: ListState = {
    currentList: null,
    bufferList: [],
    updateTimeout: undefined,
    refreshInterval: undefined,
    error: null,
    isRefreshing: false,
    isUpdating: false,
};
