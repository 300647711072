import React from 'react';
import { DocumentNode } from 'graphql';

import { Omit } from 'modules/shared/types/type-utils';

import { CMSQuery } from './components';

export interface WithFetchDatasFromCMSInputProps {
    onLoadingStateChange?: (state: boolean) => void;
}

export function WithFetchDatasFromCMS<OutputProps>(
    query: DocumentNode,
    Loader?: React.ComponentType,
    queryName?: string,
) {
    return <P extends OutputProps>(Component: React.ComponentType<P>) => {
        type WrapperType = Omit<P, keyof OutputProps> & WithFetchDatasFromCMSInputProps;
        const WithFetchDatasFromCMSWrapper: React.FC<WrapperType> = ({
            onLoadingStateChange,
            ...rest
        }: WrapperType) => {
            return (
                <CMSQuery
                    Loader={Loader}
                    query={query}
                    onLoadingStateChange={onLoadingStateChange}
                    readPayload={payload => {
                        let innerData: P | null;
                        if (queryName) {
                            if (!payload || !payload[queryName]) {
                                innerData = null;
                            } else if (payload[queryName].length === 1) {
                                innerData = payload[queryName][0].data;
                            } else {
                                innerData = payload[queryName].data;
                            }
                        } else {
                            innerData = payload;
                        }

                        return innerData;
                    }}
                    render={data => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        return <Component {...(rest as any)} {...data} />;
                    }}
                />
            );
        };

        return WithFetchDatasFromCMSWrapper;
    };
}
