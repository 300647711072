import { Action, AnyAction } from 'redux';

import { TracerConsent } from '../../../../models/tracer';
import { tracerConsentService } from '../../../../services/tracer-consent-service';
import { TracerState } from '..';

export const UPDATE_ALL_CONSENTS = 'UPDATE_ALL_CONSENTS';

export const ACCEPT_ALL_CONSENTS = 'ACCEPT_ALL_CONSENTS';

export const REJECT_ALL_CONSENTS = 'REJECT_ALL_CONSENTS';

interface UpdateAllConsentsAction extends Action {
    type: typeof UPDATE_ALL_CONSENTS;
    payload: { consents: TracerConsent[] };
}

interface AcceptAllConsentsAction extends Action {
    type: typeof ACCEPT_ALL_CONSENTS;
}

interface RejectAllConsentsAction extends Action {
    type: typeof REJECT_ALL_CONSENTS;
}

export type UpdateConsentAction = UpdateAllConsentsAction | AcceptAllConsentsAction | RejectAllConsentsAction;

const updateAllAction = (data: TracerConsent[]): UpdateAllConsentsAction => {
    return {
        type: UPDATE_ALL_CONSENTS,
        payload: {
            consents: data,
        },
    };
};

const acceptAllAction = (): AcceptAllConsentsAction => {
    return {
        type: ACCEPT_ALL_CONSENTS,
    };
};

const rejectAllAction = (): RejectAllConsentsAction => {
    return {
        type: REJECT_ALL_CONSENTS,
    };
};

function updateConsentReducer(state: TracerState, action: AnyAction): TracerState {
    switch (action.type) {
        case UPDATE_ALL_CONSENTS: {
            action.payload.consents.forEach((consent: TracerConsent) => {
                tracerConsentService.update(consent.type, !!consent.value);
            });
            return {
                ...state,
                consents: action.payload.consents
                    ? action.payload.consents.map((c: TracerConsent) => {
                          return { ...c, value: !!c.value };
                      })
                    : undefined,
            };
        }
        case ACCEPT_ALL_CONSENTS:
            tracerConsentService.acceptAll();
            return { ...state, consents: tracerConsentService.getAll() };
        case REJECT_ALL_CONSENTS:
            tracerConsentService.declineAll();
            return { ...state, consents: tracerConsentService.getAll() };
        default:
            return state;
    }
}

export const updateConsentModule = {
    reducer: updateConsentReducer,
    action: {
        updateAll: updateAllAction,
        acceptAll: acceptAllAction,
        rejectAll: rejectAllAction,
    },
};
