import { Address } from '..';
import { Civility, CivilityTypeValues } from './type';

export interface LightPerson {
    firstName: string;
    lastName: string;
    civility?: Civility;
    birthDate?: Date;
}

export interface Person extends LightPerson {
    email: string;
}

export interface PersonWithAddress extends Person, Address {}

export interface DetailedPerson extends PersonWithAddress {
    phone: string;
}

export const buildPersonName = (person: LightPerson | null): string => {
    if (!person) return '';
    return `${CivilityTypeValues.toString(person.civility)} ${person.firstName} ${person.lastName.toUpperCase()}`;
};
