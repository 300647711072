import React from 'react';
import { Trans } from 'react-i18next';

import { Container } from '../base-components';

export const GDPRNewsletter: React.FC<{ footer?: boolean }> = ({ footer = false }: { footer?: boolean }) => {
    return (
        <Container>
            <p>
                <Trans i18nKey="gdpr.newsletter">
                    part1
                    <a
                        href="https://www.printemps.com/fr/fr/protection-donnees-personnelles"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ici
                    </a>
                    part2
                </Trans>
                <br />
                <Trans i18nKey="shared.mentions.requiredFields" />
                {!footer && (
                    <span>
                        <br />
                        <Trans i18nKey="shared.mentions.zipCodeCityFields" />
                    </span>
                )}
            </p>
        </Container>
    );
};
