import React from 'react';
import { Link } from 'react-router-dom';

import { readCMSValue, getDefault } from 'modules/shared-cms/utils';

import { CtaButtonProps } from './cta-button-type';

import styles from './cta-button-component.module.css';

export const CtaButton: React.FC<CtaButtonProps> = React.memo(
    ({ ctaLink, ctaText, ctaStyle, forceHover, target = '_self', fullWidth = false }: CtaButtonProps) => {
        const text = getDefault(readCMSValue(ctaText), '');
        const style = readCMSValue(ctaStyle);
        const link = readCMSValue(ctaLink);
        if (link) {
            const externalUrl = !!(link.startsWith('http') || link.startsWith('www'));

            if (externalUrl) {
                return (
                    <a
                        href={link}
                        target={target}
                        style={{ textDecoration: 'none' }}
                        className={`${styles.ctaButton} ${fullWidth ? styles.fullWidth : ''} ${
                            forceHover ? styles.forceHover : ''
                        } ${style ? styles[style] : styles.normal}`}
                    >
                        {text}
                    </a>
                );
            }
            return (
                <Link
                    target={target}
                    style={{ textDecoration: 'none' }}
                    className={`${styles.ctaButton} ${fullWidth ? styles.fullWidth : ''} ${
                        forceHover ? styles.forceHover : ''
                    } ${style ? styles[style] : styles.normal}`}
                    to={link}
                >
                    {text}
                </Link>
            );
        }

        return (
            <span
                className={`${styles.ctaButton} ${fullWidth ? styles.fullWidth : ''} ${
                    forceHover ? styles.forceHover : ''
                } ${style ? styles[style] : styles.normal}`}
            >
                {text}
            </span>
        );
    },
);
