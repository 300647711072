import { formatTechDate } from 'modules/shared/utils';
import { config } from '../../../../../config';
import { restClient, securedRestClient } from '.';
import {
    AuthenticationResult,
    RegisterFolder,
    LightFolder,
    ResetPasswordValidationRequest,
    ResetPasswordValidationResult,
} from '../../../models';

export const authenticationService = {
    authenticateInternalUser: async (folderIdOrEmail: string, password: string) => {
        const response = await restClient.post<AuthenticationResult>(
            `${config.backend.rest.listUrl}/Authentication/authenticateInternalUser`,
            {
                folderIdOrEmail,
                password,
            },
        );

        if (response.data && response.data.expireAt) {
            response.data.expireAt = new Date(response.data.expireAt);
        }
        return response.data;
    },

    registerInternalUser: async (registerFolderInfos: RegisterFolder) => {
        const data = {
            ...registerFolderInfos,
            eventDate: formatTechDate(registerFolderInfos.eventDate),
            contact: {
                ...registerFolderInfos.contact,
                person1: {
                    ...registerFolderInfos.contact.person1,
                    birthDate: registerFolderInfos.contact.person1.birthDate
                        ? formatTechDate(registerFolderInfos.contact.person1.birthDate)
                        : undefined,
                },
                person2: {
                    ...registerFolderInfos.contact.person2,
                    birthDate: registerFolderInfos.contact.person2.birthDate
                        ? formatTechDate(registerFolderInfos.contact.person2.birthDate)
                        : undefined,
                },
            },
        };

        const response = await restClient.post<AuthenticationResult>(
            `${config.backend.rest.listUrl}/Authentication/registerInternalCustomer`,
            data,
        );

        if (response.data && response.data.expireAt) {
            response.data.expireAt = new Date(response.data.expireAt);
        }
        return response.data;
    },

    getLoggedInUser: async () => {
        const response = await securedRestClient.get<LightFolder>(
            `${config.backend.rest.listUrl}/Authentication/GetLoggedInUser`,
        );

        return response.data;
    },

    retrievePassword: async (email: string) => {
        await restClient.post<LightFolder>(`${config.backend.rest.listUrl}/Authentication/RetrievePassword`, { email });
    },

    getInformationToResetPassword: async ({ IdDossier, Heure, EMail, Operation }: ResetPasswordValidationRequest) => {
        const response = await restClient.get<ResetPasswordValidationResult>(
            `${config.backend.rest.listUrl}/Authentication/GetFolderAccountInformationToResetPassword/?encodedFolder=${IdDossier}&encodedHour=${Heure}&encodedEmail=${EMail}&additionalParameter=${Operation}`,
        );

        return response.data;
    },

    resetPassword: async (newPassword: string, folderId: string) => {
        await restClient.post<LightFolder>(`${config.backend.rest.listUrl}/Authentication/resetPassword`, {
            folderId,
            newPassword,
        });
    },

    acceptTransfer: async (newPassword: string, folderId: string) => {
        await restClient.post<LightFolder>(`${config.backend.rest.listUrl}/Authentication/acceptTransfer`, {
            folderId,
            newPassword,
        });
    },
};
