import { Dispatch, AnyAction, MiddlewareAPI } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import { cacheQueryService } from '../../services';

export const cacheMiddleware = ({ getState }: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            {
                const state = getState().router;
                if (state.location.pathname !== action.payload.location.pathname) {
                    cacheQueryService.clearRouteCache();
                }
            }
            break;
        default:
            break;
    }

    return next(action);
};
