import * as React from 'react';

export interface GenericListProps<T> {
    items?: T[] | null;
    itemRenderer: (item: T, key: number) => JSX.Element;
}

export const GenericList = <T extends object | number>({ items, itemRenderer }: GenericListProps<T>): JSX.Element => {
    return <React.Fragment>{items && items.map(itemRenderer)}</React.Fragment>;
};
