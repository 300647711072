import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { currentListService } from '../../../../services/API/backend';
import { ListState } from '..';
import { FastUpsertListElement, LightListElement } from '../../../../models';
import { bufferListService } from '../../../../services';

import { mergeLocalList, mergeBufferList, updateLocalList } from './utils';

const REQUEST_INITIALIZE_BUFFER_LIST = 'REQUEST_INITIALIZE_BUFFER_LIST';
export const SUCCESS_INITIALIZE_BUFFER_LIST = 'SUCCESS_INITIALIZE_BUFFER_LIST';
const ERROR_INITIALIZE_BUFFER_LIST = 'ERROR_INITIALIZE_BUFFER_LIST';

interface RequestInitializeBufferListAction extends Action {
    type: typeof REQUEST_INITIALIZE_BUFFER_LIST;
    payload: {
        bufferList: FastUpsertListElement[];
    };
}

interface SuccessInitializeBufferListAction extends Action {
    type: typeof SUCCESS_INITIALIZE_BUFFER_LIST;
    payload: {
        updatedElements: LightListElement[];
    };
}

interface ErrorInitializeBufferListAction extends Action {
    type: typeof ERROR_INITIALIZE_BUFFER_LIST;
    payload: {
        bufferList: FastUpsertListElement[];
        error: Error;
    };
}

export type InitializeBufferListAction =
    | RequestInitializeBufferListAction
    | SuccessInitializeBufferListAction
    | ErrorInitializeBufferListAction;

// Action creators
const requestAction = (bufferList: FastUpsertListElement[]): RequestInitializeBufferListAction => {
    return {
        type: REQUEST_INITIALIZE_BUFFER_LIST,
        payload: {
            bufferList,
        },
    };
};

const successAction = (updatedElements: LightListElement[]): SuccessInitializeBufferListAction => {
    return {
        type: SUCCESS_INITIALIZE_BUFFER_LIST,
        payload: {
            updatedElements,
        },
    };
};

const failureAction = (bufferList: FastUpsertListElement[], error: Error): ErrorInitializeBufferListAction => {
    return {
        type: ERROR_INITIALIZE_BUFFER_LIST,
        payload: {
            bufferList,
            error,
        },
    };
};

const initializeBufferListAction = (
    folderId: number,
): ThunkAction<Promise<void>, {}, {}, InitializeBufferListAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, InitializeBufferListAction>): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            const previousBufferList = bufferListService.get(folderId);
            dispatch(requestAction(previousBufferList));
            try {
                if (previousBufferList && previousBufferList.length > 0) {
                    const updatedElements = await currentListService.fastUpsertElements({
                        elements: previousBufferList,
                    });
                    dispatch(successAction(updatedElements));
                } else {
                    dispatch(successAction([]));
                }

                resolve();
            } catch (error) {
                dispatch(failureAction(previousBufferList, error));
                reject(error);
            }
        });
    };
};

const initializeBufferListReducer = (state: ListState, action: AnyAction): ListState => {
    switch (action.type) {
        case REQUEST_INITIALIZE_BUFFER_LIST:
            return {
                ...state,
                isUpdating: true,
                bufferList: [],
                currentList: mergeLocalList(state.currentList, action.payload.bufferList),
            };
        case SUCCESS_INITIALIZE_BUFFER_LIST:
            return {
                ...state,
                isUpdating: false,
                bufferList: [],
                currentList: updateLocalList(state.currentList, action.payload.updatedElements),
            };
        case ERROR_INITIALIZE_BUFFER_LIST:
            return {
                ...state,
                isUpdating: false,
                error: action.payload.error,
                bufferList: mergeBufferList(state.bufferList, action.payload.bufferList),
            };
        default:
            return state;
    }
};

export const initializeBufferListModule = {
    reducer: initializeBufferListReducer,
    action: {
        initializeBufferList: initializeBufferListAction,
    },
};
