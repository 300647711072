import { authenticationService } from './API/backend';
import { LightFolder, AuthToken, AuthenticationResult, RegisterFolder } from '../models';

const storageKey = 'backend_access_token';

export const contextService = {
    getLoggedInUserInfos: async (): Promise<{ folder: LightFolder; token: AuthToken } | undefined> => {
        const alreadyLoggedInUserTokenStr = localStorage.getItem(storageKey);
        if (!alreadyLoggedInUserTokenStr) {
            return undefined;
        }

        const loggedInFolder = await authenticationService.getLoggedInUser();
        const authToken = JSON.parse(alreadyLoggedInUserTokenStr) as AuthToken;
        if (authToken.expiration) {
            authToken.expiration = new Date(authToken.expiration);
        }

        return { folder: loggedInFolder, token: authToken };
    },

    authenticateInternalUser: async (folderIdOrEmail: string, password: string): Promise<AuthenticationResult> => {
        const result = await authenticationService.authenticateInternalUser(folderIdOrEmail, password);
        const token: AuthToken = { value: result.accessToken, expiration: result.expireAt, isValid: () => true };
        localStorage.setItem(storageKey, JSON.stringify(token));
        return result;
    },

    registerInternalUser: async (registerFolderInfos: RegisterFolder): Promise<AuthenticationResult> => {
        const result = await authenticationService.registerInternalUser(registerFolderInfos);
        const token: AuthToken = { value: result.accessToken, expiration: result.expireAt, isValid: () => true };
        localStorage.setItem(storageKey, JSON.stringify(token));
        return result;
    },

    logout: (): void => {
        localStorage.removeItem(storageKey);
    },
};
