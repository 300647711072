import React from 'react';
import { IntroductionLoader } from 'modules/shared-cms-advanced/enhanced-blocs/introduction/introduction-component-loader';
import { ActualiteLoader } from 'modules/shared-cms-advanced/enhanced-blocs/actualite/actualite-component-loader';

export const HomeAltLoader: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <IntroductionLoader />
            <IntroductionLoader />
            <ActualiteLoader />
            <IntroductionLoader />
            <ActualiteLoader />
            <IntroductionLoader />
            <ActualiteLoader />
        </React.Fragment>
    );
});
