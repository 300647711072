import React from 'react';
import { DocumentNode } from 'graphql';
import { Query } from 'react-apollo';
import { ErrorMessage, NotFound } from 'modules/shared/components';

interface CMSQueryProps<TVariables, TDatas> {
    query: DocumentNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readPayload: (data: any) => TDatas | null;
    render: (data: TDatas | null) => JSX.Element;
    variables?: TVariables;
    Loader?: React.ComponentType;
    bypassNotFound?: boolean;
    onLoadingStateChange?: (state: boolean) => void;
}

export function CMSQuery<TVariables, TDatas extends {}>({
    Loader,
    query,
    variables,
    readPayload,
    render,
    bypassNotFound,
    onLoadingStateChange,
}: CMSQueryProps<TVariables, TDatas>): JSX.Element {
    return (
        <Query<TDatas> query={query} variables={variables}>
            {({ loading = false, error = null, data = null }): React.ReactNode => {
                if (onLoadingStateChange) {
                    onLoadingStateChange(loading);
                }

                if (loading) {
                    if (Loader) {
                        return <Loader />;
                    }

                    return <React.Fragment />;
                }

                if (error) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return <ErrorMessage error={(error as any).message} />;
                }

                const innerData = readPayload(data);
                if (!innerData && !bypassNotFound) {
                    return <NotFound />;
                }

                return render(innerData);
            }}
        </Query>
    );
}
