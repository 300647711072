export type GetProps<C> = C extends React.ComponentType<infer P> ? P : never;

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
    const copy = ({} as unknown) as Pick<T, K>;
    keys.forEach(key => {
        copy[key] = obj[key];
    });
    return copy;
};
