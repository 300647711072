import { Reducer, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Module, FetchState, FetchActionDescriptor } from './type';
import { globalLoaderModule } from '../modules/global-loader';

export function fetchModuleFactory<T>(
    type: string,
    isGlobalLoading: boolean,
    resetOnRouteChange?: boolean,
): Module<FetchState<T>, FetchActionDescriptor<T, ThunkAction<Promise<void>, {}, {}, AnyAction>>> {
    const REQUEST = `REQUEST_${type.toUpperCase()}`;
    const SUCCESS = `SUCCESS_${type.toUpperCase()}`;
    const ERROR = `ERROR_${type.toUpperCase()}`;

    // Action creators
    const requestAction = (): AnyAction => {
        return { type: REQUEST };
    };

    const successAction = (data: T): AnyAction => {
        return {
            type: SUCCESS,
            data,
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const failureAction = (error: any): AnyAction => {
        return {
            type: ERROR,
            error,
        };
    };

    const fetchAction = (remoteFetchAction: () => Promise<T>): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
        return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
            return new Promise<void>(async (resolve, reject) => {
                if (isGlobalLoading) {
                    dispatch(globalLoaderModule.action.showLoading());
                }

                dispatch(requestAction());

                try {
                    const data = await remoteFetchAction();
                    dispatch(successAction(data));
                    resolve();
                } catch (error) {
                    dispatch(failureAction(error));
                    reject(error);
                } finally {
                    if (isGlobalLoading) {
                        dispatch(globalLoaderModule.action.hideLoading());
                    }
                }
            });
        };
    };

    const initialState: FetchState<T> = {
        data: null,
        error: null,
        isLoading: false,
    };

    const reducer: Reducer = (state: FetchState<T> = initialState, action: AnyAction): FetchState<T> => {
        switch (action.type) {
            case LOCATION_CHANGE:
                if (resetOnRouteChange) {
                    return { ...state, isLoading: false, data: null, error: null };
                }
                return state;
            case REQUEST:
                return { ...state, isLoading: true };
            case SUCCESS:
                return { ...state, isLoading: false, data: action.data };
            case ERROR:
                return { ...state, isLoading: false, error: action.error };
            default:
                return state;
        }
    };

    return {
        reducer,
        action: {
            fetchAction,
        },
        storeName: type,
    };
}
