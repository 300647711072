/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, useState, useMemo } from 'react';
import { Dimmer, Loader } from 'vendors/semantic-ui';

import { alertService } from 'modules/shared-ui/components';

import { Error } from '../../models';

export interface WithRemoteActionsCommitProps {
    commitWork: VoidFunction;
}

export type WithRemoteActionConfigurationProps<T> = {
    [P in keyof T]: {
        action: T[P];
        errorMessages?: {
            code: number;
            message: string;
        }[];

        defaultErrorMessage?: string;
        successMessage?: string;
    };
};

export type WithRemoteActionInjectedProps<T> = {
    // Les paramètres de la fonction ne sont pas utilisés directement donc le any a un sens
    [P in keyof T]: (...params: any[]) => Promise<any>;
};

export const WithRemoteActions = <InjectedProps extends WithRemoteActionInjectedProps<InjectedProps>>(
    remoteActions: WithRemoteActionConfigurationProps<InjectedProps>,
    injectCommitFunction?: boolean,
) => {
    return <P extends InjectedProps>(Component: ComponentType<P>) => {
        type WrapperType = Omit<P, keyof InjectedProps>;
        const WithRemoteActionsComponent: React.FC<WrapperType> = (props: WrapperType) => {
            let innerProps = { ...props };
            const [isUpdating, setIsUpdating] = useState<boolean>(false);

            const additionalActions = useMemo(() => {
                let innerAdditionalActions = {};
                Object.keys(remoteActions).forEach(n => {
                    if (n === 'commitWork') {
                        return;
                    }

                    const name: keyof InjectedProps = n as keyof InjectedProps;
                    const remoteActionInfos = remoteActions[name];

                    // Les paramètres de la fonction ne sont pas utilisés directement donc le any a un sens
                    const enhancedRemoteAction = async (...params: any[]): Promise<any> => {
                        setIsUpdating(true);

                        return remoteActionInfos
                            .action(...params)
                            .then(async result => {
                                if (remoteActionInfos.successMessage) {
                                    alertService.show(remoteActionInfos.successMessage);
                                }

                                return result;
                            })
                            .catch(error => {
                                let analyzedError = false;
                                if ((error as Error).subCode && remoteActionInfos.errorMessages) {
                                    const errorMessage = remoteActionInfos.errorMessages.find(
                                        e => e.code === error.subCode,
                                    );
                                    if (errorMessage) {
                                        alertService.show(errorMessage.message);
                                        analyzedError = true;
                                    }
                                }

                                if (!analyzedError) {
                                    alertService.show(remoteActionInfos.defaultErrorMessage || 'Erreur');
                                }

                                setIsUpdating(false);
                                throw error;
                            })
                            .finally(() => {
                                if (!injectCommitFunction) {
                                    setIsUpdating(false);
                                }
                            });
                    };

                    innerAdditionalActions = { ...innerAdditionalActions, [name]: enhancedRemoteAction };
                });

                if (injectCommitFunction) {
                    innerAdditionalActions = {
                        ...innerAdditionalActions,
                        commitWork: () => {
                            setIsUpdating(false);
                        },
                    };
                }

                return innerAdditionalActions;
            }, []);

            innerProps = useMemo(() => {
                return { ...innerProps, ...additionalActions };
            }, [innerProps, additionalActions]);

            return (
                <React.Fragment>
                    <Dimmer active={isUpdating} page>
                        <Loader size="massive" />
                    </Dimmer>
                    <Component {...(innerProps as P)} />
                </React.Fragment>
            );
        };

        return WithRemoteActionsComponent;
    };
};
