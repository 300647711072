import { CMSContainerField } from '../cms-base-type';

export const isCMSContainerField = <T>(
    toBeDetermined: T | CMSContainerField<T>,
): toBeDetermined is CMSContainerField<T> => {
    if (typeof (toBeDetermined as CMSContainerField<T>).iv !== 'undefined') {
        return true;
    }
    return false;
};
