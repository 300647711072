export const readPathId = (path: string): string => {
    if (!path) {
        return path;
    }

    const splittedPath = path.split('__').reverse();

    if (splittedPath.length > 1) {
        return splittedPath[0];
    }

    return '';
};

export const createPathId = (path: string, id: string): string => {
    if (!path || !id) {
        return path;
    }

    return `${path}__${id}`;
};

export const removePathId = (path: string): string => {
    if (!path) {
        return path;
    }

    return path.split('__')[0];
};

export const createPath = (name: string): string => {
    if (!name) {
        return name;
    }

    return name
        .replace(/[\300-\306]/g, 'A')
        .replace(/[\340-\346]/g, 'a')
        .replace(/[\310-\313]/g, 'E')
        .replace(/[\350-\353]/g, 'e')
        .replace(/[\314-\317]/g, 'I')
        .replace(/[\354-\357]/g, 'i')
        .replace(/[\322-\330]/g, 'O')
        .replace(/[\362-\370]/g, 'o')
        .replace(/[\331-\334]/g, 'U')
        .replace(/[\371-\374]/g, 'u')
        .replace(/[\321]/g, 'N')
        .replace(/[\361]/g, 'n')
        .replace(/[\307]/g, 'C')
        .replace(/[\347]/g, 'c')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ /g, '-')
        .replace(/%/g, 'pourcent')
        .replace(/\//g, '-')
        .toLowerCase();
};
