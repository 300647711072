import React from 'react';
import { NavLink } from 'react-router-dom';

import { CtaButton } from 'modules/shared-ui/components';
import { GenericList } from '../../../../../generic-list';
import { MenuItem } from '../../../..';

import { PanelProps } from './panel-type';

import styles from './panel-component.module.css';

const renderCta = (element: MenuItem, key: number): JSX.Element => {
    return (
        <div className={styles.ctaContainer} key={key}>
            <CtaButton ctaText={element.name} ctaLink={element.ressourcePath} ctaStyle={element.ctaStyle} fullWidth />
        </div>
    );
};

export const MobilePanel: React.FC<PanelProps> = ({ activeElement, updateActiveElement }: PanelProps) => {
    const isActiveRoot = activeElement && activeElement.name;
    if (activeElement && activeElement.children && activeElement.children.length > 0) {
        return (
            <React.Fragment>
                {isActiveRoot && (
                    <div className={`${styles.mobileTopMenuItem} ${styles.activeElement}`}>
                        {activeElement.parent && (
                            <button
                                type="button"
                                className={styles.prevBtn}
                                onClick={() => {
                                    if (activeElement.parent) {
                                        updateActiveElement(activeElement.parent);
                                    }
                                }}
                            />
                        )}
                        {activeElement.name}
                    </div>
                )}
                <GenericList
                    items={activeElement.children}
                    itemRenderer={(element: MenuItem, key: number) => {
                        if (element.ctaStyle) {
                            if (element.ctaStyle === 'container') {
                                return (
                                    <GenericList
                                        items={element.children}
                                        itemRenderer={(subElement: MenuItem, subkey: number) =>
                                            renderCta(subElement, subkey)
                                        }
                                    />
                                );
                            }
                            return renderCta(element, key);
                        }

                        return (
                            <React.Fragment key={key}>
                                <div
                                    className={`${styles.mobileTopMenuItem} ${!isActiveRoot ? styles.rootElement : ''}`}
                                >
                                    <div className={styles.mobileMenuItem}>
                                        <NavLink to={element.ressourcePath}>{element.name}</NavLink>
                                    </div>
                                    {element.children && element.children.length > 0 ? (
                                        <button
                                            type="button"
                                            className={styles.nextBtn}
                                            onClick={() => updateActiveElement(element)}
                                        />
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
            </React.Fragment>
        );
    }

    return <React.Fragment />;
};
