import { Action, AnyAction } from 'redux';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { RootState } from '../../../store';
import { FastUpsertListElement, Error, LightListElement } from '../../../../models';
import { currentListService } from '../../../../services/API/backend';

import { ListState } from '../list-state';

import { mergeBufferList, mergeLocalList, updateLocalList } from './utils';

const REQUEST_UPDATE_REMOTE_ELEMENTS_QUANTITY = 'REQUEST_UPDATE_REMOTE_ELEMENTS_QUANTITY';
export const SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY = 'SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY';
export const ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY = 'ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY';

interface RequestUpdateRemoteElementsQuantityAction extends Action {
    type: typeof REQUEST_UPDATE_REMOTE_ELEMENTS_QUANTITY;
    payload: {
        bufferList: FastUpsertListElement[];
    };
}

interface SuccessUpdateRemoteElementsQuantityAction extends Action {
    type: typeof SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY;
    payload: {
        updatedElements: LightListElement[];
    };
}

interface ErrorUpdateRemoteElementsQuantityAction extends Action {
    type: typeof ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY;
    payload: { bufferList: FastUpsertListElement[]; error: Error };
}

export type UpdateRemoteElementsQuantityAction =
    | RequestUpdateRemoteElementsQuantityAction
    | SuccessUpdateRemoteElementsQuantityAction
    | ErrorUpdateRemoteElementsQuantityAction;

const requestAction = (bufferList: FastUpsertListElement[]): RequestUpdateRemoteElementsQuantityAction => {
    return {
        type: REQUEST_UPDATE_REMOTE_ELEMENTS_QUANTITY,
        payload: {
            bufferList,
        },
    };
};

const successAction = (updatedElements: LightListElement[]): SuccessUpdateRemoteElementsQuantityAction => {
    return {
        type: SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY,
        payload: {
            updatedElements,
        },
    };
};

const failureAction = (bufferList: FastUpsertListElement[], error: Error): ErrorUpdateRemoteElementsQuantityAction => {
    return {
        type: ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY,
        payload: {
            bufferList,
            error,
        },
    };
};

const updateRemoteElementsQuantityAction = (): ThunkAction<
    Promise<void>,
    RootState,
    {},
    UpdateRemoteElementsQuantityAction
> => {
    return async (
        dispatch: ThunkDispatch<RootState, {}, UpdateRemoteElementsQuantityAction>,
        getState: () => RootState,
    ): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            const { bufferList } = getState().list;
            const elementsToUpdate = [...bufferList];

            if (!elementsToUpdate || elementsToUpdate.length === 0) {
                resolve();
                return;
            }

            dispatch(requestAction(elementsToUpdate));

            await currentListService
                .fastUpsertElements({ elements: elementsToUpdate })
                .then(updatedElements => {
                    dispatch(successAction(updatedElements));
                    resolve();
                })
                .catch(error => {
                    dispatch(failureAction(elementsToUpdate, error));
                    reject(error);
                });
        });
    };
};

const updateRemoteElementsQuantityReducer = (state: ListState, action: AnyAction): ListState => {
    switch (action.type) {
        case REQUEST_UPDATE_REMOTE_ELEMENTS_QUANTITY:
            return {
                ...state,
                bufferList: [],
                currentList: mergeLocalList(state.currentList, action.payload.bufferList),
                isUpdating: true,
            };
        case SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY:
            return {
                ...state,
                currentList: updateLocalList(state.currentList, action.payload.updatedElements),
                isUpdating: false,
            };
        case ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY:
            return {
                ...state,
                error: action.payload.error,
                bufferList: mergeBufferList(state.bufferList, action.payload.bufferList),
                isUpdating: false,
            };
        default:
            return state;
    }
};

export const updateRemoteElementsQuantityModule = {
    reducer: updateRemoteElementsQuantityReducer,
    action: {
        updateRemoteElementsQuantity: updateRemoteElementsQuantityAction,
    },
};
