/* eslint-disable @typescript-eslint/no-explicit-any */
import { readCMSValue, getDefault, getDefaultUrlPicture } from 'modules/shared-cms/utils';

import {
    TitleProps,
    SubTitleProps,
    TextColorProps,
    JustifyProps,
    BackgroundImageProps,
    BackgroundColorProps,
    ContentPositionProps,
    GlobalFontSizeProps,
    WidthProps,
} from '../cms-base-type';

const titlePropsCheck = (toBeDetermined: TitleProps | any): toBeDetermined is TitleProps => {
    if ((toBeDetermined as TitleProps).fontSizeTitle) {
        return true;
    }

    return false;
};

const subTitlePropsCheck = (toBeDetermined: SubTitleProps | any): toBeDetermined is SubTitleProps => {
    if ((toBeDetermined as SubTitleProps).fontSizeSubTitle) {
        return true;
    }

    return false;
};

const globalFontSizePropsCheck = (toBeDetermined: GlobalFontSizeProps | any): toBeDetermined is GlobalFontSizeProps => {
    if ((toBeDetermined as GlobalFontSizeProps).fontSize) {
        return true;
    }

    return false;
};

const widthPropsCheck = (toBeDetermined: WidthProps | any): toBeDetermined is WidthProps => {
    if ((toBeDetermined as WidthProps).width) {
        return true;
    }

    return false;
};

const textColorPropsCheck = (toBeDetermined: TextColorProps | any): toBeDetermined is TextColorProps => {
    if ((toBeDetermined as TextColorProps).textColor) {
        return true;
    }

    return false;
};

const justifyPropsCheck = (toBeDetermined: JustifyProps | any): toBeDetermined is JustifyProps => {
    if ((toBeDetermined as JustifyProps).justification) {
        return true;
    }

    return false;
};

const backgroundImagePropsCheck = (
    toBeDetermined: BackgroundImageProps | any,
): toBeDetermined is BackgroundImageProps => {
    if ((toBeDetermined as BackgroundImageProps).picture) {
        return true;
    }

    return false;
};

const backgroundColorPropsCheck = (
    toBeDetermined: BackgroundColorProps | any,
): toBeDetermined is BackgroundColorProps => {
    if ((toBeDetermined as BackgroundColorProps).color) {
        return true;
    }

    return false;
};

const contentPositionPropsCheck = (
    toBeDetermined: ContentPositionProps | any,
): toBeDetermined is ContentPositionProps => {
    const typedProps = toBeDetermined as ContentPositionProps;
    if (typedProps.horizontalPosition || typedProps.verticalPosition) {
        return true;
    }

    return false;
};

// Méthode de construction générique des styles du CMS
export const buildCMSStyles = <P extends {}>(props: P): any => {
    const result: any = {};

    if (titlePropsCheck(props)) {
        result.fontSize = `${(getDefault(readCMSValue((props as TitleProps).fontSizeTitle), 16) * 1.0) / 10}rem`;
    }

    if (subTitlePropsCheck(props)) {
        result.fontSize = `${(getDefault(readCMSValue((props as SubTitleProps).fontSizeSubTitle), 16) * 1.0) / 10}rem`;
    }

    if (globalFontSizePropsCheck(props)) {
        result.fontSize = `${(getDefault(readCMSValue((props as GlobalFontSizeProps).fontSize), 16) * 1.0) / 10}rem`;
    }

    if (textColorPropsCheck(props)) {
        result.color = `${readCMSValue((props as TextColorProps).textColor)}`;
    }

    if (justifyPropsCheck(props)) {
        const justification = readCMSValue((props as JustifyProps).justification);
        let cssJustification = '';
        switch (justification) {
            case 'gauche':
                cssJustification = 'left';
                break;
            case 'droite':
                cssJustification = 'right';
                break;
            case 'justifié':
                cssJustification = 'justify';
                break;
            case 'centré':
            default:
                cssJustification = 'center';
                break;
        }

        result.textAlign = cssJustification;
    }

    if (backgroundImagePropsCheck(props)) {
        const biProps = props as BackgroundImageProps;
        const pictureValue = getDefaultUrlPicture(biProps.picture);
        if (pictureValue && pictureValue.length > 0) {
            result.backgroundImage = `url('${pictureValue}')`;
        }
    }

    if (backgroundColorPropsCheck(props)) {
        result.backgroundColor = `${readCMSValue((props as BackgroundColorProps).color)}`;
    }

    if (widthPropsCheck(props)) {
        const wProps = props as WidthProps;
        const width = readCMSValue((wProps as WidthProps).width);
        let cssWidth = '600px';
        switch (width) {
            case 'petit':
                cssWidth = '540px';
                break;
            case 'large':
                cssWidth = '737px';
                break;
            default:
                cssWidth = '600px';
                break;
        }

        result.width = cssWidth;
    }

    if (contentPositionPropsCheck(props)) {
        const cpProps = props as ContentPositionProps;
        const verticalPosition = readCMSValue(cpProps.verticalPosition);
        let cssVerticalPosition = 'center';
        switch (verticalPosition) {
            case 'haut':
                cssVerticalPosition = 'flex-start';
                break;
            case 'bas':
                cssVerticalPosition = 'flex-end';
                break;
            default:
                cssVerticalPosition = 'center';
                break;
        }

        result.alignItems = cssVerticalPosition;

        const horizontalPosition = readCMSValue(cpProps.horizontalPosition);
        let cssHorizontalPosition = 'center';
        switch (horizontalPosition) {
            case 'gauche':
                cssHorizontalPosition = 'flex-start';
                break;
            case 'droite':
                cssHorizontalPosition = 'flex-end';
                break;
            default:
                cssHorizontalPosition = 'center';
                break;
        }

        result.justifyContent = cssHorizontalPosition;
    }

    return result;
};
