const configUI = {
    pagination: {
        thumbList: 12,
        productList: 20,
    },
    responsive: {
        mobile: {
            minWidth: 320,
            maxWidth: 767,
        },
        tablet: {
            minWidth: 768,
            maxWidth: 1024,
        },
        computer: {
            minWidth: 1025,
        },
        largeScreen: {
            minWidth: 1281,
            maxWidth: 1920,
        },
        wideScreen: {
            minWidth: 1921,
        },
    },
    carousel: {
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    className: 'center',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                    infinite: true,
                    autoplay: false,
                    centerPadding: '60px',
                },
            },
            {
                breakpoint: 640,
                settings: {
                    className: 'center',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    centerMode: true,
                    infinite: true,
                    autoplay: false,
                    centerPadding: '20px',
                },
            },
            {
                breakpoint: 320,
                settings: {
                    className: 'center',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    centerMode: true,
                    infinite: true,
                    autoplay: false,
                    centerPadding: '20px',
                },
            },
        ],
    },
    columnListOptions: {
        mobile: [1, 2],
        tablet: [2, 3],
        computer: [3, 4],
    },
    selects: {
        groups: [
            { key: '0', value: '', text: '--' },
            { key: '1', value: 'printemps', text: 'Printemps.com', url: 'https://www.printemps.com/' },
            {
                key: '3',
                value: 'place-des-tendances',
                text: 'Place des Tendances',
                url: 'https://www.placedestendances.com/',
            },
            { key: '4', value: 'made-in-design', text: 'Made in Design', url: 'https://www.madeindesign.com/' },
            { key: '5', value: 'citadium', text: 'Citadium', url: 'https://www.citadium.com/' },
        ],
    },
};

export { configUI };
