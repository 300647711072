import { FastUpsertListElement } from '../models';

export const bufferListService = {
    update: (folderId: number, bufferList: FastUpsertListElement[]) => {
        if (bufferList && bufferList.length > 0) {
            localStorage.setItem(`${folderId}_bufferList`, JSON.stringify(bufferList));
        } else {
            localStorage.removeItem(`${folderId}_bufferList`);
        }
    },
    get: (folderId: number): FastUpsertListElement[] => {
        const previousBufferListStr = localStorage.getItem(`${folderId}_bufferList`);
        if (previousBufferListStr) {
            return JSON.parse(previousBufferListStr) as FastUpsertListElement[];
        }

        return [];
    },
};
