import React from 'react';
import { LargeBlocLoader, LargeBlocListContainer } from 'modules/shared-cms/components';

export const HomeLoader: React.FC = React.memo(() => {
    return (
        <LargeBlocListContainer>
            <LargeBlocLoader />
            <LargeBlocLoader />
        </LargeBlocListContainer>
    );
});
