import { Action, AnyAction } from 'redux';

import { AuthenticationState } from '../authentication-state';
import { contextService } from '../../../../services';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';

export interface RequestLogoutAction extends Action {
    type: typeof REQUEST_LOGOUT;
}

export interface SuccessLogoutAction extends Action {
    type: typeof SUCCESS_LOGOUT;
}

export type LogoutAction = RequestLogoutAction | SuccessLogoutAction;

// Action creators
const requestAction = (): LogoutAction => {
    return { type: REQUEST_LOGOUT };
};

const successAction = (): LogoutAction => {
    return { type: SUCCESS_LOGOUT };
};

const logoutAction = (): LogoutAction => {
    contextService.logout();
    return successAction();
};

function logoutReducer(state: AuthenticationState, action: AnyAction): AuthenticationState {
    switch (action.type) {
        case REQUEST_LOGOUT:
            return { ...state, logoutRequested: true };
        case SUCCESS_LOGOUT:
            return { ...state, isLoading: false, loggedInFolder: null, token: null, logoutRequested: false };
        default:
            return state;
    }
}

export const logoutModule = {
    reducer: logoutReducer,
    action: {
        requestLogout: requestAction,
        logout: logoutAction,
    },
};
