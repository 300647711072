import { useState, useEffect, useRef } from 'react';

export const useInfiniteScroll = (callback: VoidFunction): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [isFetching, setIsFetching] = useState(false);
    // const latestCallback = useRef(callback);
    const latestFetching = useRef(isFetching);

    useEffect(() => {
        const handleScroll = (): void => {
            if (
                Math.ceil(window.innerHeight + window.scrollY) < document.documentElement.scrollHeight ||
                latestFetching.current
            ) {
                return;
            }
            setIsFetching(true);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        callback();
        setIsFetching(false);
    }, [isFetching, callback]);

    return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
