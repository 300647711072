export type TracerType = 0 | 1 | 2 | 3;
const UKNOWN_CONSENT_TYPE: TracerType = 0;
const FUNCTIONNAL_TRACER_TYPE: TracerType = 1;
const PERFORMANCE_TRACER_TYPE: TracerType = 2;
const ADVERTISING_TRACER_TYPE: TracerType = 3;

export const TracerTypeValues = {
    UKNOWN: UKNOWN_CONSENT_TYPE,
    FUNCTIONNAL_TRACER_TYPE,
    PERFORMANCE_TRACER_TYPE,
    ADVERTISING_TRACER_TYPE,
};
