import React, { useCallback } from 'react';
import queryString from 'query-string';
import { KeyValueFilter } from '../../models';
import { formatTechDate, parse } from '../../utils';
import { useRouter } from '../../hooks';
import { Omit, pick } from '../../types/type-utils';

import { WithFilteringInjectedProps, WithFilteringAndReadingInjectedProps } from '../types';

export const WithQuerystringFiltering = <T extends {} = string>() => <P extends WithFilteringInjectedProps<T>>(
    Component: React.ComponentType<P>,
) => {
    type WrapperType = Omit<P, keyof WithFilteringInjectedProps<T>>;
    const WithQuerystringFilteringComponent: React.FC<WrapperType> = (props: WrapperType) => {
        const { location, history } = useRouter();
        const unparsedFilters = queryString.parse(location.search);

        const queryStringFilters: KeyValueFilter[] = [];
        if (unparsedFilters) {
            Object.keys(unparsedFilters).forEach(v => {
                const values = unparsedFilters[v];
                if (values) {
                    let splittedValues;
                    if (Array.isArray(values)) {
                        splittedValues = values;
                    } else {
                        splittedValues = values.split('|');
                    }

                    queryStringFilters.push({
                        id: v,
                        values: splittedValues,
                    });
                }
            });
        }

        const handleFiltersSelection = useCallback(
            (updatedFilters: KeyValueFilter<T>[]): void => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let queryParams: any;

                if (updatedFilters && updatedFilters.length > 0) {
                    queryParams = {};
                    for (let i = 0; i < updatedFilters.length; i += 1) {
                        const currentFilter = updatedFilters[i];
                        if (currentFilter.values && currentFilter.values.length > 0) {
                            queryParams[currentFilter.id] = currentFilter.values.join('|');
                        }
                    }
                }

                history.push({ ...location, search: `?${queryParams ? queryString.stringify(queryParams) : ''}` });
            },
            [history, location],
        );

        return (
            <Component {...(props as P)} filters={queryStringFilters} handleFiltersSelection={handleFiltersSelection} />
        );
    };

    return WithQuerystringFilteringComponent;
};

export const WithQuerystringFilteringAndReading = <
    T extends { [index: string]: string | number | Date | boolean | undefined }
>(
    filterKeys: (keyof T)[],
) => <P extends WithFilteringAndReadingInjectedProps<T>>(Component: React.ComponentType<P>) => {
    type WrapperType = Omit<P, keyof WithFilteringAndReadingInjectedProps<T> & T>;
    const WithQuerystringFilteringAndReadingComponent: React.FC<WrapperType> = (props: WrapperType) => {
        const { location, history } = useRouter();
        const search = queryString.parse(location.search);

        const handleFiltersSelection = (updatedFilters: T): void => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const queryParams: any = {};

            if (updatedFilters) {
                Object.keys(updatedFilters).forEach(k => {
                    if (updatedFilters[k] && Object.prototype.toString.call(updatedFilters[k]) === '[object Date]') {
                        queryParams[k] = formatTechDate(updatedFilters[k] as Date);
                    } else {
                        queryParams[k] = updatedFilters[k];
                    }
                });
            }

            history.push({
                ...location,
                search: `?${queryParams ? queryString.stringify(pick<T, keyof T>(queryParams, filterKeys)) : ''}`,
            });
        };

        return (
            <Component
                {...(props as P)}
                {...pick<T, keyof T>(parse(search), filterKeys)}
                handleFiltersSelection={handleFiltersSelection}
            />
        );
    };

    return WithQuerystringFilteringAndReadingComponent;
};
