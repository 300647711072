import React from 'react';

import { ImageContainer } from 'modules/shared-ui/components';

import { readCMSValue } from '../../utils';
import { CMSImageContainerField } from '../../cms-base-type';

import { CMSImageContainerProps } from './cms-image-container-type';

export const CMSImageContainer: React.FC<CMSImageContainerProps & { children?: React.ReactNode }> = React.memo(
    ({
        picture,
        style,
        color,
        children,
        className,
        placeholderClassName,
        onloaded,
        maxWidth,
    }: CMSImageContainerProps & { children?: React.ReactNode }) => {
        const pic = readCMSValue(picture);
        if (pic && pic.length > 0) {
            pic.sort((a: CMSImageContainerField, b: CMSImageContainerField) => b.fileSize - a.fileSize);
            const large = { large: { ...pic[0], maxWidth: maxWidth ? maxWidth.large : undefined } };
            let medium = {};
            let small = {};
            if (pic.length > 2) small = { small: { ...pic[2], maxWidth: maxWidth ? maxWidth.small : undefined } };
            if (pic.length > 1) medium = { medium: { ...pic[1], maxWidth: maxWidth ? maxWidth.medium : undefined } };
            const image = Object.assign(large, medium, small);

            return (
                <ImageContainer
                    style={style}
                    className={className}
                    placeholderClassName={placeholderClassName}
                    onloaded={onloaded}
                    {...image}
                >
                    {children}
                </ImageContainer>
            );
        }
        return (
            <ImageContainer style={style} color={readCMSValue(color)}>
                {children}
            </ImageContainer>
        );
    },
);
