import { Action, AnyAction } from 'redux';

import { config } from '../../../../../../config';

import { ListState } from '../list-state';

const REFRESH_LIST_INTERVAL_START = 'REFRESH_LIST_INTERVAL_START';
const REFRESH_LIST_INTERVAL_END = 'REFRESH_LIST_INTERVAL_END';

interface RefreshListIntervalStartAction extends Action {
    type: typeof REFRESH_LIST_INTERVAL_START;
    payload: { callback: () => void };
}

interface RefreshListIntervalEndAction extends Action {
    type: typeof REFRESH_LIST_INTERVAL_END;
}

export type RefreshListIntervalAction = RefreshListIntervalStartAction | RefreshListIntervalEndAction;

const startRefreshListInterval = (callback: () => void): RefreshListIntervalStartAction => {
    return {
        type: REFRESH_LIST_INTERVAL_START,
        payload: {
            callback,
        },
    };
};

const endRefreshListInterval = (): RefreshListIntervalEndAction => {
    return {
        type: REFRESH_LIST_INTERVAL_END,
    };
};

function refreshListIntervalReducer(state: ListState, action: AnyAction): ListState {
    switch (action.type) {
        case REFRESH_LIST_INTERVAL_START: {
            return {
                ...state,
                refreshInterval: setInterval(action.payload.callback, config.list.refreshInterval),
            };
        }
        case REFRESH_LIST_INTERVAL_END:
            if (state.refreshInterval) {
                clearInterval(state.refreshInterval);
            }
            return {
                ...state,
                refreshInterval: undefined,
            };
        default:
            return state;
    }
}

export const refreshListIntervalModule = {
    action: {
        startRefreshListInterval,
        endRefreshListInterval,
    },
    reducer: refreshListIntervalReducer,
};
