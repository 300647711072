import React from 'react';
import { Checkbox } from 'vendors/semantic-ui';

import { CheckboxListProps, CheckboxValue } from '.';

export function CheckboxList<T, U extends CheckboxValue<T> = CheckboxValue<T>>({
    values,
    onSelectionChange,
    renderLabel,
}: CheckboxListProps<T, U>): JSX.Element {
    return (
        <React.Fragment>
            {values &&
                values.map((cbProps, key) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={key}>
                            <Checkbox
                                onChange={() => {
                                    const currentCbProps = cbProps;
                                    const newVals = [...values.filter(v => v.isSelected).map(v => v.value)];
                                    if (!currentCbProps.isSelected) {
                                        newVals.push(cbProps.value);
                                    } else {
                                        const valIndex = newVals.indexOf(cbProps.value);
                                        if (valIndex > -1) {
                                            newVals.splice(valIndex, 1);
                                        }
                                    }

                                    currentCbProps.isSelected = !currentCbProps.isSelected;
                                    onSelectionChange(newVals);
                                }}
                                label={renderLabel ? renderLabel(cbProps) : cbProps.label}
                                checked={cbProps.isSelected}
                            />
                        </div>
                    );
                })}
        </React.Fragment>
    );
}
