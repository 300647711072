import axios from 'axios';
import { config } from 'config';
import { Error } from 'modules/shared/models';
import { concatUrl } from 'modules/shared/utils';
import { cmsTokenService } from './sub-services';

const restClient = axios.create({
    baseURL: concatUrl([config.cms.baseUrl, config.cms.rest.url]),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function errorResponseHandler(error: any): Promise<Error> {
    const returnedError: Error = {
        message: error.message,
        code: -1,
    };

    return Promise.reject(returnedError);
}

restClient.interceptors.request.use(
    async requestConfig => {
        const originalRequestConfig = requestConfig;
        originalRequestConfig.headers.authorization = `Bearer ${await cmsTokenService.getToken()}`;
        return originalRequestConfig;
    },
    err => Promise.reject(err),
);

restClient.interceptors.response.use(
    response => response,
    error => errorResponseHandler(error),
);

export { restClient };
