export const createCookie = (name: string, value: string, days?: number): void => {
    let expires: string;
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    } else {
        expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
};

export const readCookie = (name: string): { expires?: Date; value: string } | undefined => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            const fullValue = c.substring(nameEQ.length, c.length);
            const splittedValue = fullValue.split('; expires=');
            if (splittedValue.length === 1) {
                return { value: splittedValue[0] };
            }

            return { value: splittedValue[0], expires: new Date(splittedValue[1]) };
        }
    }
    return undefined;
};

export const eraseCookie = (name: string): void => {
    createCookie(name, '', -1);
};

export const clearCookies = (whiteList: string[]): void => {
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (!whiteList || whiteList.findIndex(w => c.indexOf(`${w}=`) === 0) === -1) {
            createCookie(c, '', -1);
        }
    }
};

export const readActiveCookie = (name: string): { expires?: Date; value: string } | undefined => {
    const cookie = readCookie(name);
    if (!cookie || !cookie.expires) {
        return cookie;
    }

    const now = new Date();
    if (now < cookie.expires) {
        eraseCookie(name);
        return undefined;
    }

    return cookie;
};
