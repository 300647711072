import React, { useMemo, useCallback } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Responsive, Select, DropdownProps } from 'vendors/semantic-ui';

import { configUI, config, routes } from 'config';

import { createPathId, createPath } from 'modules/shared/utils';
import { Separator, LinkButton } from 'modules/shared-ui/components';

import { GDPRNewsletter } from 'modules/shared-gdpr/components';
import { I18nProvider } from 'modules/shared-i18n';
import coeur from 'assets/images/coeur.png';
import { Form, Formik } from 'formik';
import { DatePickerControl, EmailInputControl, InputControl } from 'modules/shared-form';
import { CreateNewsletterLight } from 'modules/shared/models';
import { useRouter } from '../../hooks';
import { SelectControlOption } from '../../types';

import { FooterProps } from './footer-type';

import enhancer from './footer-enhancer';

import styles from './footer-component.module.css';

const Selectors = (
    stores: SelectControlOption[],
    handleGroupePrintempsChange: (value: string) => void,
    HandleSelectStore: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void,
): JSX.Element => {
    return (
        <React.Fragment>
            <Responsive as={React.Fragment} minWidth={configUI.responsive.tablet.minWidth}>
                <div className={styles.footerColumn}>
                    <Select
                        className={`${styles.selector} custom-selector footer-selector`}
                        placeholder="ESPACES PRINTEMPS LISTES"
                        options={stores}
                        onChange={HandleSelectStore}
                        style={{ minWidth: '250px' }}
                    />
                </div>
                <div className={styles.footerColumn}>
                    <Select
                        className={`${styles.selector} custom-selector footer-selector`}
                        placeholder="LE GROUPE PRINTEMPS"
                        options={configUI.selects.groups}
                        onChange={(_event, data) => handleGroupePrintempsChange(data.value as string)}
                        style={{ minWidth: '200px' }}
                    />
                </div>
            </Responsive>
            <Responsive as={React.Fragment} maxWidth={configUI.responsive.mobile.maxWidth}>
                <div className={styles.footerSection}>
                    <div className={styles.footerColumn}>
                        <Select
                            className={`${styles.selector} custom-selector footer-selector`}
                            placeholder="ESPACES PRINTEMPS LISTES"
                            options={stores}
                            onChange={HandleSelectStore}
                            style={{ minWidth: '250px' }}
                        />
                    </div>
                </div>
                <div className={styles.footerSection}>
                    <div className={styles.footerColumn}>
                        <Select
                            className={`${styles.selector} custom-selector footer-selector`}
                            placeholder="LE GROUPE PRINTEMPS"
                            options={configUI.selects.groups}
                            onChange={(_event, data) => handleGroupePrintempsChange(data.value as string)}
                            style={{ minWidth: '250px' }}
                        />
                    </div>
                </div>
            </Responsive>
        </React.Fragment>
    );
};
const help = (): JSX.Element => {
    return (
        <React.Fragment>
            <div className={styles.footerColumn}>
                <HashLink to="/aide">AIDE</HashLink>
            </div>
            <div className={styles.footerColumn}>
                <HashLink to="/questions-frequentes">QUESTIONS FRÉQUENTES</HashLink>
            </div>
            <div className={styles.footerColumn}>
                <HashLink to="/contact">CONTACT</HashLink>
            </div>
        </React.Fragment>
    );
};

interface NewsLetterBlocProps {
    manageClickNewsletter: (data: CreateNewsletterLight) => void;
}
const NewsLetterBloc: React.FC<NewsLetterBlocProps> = ({ manageClickNewsletter }: NewsLetterBlocProps) => {
    return (
        <div className={`${styles.footerSection} ${styles.newsLetterBloc}`}>
            <div className={styles.newsLetterForm}>
                <img className={styles.newsLetterHeart} alt="logo jusqu'au jour j" src={coeur} />
                <div className={styles.nlInnerForm}>
                    <p className={styles.newsLetterTitle}>OFFRES, ÉVÉNEMENTS ET INSPIRATIONS EN AVANT PREMIÈRE</p>
                    <Formik<CreateNewsletterLight>
                        initialValues={{ email: '', firstName: '', lastName: '' }}
                        onSubmit={values => {
                            manageClickNewsletter(values);
                        }}
                    >
                        <Form className={styles.inputContainer}>
                            <div className={styles.nlFirstRow}>
                                <InputControl
                                    name="firstName"
                                    required
                                    className={styles.nlInput}
                                    placeholder="Votre prénom *"
                                />
                                <InputControl
                                    name="lastName"
                                    required
                                    className={styles.nlInput}
                                    placeholder="Votre nom *"
                                />
                                <EmailInputControl
                                    name="email"
                                    required
                                    className={styles.nlInput}
                                    placeholder="Votre adresse mail *"
                                />
                            </div>
                            <div className={styles.nlSecondRow}>
                                <DatePickerControl
                                    name="eventDate"
                                    className={styles.nlInput}
                                    placeholder="Votre date d'événement"
                                    hideIcon
                                />
                                <button type="submit" className={styles.newsLetterBtn}>
                                    S&#39;INSCRIRE À LA NEWSLETTER
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
            <div className={styles.footerColumn}>
                <div>
                    <a href={config.sn.instagram} rel="noopener noreferrer" target="_blank" aria-label="instagram link">
                        <span className={`${styles.socialBtn} ${styles.instagramBtn}`} />
                    </a>
                    <a href={config.sn.pinterest} rel="noopener noreferrer" target="_blank" aria-label="pinterest link">
                        <span className={`${styles.socialBtn} ${styles.pinterestBtn}`} />
                    </a>
                </div>
            </div>
        </div>
    );
};

const legalSection = (openTracerAdminPanel: VoidFunction): JSX.Element => {
    return (
        <div className={`${styles.footerSection} ${styles.legalSection}`}>
            <div className={styles.footerColumn}>
                <HashLink to="/mention-legale#infos-legales">INFOS LÉGALES</HashLink>
            </div>
            <div className={styles.footerColumn}>
                <a
                    href="https://www.printemps.com/fr/fr/protection-donnees-personnelles"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    PROTECTION DES DONNÉES
                </a>
            </div>
            <div className={styles.footerColumn}>
                <LinkButton onClick={openTracerAdminPanel}>GESTION DES COOKIES</LinkButton>
            </div>
            <div className={styles.footerColumn}>
                <Link to="/centre-de-preferences">CENTRE DE PRÉFÉRENCES</Link>
            </div>
            <div className={styles.footerColumn}>
                <Link to="/reglement">JEUX ET RÈGLEMENTS</Link>
            </div>
        </div>
    );
};
const InnerFooter: React.FC<FooterProps> = React.memo(
    ({ stores, showAdminPanel, createNewsletterLight }: FooterProps) => {
        const convertedStores: SelectControlOption[] = useMemo(
            () =>
                stores
                    ? [
                          { key: '-1', value: '', text: '--' },
                          ...stores.map((store, index) => {
                              return {
                                  key: `${index}`,
                                  value: `${store.id}`,
                                  text: store.name,
                              };
                          }),
                      ]
                    : [],
            [stores],
        );

        const handleGroupePrintempsChange = useCallback((value: string) => {
            const option = configUI.selects.groups.find(o => o.value === value);

            if (!option) return;

            const win = window.open(option.url, '_blank');

            if (!win) return;

            win.focus();
        }, []);

        const { history } = useRouter();
        const HandleSelectStore = useCallback(
            (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                if (event && data) {
                    const selectionText = event.currentTarget.textContent ? event.currentTarget.textContent : '';
                    history.push(`${routes.magasin}/${createPathId(createPath(selectionText), `${data.value}`)}`);
                }
            },
            [history],
        );

        const HandleClickNewsletter = useCallback((data: CreateNewsletterLight) => {
            createNewsletterLight(data);
        }, []);

        return (
            <I18nProvider>
                <div className="elementNotToPrint">
                    <Separator />
                    <div className={`ui vertical footer container ${styles.footer}`}>
                        <div className="ui center aligned">
                            <Responsive as={React.Fragment} minWidth={configUI.responsive.tablet.minWidth}>
                                <NewsLetterBloc manageClickNewsletter={HandleClickNewsletter} />
                                <div className={`${styles.footerSection} ${styles.contactSection}`}>
                                    {help()}
                                    {Selectors(convertedStores, handleGroupePrintempsChange, HandleSelectStore)}
                                </div>
                                {legalSection(showAdminPanel)}
                                <div />
                            </Responsive>
                            <Responsive as={React.Fragment} maxWidth={configUI.responsive.mobile.maxWidth}>
                                <NewsLetterBloc manageClickNewsletter={HandleClickNewsletter} />
                                <Separator />
                                <div className={styles.footerSection}>{help()}</div>
                                {Selectors(convertedStores, handleGroupePrintempsChange, HandleSelectStore)}
                                <Separator />
                                {legalSection(showAdminPanel)}
                            </Responsive>
                            <GDPRNewsletter footer />
                        </div>
                    </div>
                </div>
            </I18nProvider>
        );
    },
);

export const Footer = enhancer(InnerFooter);
