import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { LightListElement, LightList } from '../../../../models';
import { currentListService } from '../../../../services/API/backend';
import { ListState } from '..';

const REQUEST_REFRESH_LIST = 'REQUEST_REFRESH_LIST';
const SUCCESS_REFRESH_LIST = 'SUCCESS_REFRESH_LIST';
const ERROR_REFRESH_LIST = 'ERROR_REFRESH_LIST';

interface RequestRefreshListAction extends Action {
    type: typeof REQUEST_REFRESH_LIST;
}

interface SuccessRefreshListAction extends Action {
    type: typeof SUCCESS_REFRESH_LIST;
    payload: { updatedElements: LightListElement[] };
}

interface ErrorRefreshListAction extends Action {
    type: typeof ERROR_REFRESH_LIST;
    payload: { error: Error };
}

export type RefreshListAction = RequestRefreshListAction | SuccessRefreshListAction | ErrorRefreshListAction;

// Action creators
const requestAction = (): RequestRefreshListAction => {
    return { type: REQUEST_REFRESH_LIST };
};

const successAction = (updatedElements: LightListElement[]): SuccessRefreshListAction => {
    return {
        type: SUCCESS_REFRESH_LIST,
        payload: {
            updatedElements,
        },
    };
};

const failureAction = (error: Error): ErrorRefreshListAction => {
    return {
        type: ERROR_REFRESH_LIST,
        payload: {
            error,
        },
    };
};

const refreshListAction = (): ThunkAction<Promise<void>, {}, {}, RefreshListAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, RefreshListAction>): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            dispatch(requestAction());

            try {
                const data = await currentListService.getSummary();
                dispatch(successAction(data ? data.elements : []));
                resolve();
            } catch (error) {
                dispatch(failureAction(error));
                reject(error);
            }
        });
    };
};

const updateLocalList = (localList: LightList | null, updatedElements: LightListElement[]): LightList | null => {
    if (!updatedElements) {
        return localList;
    }

    if (!localList || !localList.elements) {
        return {
            elements: updatedElements,
        };
    }

    const outputElements: LightListElement[] = [];

    updatedElements.forEach(e => {
        const existingElement = localList.elements.find(
            ee => (ee.id && ee.id === e.id) || (ee.variantId && ee.variantId === e.variantId),
        );
        if (existingElement) {
            existingElement.id = e.id;
            existingElement.quantity = e.quantity;
            outputElements.push(existingElement);
        } else {
            outputElements.push(e);
        }
    });

    return { elements: outputElements.concat(localList.elements.filter(e => !e.id)) };
};

const refreshListReducer = (state: ListState, action: AnyAction): ListState => {
    switch (action.type) {
        case REQUEST_REFRESH_LIST:
            return { ...state, isRefreshing: true };
        case SUCCESS_REFRESH_LIST:
            return {
                ...state,
                currentList: updateLocalList(state.currentList, action.payload.updatedElements),
                isRefreshing: false,
            };
        case ERROR_REFRESH_LIST:
            return { ...state, isRefreshing: false, error: action.payload.error };
        default:
            return state;
    }
};

export const refreshListModule = {
    reducer: refreshListReducer,
    action: {
        refreshList: refreshListAction,
    },
};
