import React from 'react';
import { Container } from 'vendors/semantic-ui';

import { getCatalogueCDNUrl } from 'modules/shared/utils';

import { HashLink } from 'react-router-hash-link';
import { ConsentBannerProps } from './consent-banner-type';
import enhancer from './consent-banner-enhancer';

import styles from './consent-banner-component.module.css';

const InnerConsentBanner: React.FC<ConsentBannerProps> = ({
    isVisible,
    acceptAll,
    rejectAll,
    showAdminPanel,
}: ConsentBannerProps) => {
    if (!isVisible) {
        return <React.Fragment />;
    }

    return (
        <Container fluid className={styles.container}>
            <div className={styles.topRow}>
                <img className={styles.logo} src={getCatalogueCDNUrl('printempslistesnoir.png')} alt="Site Logo" />
                <button
                    className={`${styles.button} ${styles.onlyText} ${styles.underlined}`}
                    type="button"
                    onClick={rejectAll}
                >
                    Continuer sans accepter
                </button>
            </div>

            <div className={styles.midRow}>
                <p>
                    listes.printemps.com, et les <b>tiers sélectionnés</b>
                    , utilisent des cookies sur votre appareil pour analyser et améliorer votre expérience utilisateur,
                    la navigation sur le site, notre offre de produits et services, mesurer et analyser l&apos;audience
                    et réaliser des statistiques. Pour plus d&apos;informations sur chaque finalités, veuillez cliquer
                    dans le gestionnaire ci-dessous sur les compléments d&apos;information.
                    <br />
                    <br />
                    Vous pouvez choisir l&apos;usage que nous ferons de vos données en cliquant sur{' '}
                    <b>&laquo; Paramétrer &raquo;</b>.
                    <br />
                    <br />
                    Vous pouvez également accepter les cookies en cliquant sur <b>&laquo; J&apos;accepte &raquo;</b> ou
                    refuser tous les cookies qui ne sont pas strictement nécessaires au fonctionnement du site en
                    cliquant sur <b>&laquo; Continuer sans accepter &raquo;</b>. Dans ce cas vous n&apos;aurez plus
                    accès à du contenu personnalisé basé sur votre navigation et nous ne tiendrons pas compte de votre
                    usage pour améliorer notre site.
                    <br />
                    <br />
                    Vous pourrez mettre à jour votre choix à tout moment en cliquant sur le lien{' '}
                    <b>Gestion des cookies</b> en bas de notre site.
                    <br />
                    <br />
                    Pour plus d&apos;information, consultez notre <b>Politique de gestion des cookies</b>.
                </p>
            </div>
            <div className={styles.botRow}>
                <button className={`${styles.button} ${styles.withBorder}`} type="button" onClick={showAdminPanel}>
                    Paramétrer
                </button>
                <button className={`${styles.button} ${styles.withBorder}`} type="button" onClick={acceptAll}>
                    J&apos;accepte
                </button>
            </div>
        </Container>
    );
};

export const ConsentBanner = enhancer(InnerConsentBanner);
