import queryString from 'query-string';
import { config } from '../../../../../config';
import { restClient } from '.';
import { GetSummaryPrivateFolderByEmail, SummaryPrivateFolder } from '../../../models';

export const privateFolderService = {
    getByDateEmailAndUniverse: async ({ date, email, universeId }: GetSummaryPrivateFolderByEmail) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const queryParams: any = {
            email,
            universeId,
        };

        // TODO : revoir le formattage des dates et passer par un helper
        const response = await restClient.get<SummaryPrivateFolder>(
            `${config.backend.rest.listUrl}/privatefolder/date/${new Date(date).toISOString()}?${queryString.stringify(
                queryParams,
            )}`,
        );
        return response.data;
    },
};
