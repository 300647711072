import { Action, AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { RootState } from '../../../store';

import { FastUpsertListElement, Error } from '../../../../models';
import { ListState } from '../list-state';

import { updateBufferList, mergeLocalList } from './utils';

export const SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY = 'SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY';
const ERROR_UPDATE_LOCAL_ELEMENTS_QUANTITY = 'ERROR_UPDATE_LOCAL_ELEMENTS_QUANTITY';

interface SuccessUpdateLocalElementsQuantityAction extends Action {
    type: typeof SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY;
    payload: { elements: FastUpsertListElement[] };
}

interface ErrorUpdateLocalElementsQuantityAction extends Action {
    type: typeof ERROR_UPDATE_LOCAL_ELEMENTS_QUANTITY;
    payload: { error: Error };
}

export type UpdateLocalElementsQuantityAction =
    | SuccessUpdateLocalElementsQuantityAction
    | ErrorUpdateLocalElementsQuantityAction;

const successAction = (elements: FastUpsertListElement[]): SuccessUpdateLocalElementsQuantityAction => {
    return {
        type: SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY,
        payload: {
            elements,
        },
    };
};

const failureAction = (error: Error): ErrorUpdateLocalElementsQuantityAction => {
    return {
        type: ERROR_UPDATE_LOCAL_ELEMENTS_QUANTITY,
        payload: {
            error,
        },
    };
};

const updateLocalElementsQuantityAction = (
    ElementsToUpdate: FastUpsertListElement[],
): ThunkAction<void, RootState, {}, UpdateLocalElementsQuantityAction> => {
    return (
        dispatch: ThunkDispatch<RootState, {}, UpdateLocalElementsQuantityAction>,
        getState: () => RootState,
    ): void => {
        const { currentList } = getState().list;
        const currentElements = currentList ? currentList.elements : [];
        for (let index = 0; index < ElementsToUpdate.length; index += 1) {
            const currentUpdatedElement = ElementsToUpdate[index];
            const currentElement = currentElements.find(f => f.variantId === currentUpdatedElement.variantId);
            // On ne peut pas supprimer un element reservé
            if (currentElement && currentElement.reservedQuantity) {
                if (!currentElement.quantity || currentElement.reservedQuantity > currentElement.quantity) {
                    dispatch(failureAction({ message: 'ITEM_RESERVED', code: 400, subCode: 32.1 }));
                }
            }
        }

        dispatch(successAction(ElementsToUpdate));
    };
};

const updateLocalElementsQuantityReducer = (state: ListState, action: AnyAction): ListState => {
    switch (action.type) {
        case SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY:
            return {
                ...state,
                bufferList: updateBufferList(state.currentList, state.bufferList, action.payload.elements),
                currentList: mergeLocalList(state.currentList, action.payload.elements),
            };
        case ERROR_UPDATE_LOCAL_ELEMENTS_QUANTITY:
            return { ...state, error: action.payload.error };
        default:
            return state;
    }
};

export const updateLocalElementsQuantityModule = {
    reducer: updateLocalElementsQuantityReducer,
    action: {
        updateLocalElementsQuantity: updateLocalElementsQuantityAction,
    },
};
