import { connect } from 'react-redux';

import { FolderTypeValues } from 'modules/shared/models';
import { ListState, listModule, AuthenticationState, authenticationModule } from '../../../redux';

import { ListButtonStateProps } from './list-button-type';

const sumReducer = (accumulator: number, item: number): number => accumulator + item;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (states: any): ListButtonStateProps => {
    const listSubState = states[listModule.storeName] as ListState;
    const authenticationSubState = states[authenticationModule.storeName] as AuthenticationState;

    return {
        productQuantity:
            listSubState &&
            listSubState.currentList &&
            listSubState.currentList.elements &&
            listSubState.currentList.elements.length > 0
                ? listSubState.currentList.elements.map(g => g.quantity).reduce(sumReducer)
                : null,
        productCount:
            listSubState &&
            listSubState.currentList &&
            listSubState.currentList.elements &&
            listSubState.currentList.elements.length > 0
                ? listSubState.currentList.elements.length
                : null,
        isAuthenticated:
            authenticationSubState && authenticationSubState.token && authenticationSubState.loggedInFolder
                ? authenticationSubState.token.isValid() &&
                  authenticationSubState.loggedInFolder.type === FolderTypeValues.CUSTOMER
                : false,
    };
};

const reduxConnect = connect<ListButtonStateProps>(mapStateToProps);

const enhancer = reduxConnect;

export default enhancer;
