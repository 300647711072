export class Subscriptions {
    mariage: boolean = false;

    naissance: boolean = false;

    cadeaux: boolean = false;

    voyage: boolean = false;

    robes: boolean = false;
}
