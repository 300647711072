import { AnyAction } from 'redux';
import { Module } from '../../utils/type';

const START = 'GLOBAL_LOADER_START';
const END = 'GLOBAL_LOADER_END';

const showLoading = (): AnyAction => {
    return {
        type: START,
    };
};

const hideLoading = (): AnyAction => {
    return {
        type: END,
    };
};

function globalLoadingReducer(state: GlobalLoadingState = { count: 0 }, action: AnyAction): GlobalLoadingState {
    switch (action.type) {
        case START:
            return {
                ...state,
                count: (state.count || 0) + 1,
            };
        case END:
            return {
                ...state,
                count: Math.max(0, (state.count || 1) - 1),
            };
        default:
            return state;
    }
}

export interface GlobalLoadingState {
    count: number;
}

export interface GlobalLoadingActionDescriptor {
    showLoading: () => AnyAction;
    hideLoading: () => AnyAction;
}

export const globalLoaderModule: Module<GlobalLoadingState, GlobalLoadingActionDescriptor> = {
    storeName: 'globalLoader',
    action: {
        showLoading,
        hideLoading,
    },
    reducer: globalLoadingReducer,
};
