import { AuthToken } from '../../../../../models';

export const backendTokenService = {
    getToken: (): AuthToken | undefined => {
        const tokenValue = localStorage.getItem('backend_access_token');
        if (!tokenValue) {
            return undefined;
        }

        return JSON.parse(tokenValue) as AuthToken;
    },
};
