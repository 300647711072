import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { RootState } from '../../../redux/store';
import { tracerModule } from '../../../redux';

import { ConsentBannerStateProps, ConsentBannerDispatchProps } from './consent-banner-type';

const mapStateToProps = (states: RootState): ConsentBannerStateProps => {
    const subState = states.tracer;
    return {
        isVisible: !subState || !subState.consents || subState.consents.some(c => c.value === undefined),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ConsentBannerDispatchProps => ({
    acceptAll: () => dispatch(tracerModule.action.consents.acceptAll()),
    rejectAll: () => dispatch(tracerModule.action.consents.rejectAll()),
    showAdminPanel: () => dispatch(tracerModule.action.adminPanel.show()),
});

const reduxConnect = connect<ConsentBannerStateProps, ConsentBannerDispatchProps, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
);

const enhancer = reduxConnect;

export default enhancer;
