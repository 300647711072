import { CMSContainerField } from '../cms-base-type';
import { isCMSContainerField } from './is-cms-container-field';

export const readCMSValue = <T>(val?: T | CMSContainerField<T>): T | undefined => {
    if (!val) {
        return val;
    }

    if (isCMSContainerField(val)) {
        return val.iv;
    }

    return val;
};
