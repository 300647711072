import React from 'react';

import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import {
    Actualite,
    DescriptionContainer,
    DescriptionContainerProps,
    getNestedReferenceId,
    Introduction,
    Mention,
    RibbonBlocContainer,
    TitleBlocContainer,
} from 'modules/shared-cms-advanced';

import { HomeAltProps } from './home-alt-type';

import enhancer from './home-alt-enhancer';

import styles from './home-alt-page.module.css';

const InnerHomeAltPage: React.FC<HomeAltProps> = React.memo(
    ({
        text1,
        textColor1,
        fontSize1,
        text2,
        textColor2,
        fontSize2,
        introduction1,
        introduction2,
        introduction3,
        actualite1,
        actualite2,
        actualite3,
        mentionRef,
        ...rest
    }: HomeAltProps) => {
        const descBloc1: DescriptionContainerProps = {
            description: text1,
            descriptionTextColor: textColor1,
            descriptionFontSize: fontSize1,
        };
        const descBloc2: DescriptionContainerProps = {
            description: text2,
            descriptionTextColor: textColor2,
            descriptionFontSize: fontSize2,
        };

        const introduction1Id = getNestedReferenceId(introduction1);
        const introduction2Id = getNestedReferenceId(introduction2);
        const introduction3Id = getNestedReferenceId(introduction3);

        const actualite1Id = getNestedReferenceId(actualite1);
        const actualite2Id = getNestedReferenceId(actualite2);
        const actualite3Id = getNestedReferenceId(actualite3);

        const mentionId = getNestedReferenceId(mentionRef);

        return (
            <div className={styles.rootContainer}>
                <TitleBlocContainer {...rest} fullWidth />

                <div className={styles.description1}>
                    <DescriptionContainer {...descBloc1} />
                </div>

                {introduction1Id && <Introduction id={introduction1Id} />}

                <div className={styles.description2}>
                    <DescriptionContainer {...descBloc2} />
                </div>

                {actualite1Id && <Actualite id={actualite1Id} />}

                {introduction2Id && <Introduction id={introduction2Id} />}

                <Container className={styles.ribbonContainer}>
                    <RibbonBlocContainer {...rest} />
                </Container>

                {actualite2Id && <Actualite id={actualite2Id} />}

                {introduction3Id && <Introduction id={introduction3Id} />}

                {actualite3Id && <Actualite id={actualite3Id} />}

                {mentionId && <Mention id={mentionId} />}
            </div>
        );
    },
);

export const HomeAltPage = enhancer(InnerHomeAltPage);
