/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType } from 'react';
import { SEO } from 'modules/shared/components';

import { PageTitleProps, SEODescriptionProps, SEOKeywordsProps, SEOThumbnailProps } from '../../cms-base-type';
import { readCMSValue } from '../../utils';

const pageTitlePropsCheck = (toBeDetermined: PageTitleProps | any): toBeDetermined is PageTitleProps => {
    if ((toBeDetermined as PageTitleProps).pageTitle) {
        return true;
    }

    return false;
};

const readPageTitle = (cmsDatas: any): string | undefined => {
    if (cmsDatas && pageTitlePropsCheck(cmsDatas)) {
        const pageTitle = readCMSValue(cmsDatas.pageTitle);
        if (pageTitle) {
            return pageTitle;
        }
    }

    return undefined;
};

const descriptionPropsCheck = (toBeDetermined: SEODescriptionProps | any): toBeDetermined is SEODescriptionProps => {
    if ((toBeDetermined as SEODescriptionProps).seoDescription) {
        return true;
    }

    return false;
};

const readDescription = (cmsDatas: any): string | undefined => {
    if (cmsDatas && descriptionPropsCheck(cmsDatas)) {
        const description = readCMSValue(cmsDatas.seoDescription);
        if (description) {
            return description;
        }
    }

    return undefined;
};

const keywordsPropsCheck = (toBeDetermined: SEOKeywordsProps | any): toBeDetermined is SEOKeywordsProps => {
    if ((toBeDetermined as SEOKeywordsProps).seoKeywords) {
        return true;
    }

    return false;
};

const readKeywords = (cmsDatas: any): string[] | undefined => {
    if (cmsDatas && keywordsPropsCheck(cmsDatas)) {
        const keywords = readCMSValue(cmsDatas.seoKeywords);
        if (keywords) {
            return keywords;
        }
    }

    return undefined;
};

const thumbnailPropsCheck = (toBeDetermined: SEOThumbnailProps | any): toBeDetermined is SEOThumbnailProps => {
    if ((toBeDetermined as SEOThumbnailProps).seoThumbnail) {
        return true;
    }

    return false;
};

const readThumbnail = (cmsDatas: any): string | undefined => {
    if (cmsDatas && thumbnailPropsCheck(cmsDatas)) {
        const thumbnail = readCMSValue(cmsDatas.seoThumbnail);
        if (thumbnail && thumbnail.length > 0) {
            return thumbnail[0].url;
        }
    }

    return undefined;
};

export const WithCMSSEO = <P extends {}>(
    getPageTitle?: (props?: P) => string | undefined,
    disablePageTitleSuffix?: boolean,
) => (Component: ComponentType<P>): React.FC<P> => {
    const WithCMSSEOComponent: React.FC<P> = (props: P) => {
        return (
            <React.Fragment>
                <SEO
                    disablePageTitleSuffix={disablePageTitleSuffix}
                    pageTitle={getPageTitle ? getPageTitle(props) : readPageTitle(props)}
                    description={readDescription(props)}
                    keywords={readKeywords(props)}
                    img={readThumbnail(props)}
                />
                <Component {...props} />
            </React.Fragment>
        );
    };

    return WithCMSSEOComponent;
};
