import React from 'react';
import { Trans } from 'react-i18next';

import { Container } from '../base-components';

export const GDPRDonor: React.FC = () => {
    return (
        <Container>
            <p>
                <Trans i18nKey="gdpr.intro.donor">
                    part1
                    <a
                        href="https://www.printemps.com/fr/fr/protection-donnees-personnelles"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ici
                    </a>
                    part2
                </Trans>
                <br />
                <Trans i18nKey="shared.mentions.requiredFields" />
            </p>
        </Container>
    );
};
