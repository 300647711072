import gql from 'graphql-tag';

export const QUERY = gql([
    `
    query FindHomeV2Content {
        queryHomeV2Contents {
            data {
                pageTitle { iv }

                titleBlocTitle { iv }
                titleBlocSubTitle { iv }
                titleBlocFontSizeTitle { iv }
                titleBlocFontSizeSubTitle { iv }
                titleBlocWidth { iv }
                titleBlocTagline { iv }
                titleBlocTextColor { iv }
                titleBlocColor { iv }
                titleBlocCtaLink { iv }
                titleBlocCtaText { iv }
                titleBlocCtaStyle { iv }
                titleBlocCtaLink2 { iv }
                titleBlocCtaText2 { iv }
                titleBlocCtaStyle2 { iv }
                titleBlocPicture {
                  iv {
                    url
                    fileSize
                  }
                }
                titleBlocHorizontalPosition { iv }
                titleBlocVerticalPosition { iv }
                titleBlocJustification { iv }

                text1 { iv }
                textColor1 { iv }
                fontSize1 { iv }

                introduction1 { iv { id } }

                text2 { iv }
                textColor2 { iv }
                fontSize2 { iv }

                actualite1 { iv { id } }

                introduction2 { iv { id } }

                ribbonTitle { iv }
                ribbonColor { iv }
                ribbonPicture { 
                    iv {
                        url
                        fileSize
                    }
                }
                ribbonList {
                    iv {
                        title
                        subTitle
                        picture {
                            url
                            fileSize
                        }
                        color
                    }
                }

                actualite2 { iv { id } }

                introduction3 { iv { id } }

                actualite3 { iv { id } }

                mentionRef { iv { id } }

                seoDescription { iv }
                seoKeywords { iv }
                seoThumbnail {
                    iv {
                        url
                    }
                }
            }
        }
    }
`,
]);
