import { config } from '../../../../../config';
import { restClient } from '.';
import { Offer, GetOffer } from '../../../models';

export const offerService = {
    getCurrent: async () => {
        const response = await restClient.get<Offer>(`${config.backend.rest.listUrl}/offer/current`);
        return response.data;
    },

    getCurrentByUniverse: async ({ universeId }: GetOffer) => {
        const response = await restClient.get<Offer>(
            `${config.backend.rest.listUrl}/universe/${universeId}/offer/current`,
        );
        return response.data;
    },
};
