import { config } from '../../../../../config';
import { restClient } from '.';
import {
    CreateNewsletter,
    CreateNewsletterLight,
    GetNewsletterByToken,
    Newsletter,
    UpdateNewsletter,
} from '../../../models';

export const newsletterService = {
    requestAccess: async (email: string) => {
        const response = await restClient.post<void>(
            `${config.backend.rest.commonUrl}/newsletter/${encodeURIComponent(email)}/requestaccess`,
        );
        return response.data;
    },
    create: async (data: CreateNewsletter) => {
        const response = await restClient.post<void>(`${config.backend.rest.commonUrl}/newsletter`, data);
        return response.data;
    },
    createLight: async (data: CreateNewsletterLight) => {
        const response = await restClient.post<void>(
            `${config.backend.rest.commonUrl}/newsletter/subscribelight`,
            data,
        );
        return response.data;
    },
    update: async (data: UpdateNewsletter) => {
        const response = await restClient.put<void>(`${config.backend.rest.commonUrl}/newsletter`, data);
        return response.data;
    },
    getByToken: async ({ Token }: GetNewsletterByToken) => {
        const response = await restClient.get<Newsletter>(
            `${config.backend.rest.commonUrl}/newsletter/token?token=${encodeURIComponent(Token)}`,
        );
        return response.data;
    },
    delete: async (email: string) => {
        const response = await restClient.delete<void>(
            `${config.backend.rest.commonUrl}/newsletter/${encodeURIComponent(email)}/`,
        );
        return response.data;
    },
};
