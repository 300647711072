import { Action, AnyAction } from 'redux';
import { TracerState } from '..';

export const SHOW_TRACER_ADMIN_PANEL = 'SHOW_TRACER_ADMIN_PANEL';

export const HIDE_TRACER_ADMIN_PANEL = 'HIDE_TRACER_ADMIN_PANEL';

interface ShowAdminPanelAction extends Action {
    type: typeof SHOW_TRACER_ADMIN_PANEL;
}

interface HideAdminPanelAction extends Action {
    type: typeof HIDE_TRACER_ADMIN_PANEL;
}

export type AdminPanelAction = ShowAdminPanelAction | HideAdminPanelAction;

const showAction = (): ShowAdminPanelAction => {
    return {
        type: SHOW_TRACER_ADMIN_PANEL,
    };
};

const hideAction = (): HideAdminPanelAction => {
    return {
        type: HIDE_TRACER_ADMIN_PANEL,
    };
};

function adminPanelReducer(state: TracerState, action: AnyAction): TracerState {
    switch (action.type) {
        case SHOW_TRACER_ADMIN_PANEL:
            return { ...state, adminPanelEnabled: true };
        case HIDE_TRACER_ADMIN_PANEL: {
            return { ...state, adminPanelEnabled: false };
        }
        default:
            return state;
    }
}

export const adminPanelModule = {
    reducer: adminPanelReducer,
    action: {
        show: showAction,
        hide: hideAction,
    },
};
