import React from 'react';

import { GenericList } from '../../../generic-list';
import { DesktopMenuProps } from './desktop-menu-type';
import { MenuItem } from '../../menu-type';
import { DesktopPanel } from './components';

export const DesktopMenu: React.FC<DesktopMenuProps> = React.memo(
    ({ elements, top, verticalDisplay }: DesktopMenuProps) => {
        if (elements) {
            return (
                <GenericList
                    items={elements}
                    itemRenderer={(childElement: MenuItem, key: number) => {
                        return (
                            <DesktopPanel
                                key={key}
                                element={childElement}
                                top={top}
                                verticalDisplay={verticalDisplay}
                            />
                        );
                    }}
                />
            );
        }

        return <React.Fragment />;
    },
);
