import * as React from 'react';
import { Placeholder, Grid } from 'vendors/semantic-ui';

import { useHover } from 'modules/shared/hooks';
import { CtaButton, ClickableContainer } from 'modules/shared-ui/components';
import { readCMSValue, getDefault, buildCMSStyles } from 'modules/shared-cms/utils';
import { SubTitleProps, TextColorProps, TitleProps } from 'modules/shared-cms/cms-base-type';
import { CMSImageContainer } from 'modules/shared-cms/components';

import { LargeBlocProps } from './large-bloc-type';

import styles from './large-bloc-component.module.css';

const LargeBlocContainer: React.FC = props => {
    const { children } = props;
    return <div className={`ui vertical segment full-height ${styles.rootContainer}`}>{children}</div>;
};

export const LargeBloc: React.FC<LargeBlocProps> = ({
    title: wrappedTitle,
    subTitle: wrappedSubTitle,
    color: wrappedColor,
    picture: wrappedPicture,
    ctaText: wrappedCtaText,
    ctaLink: wrappedCtaLink,
    ctaStyle: wrappedCtaStyle,
    textColor: wrappedTextColor,
}: LargeBlocProps) => {
    const { hovered, bind } = useHover();

    const {
        title = readCMSValue(wrappedTitle),
        subTitle = readCMSValue(wrappedSubTitle),
        color = readCMSValue(wrappedColor),
        picture = getDefault(readCMSValue(wrappedPicture), []),
        ctaText = readCMSValue(wrappedCtaText),
        ctaLink = readCMSValue(wrappedCtaLink),
        ctaStyle = readCMSValue(wrappedCtaStyle),
        textColor = readCMSValue(wrappedTextColor),
    } = {};

    return (
        <LargeBlocContainer>
            <ClickableContainer link={ctaLink} innerClassName={`${styles.background} `} onHover={bind}>
                <CMSImageContainer
                    picture={picture}
                    color={color}
                    style={{ width: '100%' }}
                    maxWidth={{ large: 800, medium: 600, small: 400 }}
                >
                    <div className={styles.innerWrapper}>
                        {title ? (
                            <div className={styles.title}>
                                <h1
                                    style={buildCMSStyles<TitleProps & TextColorProps>({
                                        title,
                                        fontSizeTitle: 31,
                                        textColor,
                                    })}
                                >
                                    {title}
                                </h1>
                            </div>
                        ) : (
                            <div className={styles.title}>&nbsp;</div>
                        )}

                        {subTitle ? (
                            <div
                                className={styles.subTitle}
                                style={buildCMSStyles<SubTitleProps & TextColorProps>({
                                    subTitle,
                                    fontSizeSubTitle: 25,
                                    textColor,
                                })}
                            >
                                {subTitle}
                            </div>
                        ) : (
                            <div className={styles.subTitle}>&nbsp;</div>
                        )}
                        {ctaText && ctaLink ? (
                            <div className={styles.ctaButtonContainer}>
                                <CtaButton forceHover={!!hovered} ctaText={ctaText} ctaStyle={ctaStyle} />
                            </div>
                        ) : (
                            <div className={styles.ctaButtonContainer}>&nbsp;</div>
                        )}
                    </div>
                </CMSImageContainer>
            </ClickableContainer>
        </LargeBlocContainer>
    );
};

export const LargeBlocLoader: React.FC = React.memo(() => {
    return (
        <LargeBlocContainer>
            <Placeholder className="full-height full-width">
                <Placeholder.Image />
            </Placeholder>
        </LargeBlocContainer>
    );
});

export const LargeBlocListContainer: React.FC = React.memo(props => {
    const { children } = props;
    return (
        <Grid stackable className="full-height">
            {React.Children.toArray(children).map((child, key) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid.Column key={key} mobile={16} tablet={8} computer={8}>
                        {child}
                    </Grid.Column>
                );
            })}
        </Grid>
    );
});
