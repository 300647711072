import { AnyAction } from 'redux';
import { Module } from '../../utils/type';

import { NavigationElement } from '../../../models';

export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';

const updateNavigation = (data?: NavigationElement): AnyAction => {
    return {
        type: UPDATE_NAVIGATION,
        payload: {
            currentPage: data,
        },
    };
};

function navigationReducer(state: NavigationState = { currentPage: undefined }, action: AnyAction): NavigationState {
    switch (action.type) {
        case UPDATE_NAVIGATION:
            return { ...state, currentPage: action.payload.currentPage };
        default:
            return state;
    }
}

export interface NavigationState {
    currentPage?: NavigationElement;
}

export interface NavigationActionDescriptor {
    updateNavigation: (data?: NavigationElement) => AnyAction;
}

export const navigationModule: Module<NavigationState, NavigationActionDescriptor> = {
    storeName: 'navigation',
    action: {
        updateNavigation,
    },
    reducer: navigationReducer,
};
