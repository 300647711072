import { LocationState } from 'history';
import { Context, useContext, useEffect } from 'react';
import { __RouterContext, RouteComponentProps, StaticContext } from 'react-router';
import { useForceUpdate } from './use-force-update';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyContext = Context<RouteComponentProps<any, any, any>>;

const MISSING_CONTEXT_ERROR: Error = new Error('useReactRouter may only be called within a <Router /> context.');

export const useRouter = <
    P extends { [K in keyof P]?: string } = {},
    C extends StaticContext = StaticContext,
    S = LocationState
>(): RouteComponentProps<P, C, S> => {
    const context: RouteComponentProps<P, C, S> = useContext<RouteComponentProps<P, C, S>>(
        (__RouterContext as AnyContext) as Context<RouteComponentProps<P, C, S>>,
    );
    if (!context) {
        throw MISSING_CONTEXT_ERROR;
    }

    const forceUpdate: VoidFunction = useForceUpdate();
    useEffect((): VoidFunction => context.history.listen(forceUpdate), [context, forceUpdate]);
    return context;
};
