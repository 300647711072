const routes = {
    catalogue: {
        landing: '/catalogue',
        brand: '/catalogue/marques',
        product: '/catalogue/produit',
    },
    creerListe: {
        cadeauxNaissance: '/listes/creer-liste/nom/', // pour cadeaux et naissance
        voyageMariage: '/listes/creer-liste/type/', // pour voyage et mariage
    },
    espacePerso: {
        login: '/listes/espace-perso/login',
        client: {
            landing: '/listes/espace-perso/client',
            maListe: {
                gerer: '/listes/espace-perso/client/ma-liste/gerer',
                fairePart: '/listes/espace-perso/client/ma-liste/faire-part',
                communiquerMaListe: '/listes/espace-perso/client/ma-liste/communiquer',
                mesAvisCadeaux: '/listes/espace-perso/client/ma-liste/mes-avis-cadeaux',
                imprimerMesAvisCadeaux: '/listes/espace-perso/client/ma-liste/imprimer-mes-avis-cadeaux',
            },
        },
    },
    partenaires: {
        basique: '/listes/avantages-services/partenaires/partenaire',
        privilege: '/listes/avantages-services/partenaires/privilege',
    },
    dons: {
        choixDons: '/dons/choix-dons',
    },
    laRobe: {
        modeles: '/mariage/la-robe/modeles',
        createurs: '/mariage/la-robe/createurs',
        dressFitting: {
            createAppointment: '/mariage/la-robe/essayages-robes-de-mariee/prise-rdv',
            manageAppointment: '/mariage/la-robe/essayages-robes-de-mariee/gerer-rdv',
        },
    },
    magasin: '/magasin',
};

export { routes };
