import { config } from '../../../../../config';
import { restClient } from '.';
import { Structure } from './internal-models/structure';

export const structureService = {
    get: async () => {
        const response = await restClient.get<Structure>(`${config.backend.rest.catalogueUrl}/hierarchy`);
        return response.data;
    },
};
