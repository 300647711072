import { Action, AnyAction } from 'redux';

import { config } from '../../../../../../config';

import { ListState } from '../list-state';

const UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_START = 'UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_START';
const UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_STOP = 'UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_STOP';

interface UpdateRemoteElementsQuantityTimeoutStartAction extends Action {
    type: typeof UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_START;
    payload: { callback: () => void };
}

interface UpdateRemoteElementsQuantityTimeoutStopAction extends Action {
    type: typeof UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_STOP;
}

export type UpdateRemoteElementsQuantityTimeoutAction =
    | UpdateRemoteElementsQuantityTimeoutStartAction
    | UpdateRemoteElementsQuantityTimeoutStopAction;

const startUpdateRemoteElementsQuantityTimeoutAction = (
    callback: () => void,
): UpdateRemoteElementsQuantityTimeoutStartAction => {
    return {
        type: UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_START,
        payload: {
            callback,
        },
    };
};

const stopUpdateRemoteElementsQuantityTimeoutAction = (): UpdateRemoteElementsQuantityTimeoutStopAction => {
    return {
        type: UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_STOP,
    };
};

function updateRemoteElementsQuantityTimeoutReducer(state: ListState, action: AnyAction): ListState {
    switch (action.type) {
        case UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_START: {
            if (state.updateTimeout) {
                clearTimeout(state.updateTimeout);
            }

            return {
                ...state,
                updateTimeout: setTimeout(action.payload.callback, config.list.updateTimeout),
            };
        }
        case UPDATE_REMOTE_ELEMENTS_QUANTITY_TIMEOUT_STOP: {
            if (state.updateTimeout) {
                clearTimeout(state.updateTimeout);
            }

            return {
                ...state,
                updateTimeout: undefined,
            };
        }
        default:
            return state;
    }
}

export const updateRemoteElementsQuantityTimeoutModule = {
    action: {
        startUpdateRemoteElementsQuantityTimeout: startUpdateRemoteElementsQuantityTimeoutAction,
        stopUpdateRemoteElementsQuantityTimeout: stopUpdateRemoteElementsQuantityTimeoutAction,
    },
    reducer: updateRemoteElementsQuantityTimeoutReducer,
};
