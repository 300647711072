/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { configUI } from 'config';

import { GenericList } from 'modules/shared/components/generic-list';

import { ColumnListContainerProps, ColumnOptions } from './column-list-container-type';

import styles from './column-list-container-component.module.css';

const getColumnOptions = (options: ColumnOptions): number[] => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= configUI.responsive.mobile.maxWidth) {
        return options.mobile;
    }
    if (screenWidth <= configUI.responsive.tablet.maxWidth) {
        return options.tablet;
    }
    return options.computer;
};
const buildColumnOptions = (
    options: number[],
    setOptionIndex: React.Dispatch<React.SetStateAction<number>>,
    currentOptionIndex: number,
): JSX.Element => {
    return (
        <React.Fragment>
            <span className={styles.displayLabel}>Affichage</span>
            {options.map((option, key) => {
                let value = '';
                for (let i = 0; i < option; i += 1) {
                    value += '■';
                }
                return (
                    <React.Fragment key={key}>
                        <button
                            type="button"
                            className={`${styles.displaySelection} ${
                                key === currentOptionIndex ? styles.active : styles.inactive
                            }`}
                            onClick={() => setOptionIndex(key)}
                        >
                            {value}
                        </button>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export const ColumnListContainer = <T extends object | number>({
    items,
    itemRenderer,
    options,
    hideOptions = false,
    headerChildren,
}: ColumnListContainerProps<T>): JSX.Element => {
    const option = getColumnOptions(options);
    const [optionIndex, setOptionIndex] = useState(0);
    const columns = option[optionIndex];
    const hasEnoughItems = items ? items.length > columns : false;
    const renderer: (item: T, key: number) => JSX.Element = (item: T, key: number) => {
        return (
            <div className={styles.itemContainer} style={{ width: `calc(100% / ${columns})` }} key={key}>
                {itemRenderer(item, key)}
            </div>
        );
    };
    return (
        <React.Fragment>
            <div className={styles.header}>
                <div className={styles.headerTopRow}>{headerChildren !== undefined && headerChildren}</div>
                <div className={styles.headerBottomRow}>
                    {!hideOptions && buildColumnOptions(option, setOptionIndex, optionIndex)}
                </div>
            </div>
            <div className={`${styles.listContainer} ${!hasEnoughItems ? styles.centeredList : ''}`}>
                <GenericList items={items} itemRenderer={renderer} />
            </div>
        </React.Fragment>
    );
};
