import React from 'react';
import { connect } from 'react-redux';
import { FetchState } from '../../../redux/utils/type';
import { ErrorMessage } from '../../../components';
import { Error } from '../../../models';

interface StateProps<T> {
    data: T | null;
    isLoading: boolean;
    error: Error | string | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WithDatasFetchingListeningProps<T> = StateProps<T> & any;

export const WithDatasFetchingListening = <T extends object>(stateName: string) => {
    return <P extends object>(Component: React.ComponentType<P>) => {
        const withDatasFetchingListeningWrapper: React.FC<P & WithDatasFetchingListeningProps<T>> = ({
            error,
            ...rest
        }: P & WithDatasFetchingListeningProps<T>) => {
            if (error) {
                const errorMessage: string = typeof error === 'string' ? error : error.message;
                return <ErrorMessage error={errorMessage} />;
            }
            return <Component {...rest} />;
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mapStateToProps = (states: any): StateProps<T> => {
            const subState = states[stateName] as FetchState<T>;
            return {
                data: subState.data,
                isLoading: subState.isLoading,
                error: subState.error,
            };
        };

        return connect<StateProps<T>>(mapStateToProps)(withDatasFetchingListeningWrapper);
    };
};
