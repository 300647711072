import React, { ComponentType } from 'react';
import querystring from 'query-string';

import { useRouter } from '../../hooks';
import { Omit } from '../../types/type-utils';
import { parse } from '../../utils';

export const WithQuerystringReading = <InputParams extends {}>() => {
    return <P extends InputParams>(Component: ComponentType<P>) => {
        type WrapperType = Omit<P, keyof InputParams>;
        const WithQuerystringReadingComponent: React.FC<WrapperType> = (props: WrapperType) => {
            const { location } = useRouter();
            const search = querystring.parse(location.search) as InputParams;

            const innerProps = { ...props, ...parse(search) };

            return <Component {...(innerProps as P)} />;
        };

        return WithQuerystringReadingComponent;
    };
};
