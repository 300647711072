const config = {
    cms: {
        baseUrl: '',
        infraUrl: '',
        auth: {
            tokenUrl: '/identity-server/connect/token',
            issUrl: '/identity-server',
            grantType: 'client_credentials',
            clientScope: 'squidex-api',
            clientId: 'printemps:default',
            clientSecret: 'dx8qm4ATo2vdLy2kBwnHtShXcd5T/xljtCGZD1q4cyQ=',
        },
        rest: {
            url: '/api/apps/printemps',
        },
        graphql: {
            url: '/api/content/printemps/graphql',
        },
    },
    backend: {
        baseUrl: '',
        rest: {
            url: '/api',
            catalogueUrl: '/catalogue',
            listUrl: 'list',
            weddingUrl: 'wedding',
            commonUrl: 'common',
        },
    },
    cdn: {
        baseUrl: '',
        fileMaxSize: 1000,
    },
    baseUrl: '',
    list: {
        updateTimeout: 5000,
        refreshInterval: 30000,
    },
    seo: {
        titleSuffix: 'Printemps Listes',
        title: 'Printemps Listes : Liste de mariage, liste de naissance, liste de cadeaux',
        description:
            'Bénéficiez d’avantages exclusifs en déposant votre Voyage de Noces sur votre Liste de Mariage ou de Cadeaux au Printemps.',
        keywords:
            "listes de mariage,pacs,union,cagnotte mariage,listes de naissance,baptême,listes de voyage,voyage de noces,cadeaux d'anniversaire,crémaillère,cadeau commun,conseils,partenaires mariage,tenues de cérémonie,tenues de mariage,cortège mariage,costume marié,robe de mariée,alliances,printemps joailier,printemps voyages,printempsadeux,Printemps à Deux,boutiqueblanche,boutique blanche,printemps, liste de mariage,liste de naissance, liste de cadeaux, liste de voyage",
        robots: 'ALL',
        copyright: 'Copyright 2000/2019 - Printemps.com S.A. - https://listes.printemps.com/',
        author: 'Printemps.com S.A.',
        classification: 'Global',
        distribution: 'Global',
        rating: 'General',
        resourceType: 'document',
        revisitAfter: '9 days',
        bulletinText: 'Listes de mariage, naissance et cadeaux - Printemps Listes',
        language: 'fr',
        type: 'website',
        image: {
            url: '/PLISTES/img/catalogue/printempslistesnoir-og.png',
        },
    },
    tagManager: {
        gtmId: 'GTM-TTZXBWW',
        datalayer: {
            'id-ua-plistes': 'DEV-UA',
        },
        auth: '',
        preview: '',
    },
    apiKey: {
        googleMap: 'AIzaSyDbQF70Ryg2aHzMUglnvEjcvoT_EunUiLk',
        appInsights: '12a247df-a54a-4609-b3bd-87ca8a3459db',
    },
    sn: {
        pinterest: 'https://www.pinterest.fr/printemps1217/',
        instagram: 'https://www.instagram.com/printempsmariage/?hl=fr',
    },
    voyage: {
        scriptSrc: '//js-eu1.hsforms.net/forms/embed/v2.js',
        region: 'eu1',
        portalId: '26122002',
        form: {
            id: 'df6b5255-1850-4dd6-b7de-a2034872dc0d',
            url: 'https://share-eu1.hsforms.com/1dh7o7jteScW4fF-jz6oTzgfjvv6',
        },
    },
};

if (process.env.REACT_APP_STAGE === 'recette') {
    config.baseUrl = 'https://printemps-dev-refonte-fo.azurewebsites.net';
    config.cms.baseUrl = 'https://printemps-test-refonte-cmsv7.azurewebsites.net';
    config.backend.baseUrl = 'https://printemps-test-refonte-api.azurewebsites.net';
    config.cdn.baseUrl = 'https://printempstestuplaod.blob.core.windows.net/printempsupload/WPCOM.SRC';
} else if (process.env.REACT_APP_STAGE === 'preprod') {
    config.baseUrl = 'https://ppd-listes.printemps.com';
    config.cms.baseUrl = 'https://ppd-cms-listes.printemps.com';
    config.cms.infraUrl = 'https://fpr-plistes-ppd-cms03.azurewebsites.net';
    config.backend.baseUrl = 'https://ppd-api-listes.printemps.com';
    config.cdn.baseUrl = 'https://ppd-asset-listes.printemps.com';
    config.tagManager.gtmId = 'GTM-TNJZWJL';
    config.tagManager.datalayer['id-ua-plistes'] = 'UA-9680728-10';
    config.tagManager.auth = '4aZlQe1x-rhIGaaKWukWXQ';
    config.tagManager.preview = 'env-24';
    config.apiKey.appInsights = 'f4ba1444-8ca0-4770-9432-91f26af664ad';
    config.cms.auth.clientSecret = 'reb3i4pyzc040aobjfdwrllifgovvouavidhrr9wanwx';
    config.voyage.form.id = 'df6b5255-1850-4dd6-b7de-a2034872dc0d';
    config.voyage.form.url = 'https://share-eu1.hsforms.com/1dh7o7jteScW4fF-jz6oTzgfjvv6';
} else if (process.env.REACT_APP_STAGE === 'prod') {
    config.baseUrl = 'https://listes.printemps.com';
    config.cms.baseUrl = 'https://cms-listes.printemps.com';
    config.cms.infraUrl = 'https://fpr-plistes-prd-cms03.azurewebsites.net';
    config.backend.baseUrl = 'https://api-listes.printemps.com';
    config.cdn.baseUrl = 'https://asset-listes.printemps.com';
    config.apiKey.googleMap = 'AIzaSyARbGU-QaSW75b3nKJ6bt2ovcrtPaTVOyg';
    config.tagManager.gtmId = 'GTM-TNJZWJL';
    config.tagManager.datalayer['id-ua-plistes'] = 'UA-9680728-2';
    config.tagManager.auth = 'LACa7Oi727mLAeD5oT8dww';
    config.tagManager.preview = 'env-2';
    config.apiKey.appInsights = '260e579b-c16f-47fa-aa26-53a1e5965003';
    config.cms.auth.clientSecret = 'reb3i4pyzc040aobjfdwrllifgovvouavidhrr9wanwx';
    config.voyage.form.id = 'df6b5255-1850-4dd6-b7de-a2034872dc0d';
    config.voyage.form.url = 'https://share-eu1.hsforms.com/1dh7o7jteScW4fF-jz6oTzgfjvv6';
} else {
    config.baseUrl = 'https://printemps-dev-refonte-fo.azurewebsites.net';
    config.cms.baseUrl = 'https://printemps-test-refonte-cmsv7.azurewebsites.net';
    config.backend.baseUrl = 'https://printemps-test-refonte-api.azurewebsites.net';
    config.cdn.baseUrl = 'https://printempstestuplaod.blob.core.windows.net/printempsupload/WPCOM.SRC';
}
export { config };
