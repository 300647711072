import { AnyAction } from 'redux';
import { Module } from '../../utils/type';

export const UPDATE_DATALAYER = 'UPDATE_DATALAYER';

const updateDatalayer = (data?: {}): AnyAction => {
    return {
        type: UPDATE_DATALAYER,
        payload: {
            datalayer: data,
        },
    };
};

function trackingReducer(state: TrackingState = { datalayer: undefined }, action: AnyAction): TrackingState {
    switch (action.type) {
        case UPDATE_DATALAYER:
            return { ...state, datalayer: action.payload.datalayer };
        default:
            return state;
    }
}

export interface TrackingState {
    datalayer?: {};
}

export interface TrackingActionDescriptor {
    updateDatalayer: (data?: {}) => AnyAction;
}

export const trackingModule: Module<TrackingState, TrackingActionDescriptor> = {
    storeName: 'tracking',
    action: {
        updateDatalayer,
    },
    reducer: trackingReducer,
};
