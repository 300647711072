import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useRouter } from '../../../../hooks';
import { MenuItem, SectionMenuItem, BaseMenuItem } from '../../menu-type';

import { MobileMenuProps } from './mobile-menu-type';
import { MobilePanel } from './components';

const cleanAndGetCTA = (items?: MenuItem[]): MenuItem[] => {
    let result: MenuItem[] = [];

    if (!items || items.length === 0) {
        return result;
    }

    for (let i = 0; i < items.length; i += 1) {
        if (items[i].ctaStyle) {
            result = result.concat(items.splice(i, 1));
            i -= 1;
        } else {
            const clonedItem = { ...items[i] };
            if (clonedItem.children) {
                result = result.concat(cleanAndGetCTA([...clonedItem.children]));
            }
        }
    }

    return result;
};

const getActiveElement = (element: MenuItem, activePath: string): MenuItem | undefined => {
    if (!element || !activePath || !element.children || element.children.length === 0) {
        return undefined;
    }

    if (activePath.startsWith(element.levelPath)) {
        for (let i = 0; i < element.children.length; i += 1) {
            const activeChildElement = getActiveElement(element.children[i], activePath);
            if (activeChildElement) {
                return activeChildElement;
            }
        }

        return element;
    }

    return undefined;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({ elements }: MobileMenuProps) => {
    const [currentElement, setCurrentElement] = useState<SectionMenuItem | MenuItem | undefined>(undefined);
    const { history } = useRouter();

    const handleUpdateActiveElement = useCallback((activeElement: SectionMenuItem | MenuItem) => {
        setCurrentElement(activeElement);
    }, []);

    const mobileRootLevel = useMemo(() => {
        // On rajoute un noeud racine pour la navigation mobile
        const rootLevel: BaseMenuItem<SectionMenuItem> = {
            name: '',
            levelPath: '/',
            ressourcePath: '/',
        };

        const clonedElements = [];
        for (let i = 0; i < elements.length; i += 1) {
            const currentClonedElement = { ...elements[i] };
            currentClonedElement.parent = rootLevel;
            if (currentClonedElement.children) {
                const clonedChildren = [...currentClonedElement.children];
                currentClonedElement.children = clonedChildren.concat(cleanAndGetCTA(clonedChildren));
                currentClonedElement.children.forEach(menuItem => {
                    const currentMenuItem = menuItem;
                    currentMenuItem.parent = currentClonedElement;
                });
            }

            clonedElements.push(currentClonedElement);
        }

        rootLevel.children = clonedElements;

        return rootLevel;
    }, [elements]);

    useEffect(() => {
        setCurrentElement(getActiveElement(mobileRootLevel, history.location.pathname));
    }, [mobileRootLevel, history]);

    if (!currentElement) {
        return <React.Fragment></React.Fragment>;
    }

    return <MobilePanel activeElement={currentElement} updateActiveElement={handleUpdateActiveElement} />;
};
