import { config } from '../../../../../config';
import { securedRestClient } from '.';
import {
    LightList,
    LightListElement,
    FastUpsertListElement,
    FastUpsertListElements,
    DeleteListElementByVariantId,
} from '../../../models';

export const currentListService = {
    getSummary: async () => {
        const response = await securedRestClient.get<LightList>(`${config.backend.rest.listUrl}/list/current/summary`);
        return response.data;
    },
    fastUpsertElement: async (data: FastUpsertListElement) => {
        const response = await securedRestClient.post<LightListElement>(
            `${config.backend.rest.listUrl}/list/current/cataloguelement`,
            data,
        );
        return response.data;
    },
    fastUpsertElements: async (data: FastUpsertListElements) => {
        const response = await securedRestClient.post<LightListElement[]>(
            `${config.backend.rest.listUrl}/list/current/cataloguelements`,
            data,
        );
        return response.data;
    },
    deleteElementByVariantId: async ({ variantId }: DeleteListElementByVariantId) => {
        await securedRestClient.delete(`${config.backend.rest.listUrl}/list/current/element/${variantId}`);
    },
};
