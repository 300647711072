import axios from 'axios';
import { config } from '../../../../../../../config';
import { concatUrl, dateIsExpired } from '../../../../../utils';
import { AuthToken } from '../../../../../models';

const apiClient = axios.create({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

export const cmsAuthenticationService = {
    authenticate: async (): Promise<AuthToken> => {
        const url = concatUrl([config.cms.baseUrl, config.cms.auth.tokenUrl]);
        const { grantType, clientId, clientScope, clientSecret } = config.cms.auth;

        const formData = new URLSearchParams();
        formData.append('grant_type', grantType);
        formData.append('client_id', clientId);
        formData.append('client_secret', clientSecret);
        formData.append('scope', clientScope);

        const response = await apiClient.post(url, formData);

        const expiration = new Date();
        expiration.setSeconds(expiration.getSeconds() + response.data.expires_in);

        return {
            value: response.data.access_token,
            expiration,
            isValid: () => expiration && dateIsExpired(expiration),
        };
    },
};
