import queryString from 'query-string';
import { restClient } from '.';
import { config } from '../../../../../config';
import { EventType, GetEventType } from '../../../models';

export const eventTypeService = {
    getByUniverse: async ({ universeId, hasOrganiser }: GetEventType) => {
        const queryParams: any = {
            hasOrganiser: !!(hasOrganiser && hasOrganiser === 1),
        };
        const response = await restClient.get<EventType[]>(
            `${config.backend.rest.listUrl}/universe/${universeId}/eventtypes?${queryString.stringify(queryParams)}`,
        );
        return response.data;
    },
};
