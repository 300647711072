import React from 'react';
import styles from './page-title-component.module.css';
import { PageTitleProps } from './page-title-type';

export const PageTitle: React.FC<PageTitleProps> = ({
    title,
    subtitle,
    bordered,
    centered,
    uppercase,
}: PageTitleProps) => {
    const wrapperClass = `${styles.pageTitle} ${centered ? styles.center : ''} ${uppercase ? styles.upper : ''}`;
    const mainClass = `${styles.title} ${!subtitle && bordered ? styles.border : ''}`;
    const subClass = `${styles.subtitle} ${subtitle && bordered ? styles.border : ''}`;

    return (
        <React.Fragment>
            <div className={wrapperClass}>
                {title && <div className={mainClass}>{title}</div>}
                {subtitle && <div className={subClass}>{subtitle}</div>}
            </div>
        </React.Fragment>
    );
};
