const parseHtmlEntities = (str: string): string => {
    return str.replace(/&#([0-9]{1,3});/gi, (match, numStr) => {
        const num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
};

const parseNewlines = (str: string): string => {
    return str.replace(/<br\s*[\\/]?>/gi, '\n');
};

const parseBold = (str: string): string => {
    return str.replace(/<strong\s*[\\/]?>/gi, '').replace(/<\/strong\s*[\\/]?>/gi, '');
};

export const cleanString = (str: string): string => {
    return parseBold(parseNewlines(parseHtmlEntities(str)));
};

export const titleCase = (str: string): string => {
    const result = str
        .toLowerCase()
        .split('')
        .map((char, index) => {
            return index === 0 ? char.toUpperCase() : char;
        });

    return result.join('');
};

export const removeAccent = (str: string): string => {
    return str
        .replace(/[ÀÁÂÃÄÅ]/g, 'A')
        .replace(/[àáâãäå]/g, 'a')
        .replace(/[ÒÓÔÕÕÖØ]/g, 'O')
        .replace(/[òóôõöø]/g, 'o')
        .replace(/[ÈÉÊË]/g, 'E')
        .replace(/[èéêëð]/g, 'e')
        .replace(/[Ç]/g, 'C')
        .replace(/[ç]/g, 'c')
        .replace(/[Ð]/g, 'D')
        .replace(/[ÌÍÎÏ]/g, 'I')
        .replace(/[ìíîï]/g, 'i')
        .replace(/[ÙÚÛÜ]/g, 'U')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[Ñ]/g, 'N')
        .replace(/[ñ]/g, 'n')
        .replace(/[Š]/g, 'S')
        .replace(/[š]/g, 's')
        .replace(/[Ÿ]/g, 'Y')
        .replace(/[ÿý]/g, 'y')
        .replace(/[Ž]/g, 'Z')
        .replace(/[ž]/g, 'z');
};

export const searchString = (str: string, criteria: string): boolean => {
    return removeAccent(str.toLowerCase()).search(removeAccent(criteria.toLowerCase())) !== -1;
};

export const stringFormat = (str: string, ...args: string[]): string =>
    str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
