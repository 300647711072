import React from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'vendors/semantic-ui';

import { RootState } from '../../redux/store';

export const LogoutProgressMessage: React.FC = () => {
    const logoutRequested = useSelector<RootState, boolean>(state => state.authentication.logoutRequested);
    if (logoutRequested) {
        return (
            <Dimmer active page>
                <Loader size="massive">Déconnexion...</Loader>
            </Dimmer>
        );
    }

    return <React.Fragment />;
};
