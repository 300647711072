import { config } from '../../../../../config';
import { securedRestClient } from '.';
import { LightOffer } from '../../../models';

export const currentFolderService = {
    getOffer: async () => {
        const response = await securedRestClient.get<LightOffer>(`${config.backend.rest.listUrl}/folder/current/offer`);
        return response.data;
    },
};
