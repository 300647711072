import React from 'react';
import { DocumentNode } from 'graphql';

import { Omit } from 'modules/shared/types/type-utils';

import { CMSQuery } from './components';

export interface WithFetchDatasByIdFromCMSInputProps {
    id: string;
    onLoadingStateChange?: (state: boolean) => void;
}

export function WithFetchDatasByIdFromCMS<OutputProps>(
    query: DocumentNode,
    Loader?: React.ComponentType,
    queryName?: string,
    additionalFilterPaths?: { name: string; filter: string }[],
    includeIdInFilters?: boolean,
) {
    return <P extends OutputProps>(Component: React.ComponentType<P>) => {
        type WrapperType = Omit<P, keyof OutputProps> & WithFetchDatasByIdFromCMSInputProps;
        const WithFetchDatasFromCMSWrapper: React.FC<WrapperType> = ({
            id,
            onLoadingStateChange,
            ...rest
        }: WrapperType) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const variable: any = {};
            if (additionalFilterPaths && additionalFilterPaths.length > 0) {
                additionalFilterPaths.forEach(f => {
                    if (includeIdInFilters) {
                        variable[f.name] = f.filter.replace('$id', id);
                    } else {
                        variable[f.name] = f.filter;
                    }
                });
            }

            if (!includeIdInFilters) {
                variable.id = id;
            }

            return (
                <CMSQuery
                    Loader={Loader}
                    query={query}
                    variables={variable}
                    onLoadingStateChange={onLoadingStateChange}
                    readPayload={payload => {
                        let innerData: P | null;
                        if (queryName) {
                            if (!payload || !payload[queryName]) {
                                innerData = null;
                            } else if (includeIdInFilters) {
                                if (payload[queryName].length === 0) {
                                    innerData = null;
                                } else {
                                    innerData = payload[queryName][0].data;
                                }
                            } else {
                                innerData = payload[queryName].data;
                            }
                        } else {
                            innerData = payload;
                        }

                        return innerData;
                    }}
                    render={data => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        return <Component {...(rest as any)} {...data} />;
                    }}
                />
            );
        };

        return WithFetchDatasFromCMSWrapper;
    };
}
