import React from 'react';
import { Route as BaseRoute, RouteComponentProps } from 'react-router-dom';
import loadable from '@loadable/component';

import { IdRouteParams } from 'modules/shared/types';
import { NotifiedRoute as Route, Switch } from 'modules/shared/components';

import { Props } from './router-type';
import { ContentSelector } from './components';

import enhancer from './router-enhancer';

const router: React.FC<Props> = ({ data }: Props) => {
    if (!data || !data.rootContentId || !data.rootContentTemplate) {
        return <React.Fragment />;
    }
    const { rootBannerId, rootContentTemplate } = data;
    const rootNavigationElement = {
        path: '/',
        bannerId: rootBannerId,
    };

    return (
        <Switch>
            <Route
                exact
                path="/"
                navigationTree={rootNavigationElement}
                render={() => <ContentSelector template={rootContentTemplate} />}
            />
            <Route
                path="/preview/:template"
                render={(subProps: RouteComponentProps<IdRouteParams>) => {
                    return <ContentSelector template={subProps.match.params.template} />;
                }}
            />
            <BaseRoute
                render={() => {
                    const RootModule = loadable(() => import(/* webpackChunkName: "root" */ '../../../root/root'));
                    return <RootModule rootNavigationElement={rootNavigationElement} nodes={data.sectionNodes} />;
                }}
            />
        </Switch>
    );
};

export const Router = enhancer(router);
