import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'connected-react-router';

import { history } from 'global/history';
import { store } from 'modules/shared/redux/store';

import { apolloClient as CMSApolloClient } from 'modules/shared/services/API/cms';

import {
    ErrorBoundary,
    GlobalLoader,
    ScrollRestoration,
    SEO,
    LogoutProgressMessage,
    ConsentBanner,
    TracerAdminPanel,
} from 'modules/shared/components';

import { BootStrap } from 'modules/bootstrap';

const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <SEO />
            <Provider store={store}>
                <ConsentBanner />
                <ApolloProvider client={CMSApolloClient}>
                    <ConnectedRouter history={history}>
                        <TracerAdminPanel />
                        <ScrollRestoration>
                            <GlobalLoader />
                            <BootStrap />
                            <LogoutProgressMessage />
                        </ScrollRestoration>
                    </ConnectedRouter>
                </ApolloProvider>
            </Provider>
        </ErrorBoundary>
    );
};

export default App;
