import { Action, AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { dateIsExpiredBuilder } from 'modules/shared/utils';

import { globalLoaderModule } from '../../global-loader';
import { contextService } from '../../../../services';

import { AuthenticationResult } from '../../../../models/customer';
import { AuthenticationState } from '../authentication-state';

import { AuthToken, LightFolder, RegisterFolder } from '../../../../models';

export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const SUCCESS_REGISTER = 'SUCCESS_REGISTER';
export const ERROR_REGISTER = 'ERROR_REGISTER';

interface RequestRegisterAction extends Action {
    type: typeof REQUEST_REGISTER;
}

interface SuccessRegisterAction extends Action {
    type: typeof SUCCESS_REGISTER;
    payload: { loggedInFolder: LightFolder; token: AuthToken };
}

interface ErrorRegisterAction extends Action {
    type: typeof ERROR_REGISTER;
    payload: { error: Error };
}

export type RegisterAction = RequestRegisterAction | SuccessRegisterAction | ErrorRegisterAction;

// Action creators
const requestAction = (): RequestRegisterAction => {
    return { type: REQUEST_REGISTER };
};

const successAction = (authenticationResult: AuthenticationResult): SuccessRegisterAction => {
    return {
        type: SUCCESS_REGISTER,
        payload: {
            loggedInFolder: authenticationResult.folder,
            token: {
                value: authenticationResult.accessToken,
                expiration: authenticationResult.expireAt,
                isValid: dateIsExpiredBuilder(authenticationResult.expireAt),
            },
        },
    };
};

const failureAction = (error: Error): ErrorRegisterAction => {
    return {
        type: ERROR_REGISTER,
        payload: {
            error,
        },
    };
};

const registerAction = (registerFolderInfos: RegisterFolder): ThunkAction<Promise<void>, {}, {}, RegisterAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, RegisterAction>): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            globalLoaderModule.action.showLoading();

            dispatch(requestAction());

            try {
                const data = await contextService.registerInternalUser(registerFolderInfos);

                dispatch(successAction(data));
                resolve();
            } catch (error) {
                dispatch(failureAction(error));
                reject(error);
            } finally {
                globalLoaderModule.action.hideLoading();
            }
        });
    };
};

function registerReducer(state: AuthenticationState, action: AnyAction): AuthenticationState {
    switch (action.type) {
        case REQUEST_REGISTER:
            return { ...state, isLoading: true };
        case SUCCESS_REGISTER:
            return {
                ...state,
                isLoading: false,
                loggedInFolder: action.payload.loggedInFolder,
                token: action.payload.token,
            };
        case ERROR_REGISTER:
            return { ...state, isLoading: false, error: action.payload.error };
        default:
            return state;
    }
}

export const registerModule = {
    reducer: registerReducer,
    action: {
        register: registerAction,
    },
};
