export const concatUrl = (urlFragments: string[]): string => {
    const resultArray = [];
    if (urlFragments.length === 0) {
        return '';
    }

    const workingUrlFragments = [...urlFragments];

    // If the first part is a plain protocol, we combine it with the next part.
    if (workingUrlFragments[0].match(/^[^/:]+:\/*$/) && workingUrlFragments.length > 1) {
        const first = workingUrlFragments.shift();
        workingUrlFragments[0] = first + workingUrlFragments[0];
    }

    // There must be two or three slashes in the file protocol, two slashes in anything else.
    if (workingUrlFragments[0].match(/^file:\/\/\//)) {
        workingUrlFragments[0] = workingUrlFragments[0].replace(/^([^/:]+):\/*/, '$1:///');
    } else {
        workingUrlFragments[0] = workingUrlFragments[0].replace(/^([^/:]+):\/*/, '$1://');
    }

    for (let i = 0; i < workingUrlFragments.length; i += 1) {
        let component = workingUrlFragments[i];

        if (component !== '') {
            if (i > 0) {
                // Removing the starting slashes for each component but the first.
                component = component.replace(/^[/]+/, '');
            }
            if (i < workingUrlFragments.length - 1) {
                // Removing the ending slashes for each component but the last.
                component = component.replace(/[/]+$/, '');
            } else {
                // For the last component we will combine multiple slashes to a single one.
                component = component.replace(/[/]+$/, '/');
            }

            resultArray.push(component);
        }
    }

    let str = resultArray.join('/');
    // Each input component is now separated by a single slash except the possible first plain protocol part.

    // remove trailing slash before parameters or hash
    str = str.replace(/\/(\?|&|#[^!])/g, '$1');

    // replace ? in parameters with &
    const parts = str.split('?');
    str = parts.shift() + (parts.length > 0 ? '?' : '') + parts.join('&');

    return str;
};
