import { compose } from 'redux';
import { WithFetchDatasByParamsFromCMS, WithFetchDatasByParamsFromCMSInputProps } from 'modules/shared-cms-advanced';

import { BannerGroupLoader } from './banner-group-loader';
import { BannerGroupInjectedProps, BannerGroupOwnProps } from './banner-group-types';
import { QUERY } from './banner-group-query';

const enhancer = compose<React.ComponentType<BannerGroupOwnProps & WithFetchDatasByParamsFromCMSInputProps>>(
    WithFetchDatasByParamsFromCMS<BannerGroupInjectedProps>(
        BannerGroupLoader,
        QUERY,
        undefined,
        [
            {
                filter: `id in [$ids]`,
            },
        ],
        undefined,
        true,
    ),
);

export default enhancer;
