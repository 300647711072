import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { navigationModule } from '../../../redux';

import { NavigationElement } from '../../../models';

export interface WithNotifyRessourceLoadedInputProps {
    parentNavigationTree: NavigationElement;
    path: string;
}

interface DispatchProps {
    updateNavigation: (data?: NavigationElement) => void;
}

type WithNotifyRessourceLoadedProps = WithNotifyRessourceLoadedInputProps & DispatchProps;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function WithNotifyRessourceLoaded<P extends {}>(
    getRessourceName: (props?: P) => string | undefined,
    getAdditionalParentTree?: (props?: P, parentNavigationTree?: NavigationElement) => NavigationElement | undefined,
    getAdditionalType?: (props?: P) => { typeName: string; value: string } | undefined,
) {
    return (Component: React.ComponentType<P>) => {
        class WithNotifyRessourceLoadedWrapper extends React.Component<WithNotifyRessourceLoadedProps> {
            componentDidMount(): void {
                const { updateNavigation, parentNavigationTree, path, ...rest } = this.props;

                const name = getRessourceName(rest as P);

                const additionalParentTree = getAdditionalParentTree
                    ? getAdditionalParentTree(rest as P, parentNavigationTree)
                    : null;

                const additionalType = getAdditionalType ? getAdditionalType(rest as P) : undefined;

                if (updateNavigation) {
                    updateNavigation(
                        name
                            ? {
                                  name,
                                  path,
                                  parent: additionalParentTree || parentNavigationTree,
                                  additionalType,
                              }
                            : undefined,
                    );
                }
            }

            render(): React.ReactNode {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { updateNavigation, parentNavigationTree, path, ...rest } = this.props;
                return <Component {...(rest as P)} />;
            }
        }

        const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
            updateNavigation: (data?: NavigationElement) => dispatch(navigationModule.action.updateNavigation(data)),
        });

        return connect(null, mapDispatchToProps)(WithNotifyRessourceLoadedWrapper);
    };
}
