import { Preference, OptinPreferenceValues, OptinTypeValues, OptinType, Subscriptions } from '../models';

export const convertToOptins = (
    subs: Subscriptions,
): {
    id: OptinType;
    checked: boolean;
    preferences: Preference[];
}[] => {
    const preferences = [] as Preference[];
    let checked = false;
    Object.entries(subs).forEach(e => {
        const key = e[0];
        const value = e[1];
        if (value) {
            checked = true;
        }
        const id = OptinPreferenceValues.getIntByString(key);
        if (id !== undefined) {
            preferences.push({
                id,
                checked: value,
            });
        }
    });

    const optIn = {
        id: OptinTypeValues.PRINTEMPS_LISTES,
        checked,
        preferences,
    };
    return [optIn];
};
