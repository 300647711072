import React from 'react';
import { Link } from 'react-router-dom';

import { getCatalogueCDNUrl } from '../../utils';

import { Props } from './logo-type';

import styles from './logo-component.module.css';

export const Logo: React.FC<Props> = ({ maxWidth, maxHeight, noMargin, alignLeft, onLoaded }: Props) => {
    const inlineStyle: React.CSSProperties = {
        maxWidth: maxWidth || 245,
    };

    if (maxHeight) {
        inlineStyle.maxHeight = `${maxHeight}px`;
    }

    if (noMargin) {
        inlineStyle.marginTop = 0;
        inlineStyle.marginBottom = 0;
    }

    if (alignLeft) {
        inlineStyle.marginLeft = 0;
    }

    return (
        <div>
            <div className={styles.headerLogo} style={inlineStyle}>
                <Link className={styles.logoLink} to="/">
                    <picture>
                        <img src={getCatalogueCDNUrl('printempslistesnoir.png')} alt="Site Logo" onLoad={onLoaded} />
                    </picture>
                    <div className={styles.subtext}>Mariage et Cadeaux en toutes occasions</div>
                </Link>
            </div>
        </div>
    );
};
