export const isPrintMode = window && window.matchMedia && window.matchMedia('print').matches;

export const printReadyEventName = 'readyToPrint';

export const printPage = (targetUrl: string, readyEventName: string | undefined = undefined): void => {
    const w = window.open(targetUrl);
    if (w) {
        if (readyEventName) {
            // -- on doit attendre que la page soit chargée avant de lancer le .print() car sinon c'est une page vide qui est imprimée
            w.addEventListener(readyEventName, () => {
                w.print();
                w.close();
            });
        } else {
            w.print();
            w.close();
        }
    }
};

export const printCurrentPage = (readyEventName: string | undefined = undefined): void => {
    printPage('#', readyEventName);
};

export const printPageWhenReady = (targetUrl: string): void => {
    printPage(targetUrl, printReadyEventName);
};

export const printCurrentPageWhenReady = (): void => {
    printCurrentPage(printReadyEventName);
};
