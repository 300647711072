import { config } from '../../../../../config';
import { restClient } from '.';
import { PublicList, GetList } from '../../../models';

export const listService = {
    get: async ({ id }: GetList) => {
        const response = await restClient.get<PublicList>(`${config.backend.rest.listUrl}/list/${id}`);
        return response.data;
    },
};
