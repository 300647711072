import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AuthenticationState, authenticationModule } from '../../redux';

import { ContextualMenuStateProps, ContextualMenuDispatchProps } from './contextual-menu-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (states: any): ContextualMenuStateProps => {
    const authenticationSubState = states[authenticationModule.storeName] as AuthenticationState;

    return {
        isAuthenticated:
            authenticationSubState && authenticationSubState.token ? authenticationSubState.token.isValid() : false,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ContextualMenuDispatchProps => ({
    logout: () => dispatch(authenticationModule.action.requestLogout()),
});

const reduxConnect = connect<ContextualMenuStateProps, ContextualMenuDispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
);

const enhancer = reduxConnect;

export default enhancer;
