import React from 'react';
import { ClassicButtonProps } from './classic-button-type';
import styles from './classic-button-component.module.css';

export const ClassicButton: React.FC<ClassicButtonProps> = ({
    disabled,
    text,
    secondary,
    grey,
    onClick,
    type = 'button',
    themed = false,
    normal = false,
}: ClassicButtonProps) => {
    let additionalClass = '';
    if (secondary) {
        additionalClass = styles.secondary;
    }

    if (grey) {
        additionalClass = styles.grey;
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            className={`${styles.classicBtn} ${additionalClass}  ${normal ? styles.normalText : ''} ${
                themed ? styles.themed : ''
            } ${disabled ? styles.disabled : ''}`}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {text}
        </button>
    );
};
