import { Dispatch, AnyAction, MiddlewareAPI } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import { clearCookies } from 'modules/shared/utils';
import { cookieConsentMap } from 'modules/shared/services';
import { RootState } from '../store';
import { tracerModule } from '../modules';
import { REJECT_ALL_CONSENTS, ACCEPT_ALL_CONSENTS, UPDATE_ALL_CONSENTS } from '../modules/tracer/sub-modules';

export const tracerMiddleware = ({ dispatch, getState }: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch) => (
    action: AnyAction,
) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            {
                const state = getState();
                if (state && state.tracer && state.tracer.adminPanelEnabled) {
                    dispatch(tracerModule.action.adminPanel.hide());
                }
            }

            break;
        case UPDATE_ALL_CONSENTS:
            {
                const state = getState();
                // TODO : a améliorer
                if (state.tracer && state.tracer.consents && state.tracer.consents.some(c => !c.value)) {
                    clearCookies(Object.entries(cookieConsentMap).map(e => e[1]));
                }
                window.location.reload();
            }

            break;
        case ACCEPT_ALL_CONSENTS:
            window.location.reload();
            break;
        case REJECT_ALL_CONSENTS:
            clearCookies(Object.entries(cookieConsentMap).map(e => e[1]));
            window.location.reload();
            break;
        default:
            break;
    }

    return next(action);
};
