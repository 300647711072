import * as React from 'react';
import { ContextualMenuProps } from './contextual-menu-type';
import { ListButton } from './list-button';
import { UserButton } from './user-button';

import styles from './contextual-menu-component.module.css';

import enhancer from './contextual-menu-enhancer';

const InnerContextualMenu: React.FC<ContextualMenuProps> = ({ isAuthenticated, logout }: ContextualMenuProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.spacer} />
            <UserButton isAuthenticated={isAuthenticated} logout={logout} />
            {/* {isAuthenticated && <div className={styles.spacer} />} */}
            {isAuthenticated && <ListButton />}
        </div>
    );
};

export const ContextualMenu = enhancer(InnerContextualMenu);
