import { config } from '../../../../../config';
import { restClient } from '.';
import { IncentivePartner } from '../../../models';

export const incentivePartnerService = {
    getByAdvantageCode: async (code: string, eventId: string, storeId: string) => {
        const response = await restClient.get<IncentivePartner>(
            `${config.backend.rest.listUrl}/IncentivePartner/${code}&${eventId}&${storeId}`,
        );
        return response.data;
    },
};
