import React from 'react';
import { Container } from 'vendors/semantic-ui';

import { ListContainerProps } from './list-container-type';

import styles from './list-container-component.module.css';

export const ListContainer: React.FC<ListContainerProps> = React.memo(
    ({ list, title, noResultsTxt, children, headerChildren, fullWidth }: ListContainerProps) => {
        return (
            <Container>
                {list && list.length > 0 ? (
                    <React.Fragment>
                        <div className={styles.header}>
                            {headerChildren && <div>{headerChildren}</div>}
                            <div className={`${styles.results} ${fullWidth ? styles.fullWidth : ''}`}>
                                {list.length} {list.length === 1 ? title : `${title}S`}
                            </div>
                        </div>
                        {children}
                    </React.Fragment>
                ) : (
                    <div className={styles.noResults}>{noResultsTxt}</div>
                )}
            </Container>
        );
    },
);
