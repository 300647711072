import React from 'react';
import { useSelector } from 'react-redux';
import { StaticContext } from 'react-router';
import { Container, Responsive, Icon, Sidebar } from 'vendors/semantic-ui';

import { configUI } from 'config';
import { RootState } from 'modules/shared/redux/store';
import { useRouter } from 'modules/shared/hooks';

import { Props } from './sidebar-layout-type';

import styles from './sidebar-layout.module.css';

export const SidebarLayout: React.FC<Props> = ({ mobileHeaderComponent, sideComponent, contentComponent }: Props) => {
    const { history, location } = useRouter<{}, StaticContext, { sidebarVisibility?: boolean }>();
    const toggleVisibility = (visibility: boolean): void => {
        history.replace({ ...location, state: { ...location.state, sidebarVisibility: visibility } });
    };

    const headerHeight = useSelector<RootState, number>(state => {
        return state && state.layout ? state.layout.headerHeight : 0;
    });

    const visibility: boolean = location && location.state && !!location.state.sidebarVisibility;

    return (
        <React.Fragment>
            <Responsive as={React.Fragment} minWidth={configUI.responsive.tablet.minWidth}>
                <Container>
                    <div className={styles.container}>
                        <div
                            className={styles.filterContainer}
                            style={{ top: headerHeight, height: `calc(100vh - ${headerHeight}px)` }}
                        >
                            {sideComponent}
                        </div>
                        <div className={styles.productsContainer}>{contentComponent}</div>
                    </div>
                </Container>
            </Responsive>
            <Responsive as={React.Fragment} maxWidth={configUI.responsive.mobile.maxWidth}>
                <div className={styles.mobileContainer}>
                    <Sidebar
                        className={styles.filterSidebar}
                        animation="overlay"
                        direction="left"
                        visible={visibility}
                        style={{
                            top: `${headerHeight}px`,
                        }}
                    >
                        <div
                            className={styles.filterPanel}
                            style={{
                                height: `calc(100vh - (${headerHeight}px + 5rem))`,
                            }}
                        >
                            <Icon
                                className={styles.closeFilterPanelBtn}
                                name="close"
                                onClick={() => toggleVisibility(!visibility)}
                            />
                            {sideComponent}
                        </div>
                    </Sidebar>
                    {mobileHeaderComponent}
                    {contentComponent}
                </div>
            </Responsive>
        </React.Fragment>
    );
};
