import * as React from 'react';
import { ImageContainerProps } from './image-container-type';
import { LoadableImage } from '../loadable-image';

import styles from './image-container-component.module.css';

export const ImageContainer: React.FC<ImageContainerProps & { children?: React.ReactNode }> = ({
    style,
    className,
    placeholderClassName,
    children,
    color,
    small,
    medium,
    large,
    onloaded,
}: ImageContainerProps & { children?: React.ReactNode }) => {
    return (
        <>
            {large ? (
                <picture style={style}>
                    {small && (
                        <source
                            media="(max-width: 767px)"
                            srcSet={small.url.split('?')[0] + (small.maxWidth ? `?width=${small.maxWidth}` : '')}
                            style={style}
                        />
                    )}
                    {medium && (
                        <source
                            media="(max-width: 1024px)"
                            srcSet={medium.url.split('?')[0] + (medium.maxWidth ? `?width=${medium.maxWidth}` : '')}
                            style={style}
                        />
                    )}
                    <LoadableImage
                        className={styles.blockImg}
                        placeholderClassName={placeholderClassName}
                        src={large.url.split('?')[0] + (large.maxWidth ? `?width=${large.maxWidth}` : '')}
                        alt=""
                        style={style}
                        onLoad={onloaded}
                    />
                </picture>
            ) : (
                <div className={styles.backgroundColor} style={{ backgroundColor: color }} />
            )}
            {children && (
                <div style={style} className={`${className} ${styles['contenu-container']}`}>
                    {children}
                </div>
            )}
        </>
    );
};
