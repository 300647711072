/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Icon } from 'vendors/semantic-ui';

import { buildCMSStyles, extractCMSOrDefault, readCMSValue } from 'modules/shared-cms/utils';
import { BackgroundColorProps, CtaStyle, TextColorProps } from 'modules/shared-cms/cms-base-type';
import { LinkButton, HtmlContainer, CtaButton, ClickableContainer } from 'modules/shared-ui/components';

import { BannerGroupProps } from './banner-group-types';
import enhancer from './banner-group-enhancer';
import styles from './banner-group-component.module.css';

interface BannerItem {
    id: string;
    text: string;
    linkText: string;
    linkUrl: string;
    linkStyle: CtaStyle;
    backgroundColor: string;
    textColor: string;
    startDate?: Date;
    endDate?: Date;
}

export const PlainBannerGroup: React.FC<BannerGroupProps> = ({ bannerGroups, bannerOrderList, onLoaded, onClose }) => {
    const groups = (bannerGroups || []).sort((a, b) => bannerOrderList.indexOf(a.id) - bannerOrderList.indexOf(b.id));
    const group = groups.length > 0 ? groups[0] : undefined;
    const groupId = group ? group.id : '';

    const banners: BannerItem[] = group
        ? extractCMSOrDefault(group.data.banners, [])
              .map(b => {
                  const { id, data } = b;
                  return {
                      id,
                      text: extractCMSOrDefault(data.text, ''),
                      linkText: extractCMSOrDefault(data.linkText, ''),
                      linkUrl: extractCMSOrDefault(data.linkUrl, ''),
                      linkStyle: extractCMSOrDefault(data.linkStyle, 'normal'),
                      backgroundColor: extractCMSOrDefault(data.backgroundColor, ''),
                      textColor: extractCMSOrDefault(data.textColor, ''),
                      startDate: readCMSValue(data.startDate),
                      endDate: readCMSValue(data.endDate),
                  };
              })
              .filter(item => {
                  const { startDate, endDate } = item;
                  const startDateObj = startDate == null ? undefined : new Date(startDate).getTime();
                  const endDateObj = endDate == null ? undefined : new Date(endDate).getTime();
                  const now = Date.now();
                  return (startDateObj == null || startDateObj < now) && (endDateObj == null || endDateObj > now);
              })
        : [];

    const [currentIndex, setCurrentIndex] = React.useState(0);

    React.useEffect(() => {
        // Callback qui va dispatch et mettre a jour la taille du header dynamiquement
        // On execute qu'une seule fois afin de ne pas trigger un render du <main> a chaque fois
        // Les bannieres font la meme taille donc on execute qu'une fois
        if (onLoaded) {
            onLoaded();
        }
    }, []);

    React.useEffect(() => {
        if (banners.length > 1) {
            setTimeout(() => {
                setCurrentIndex((currentIndex + 1) % banners.length);
            }, 3000);
        }
    }, [currentIndex]);

    return (
        <>
            {banners.length > 0 &&
                banners.map((item, index) => {
                    const style = buildCMSStyles<BackgroundColorProps & TextColorProps>({
                        color: item.backgroundColor || 'transparent',
                        textColor: item.textColor || 'black',
                    });

                    const darkBackground = item.backgroundColor === '#000000';

                    return (
                        <ClickableContainer key={`banner-item-${index}`} link={item.linkUrl}>
                            <div
                                className={`${styles.card} ${styles.text} ${
                                    currentIndex !== index ? styles.hidden : styles.visible
                                }`}
                                style={style}
                            >
                                <div className={styles.cardContent}>
                                    <HtmlContainer htmlContent={item.text} />
                                    {item.linkUrl && item.linkText && (
                                        <div className={styles.ctaContainer}>
                                            <CtaButton
                                                ctaLink={item.linkUrl}
                                                ctaText={item.linkText}
                                                ctaStyle={item.linkStyle}
                                            />
                                        </div>
                                    )}
                                </div>
                                <LinkButton
                                    className={styles.closeButton}
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if (onClose) {
                                            onClose(groupId);
                                        }
                                    }}
                                >
                                    <Icon link name="close" color="black" inverted={darkBackground} />
                                </LinkButton>
                            </div>
                        </ClickableContainer>
                    );
                })}
        </>
    );
};

export const BannerGroup = enhancer(PlainBannerGroup);
