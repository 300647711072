import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../../../../config';

import { SEOProps } from './seo-type';
import { concatUrl } from '../../utils';

export const SEO: React.FC<SEOProps> = ({
    pageTitle,
    pageTitleSuffix,
    disablePageTitleSuffix,
    description,
    keywords,
    img,
}: SEOProps) => {
    let fullPageTitle = pageTitle;
    if (fullPageTitle && !disablePageTitleSuffix) {
        fullPageTitle = `${fullPageTitle} | ${pageTitleSuffix || config.seo.titleSuffix}`;
    }

    return (
        <Helmet>
            <title>{fullPageTitle || config.seo.title}</title>
            <meta name="description" content={description || config.seo.description} />
            <meta
                name="keywords"
                content={keywords && keywords.length > 0 ? keywords.join(',') : config.seo.keywords}
            />
            <meta name="robots" content={config.seo.robots} />
            <meta name="copyright" content={config.seo.copyright} />
            <meta name="author" content={config.seo.author} />
            <meta name="classification" content={config.seo.classification} />
            <meta name="distribution" content={config.seo.distribution} />
            <meta name="rating" content={config.seo.rating} />
            <meta name="resource-type" content={config.seo.resourceType} />
            <meta name="revisit-after" content={config.seo.revisitAfter} />
            <meta name="bulletin-text" content={config.seo.bulletinText} />
            <meta name="language" content={config.seo.language} />

            {/* OPEN GRAPH */}
            <meta property="og:title" content={fullPageTitle || config.seo.title} />
            <meta property="og:description" content={description || config.seo.description} />
            <meta property="og:type" content={config.seo.type} />
            <meta property="og:url" content={window.location.href.replace(window.location.search, '')} />
            <meta property="og:image" content={img || concatUrl([config.cdn.baseUrl, config.seo.image.url])} />
        </Helmet>
    );
};
