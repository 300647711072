import { useState, useCallback, useEffect } from 'react';
import { useInfiniteScroll } from './use-infinite-scroll';

export const useInfiniteScrollPaging = <T>(
    items: T[],
    pagination: number,
    triggerServerPaginationCommand?: VoidFunction,
): [T[]] => {
    const [itemList, setItemList] = useState<T[]>([]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        setItemList(Array.from(Array(count).keys(), n => items[n]));
    }, [items, count]);

    useEffect(() => {
        setCount(items.length < pagination ? items.length : pagination);
    }, [items, pagination]);

    const loadMoreItems = useCallback((): void => {
        if (count < items.length) {
            const addCount = count + pagination < items.length ? pagination : items.length - count;
            const newcount = count + addCount;
            setCount(newcount);
        } else if (triggerServerPaginationCommand) {
            triggerServerPaginationCommand();
        }
    }, [items, count, pagination, triggerServerPaginationCommand]);

    useInfiniteScroll(loadMoreItems);
    return [itemList];
};

export default useInfiniteScrollPaging;
