import React from 'react';
import { Route as BaseRoute } from 'react-router-dom';

import { LayoutableRouteProps } from './layoutable-route-type';

export const LayoutableRoute: React.FC<LayoutableRouteProps> = ({ layout: Layout, ...rest }: LayoutableRouteProps) => {
    if (Layout) {
        return (
            <Layout>
                <BaseRoute {...rest} />
            </Layout>
        );
    }
    return <BaseRoute {...rest} />;
};
