import queryString from 'query-string';
import { config } from '../../../../../config';
import { restClient } from '.';
import { EmailValidation, EmailContact } from '../../../models';

export const utilsService = {
    validateEmail: async (email: string, culture?: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const queryParams: any = {
            email,
        };

        if (culture) {
            queryParams.culture = culture;
        }

        const response = await restClient.get<EmailValidation>(
            `${config.backend.rest.listUrl}/utils/EmailIsValid?${queryString.stringify(queryParams)}`,
        );

        return response.data;
    },
    contact: async (data: EmailContact) => {
        await restClient.post<EmailContact>(`${config.backend.rest.listUrl}/utils/contact`, data);
    },
};
