import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { RootState } from '../../../redux/store';
import { tracerModule } from '../../../redux';
import { TracerConsent } from '../../../models/tracer';

import { TracerAdminPanelStateProps, TracerAdminPanelDispatchProps } from './tracer-admin-panel-type';

const mapStateToProps = (states: RootState): TracerAdminPanelStateProps => {
    const subState = states.tracer;
    return {
        isVisible: subState && subState.adminPanelEnabled,
        consents: subState && subState.consents ? subState.consents : [],
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TracerAdminPanelDispatchProps => ({
    acceptAll: () => dispatch(tracerModule.action.consents.acceptAll()),
    rejectAll: () => dispatch(tracerModule.action.consents.rejectAll()),
    updateAllConsents: (consents: TracerConsent[]) => dispatch(tracerModule.action.consents.updateAll(consents)),
    hideAdminPanel: () => dispatch(tracerModule.action.adminPanel.hide()),
});

const reduxConnect = connect<TracerAdminPanelStateProps, TracerAdminPanelDispatchProps, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
);

const enhancer = reduxConnect;

export default enhancer;
