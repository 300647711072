import { connect } from 'react-redux';
import { globalLoaderModule, GlobalLoadingState } from '../../redux';
import { StateProps } from './global-loader-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (states: any): StateProps => {
    const subState = states[globalLoaderModule.storeName] as GlobalLoadingState;
    return {
        count: subState.count,
    };
};

const enhancer = connect(mapStateToProps);
export default enhancer;
