import { Dispatch, AnyAction, MiddlewareAPI } from 'redux';
import TagManager from 'react-gtm-module';

import { config } from 'config';

import { NavigationElement } from 'modules/shared/models';
import { TracerTypeValues } from 'modules/shared/models/tracer';
import { UPDATE_NAVIGATION, trackingModule } from '../modules';
import { RootState } from '../store';

export const trackingMiddleware = ({ getState, dispatch }: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch) => (
    action: AnyAction,
) => {
    switch (action.type) {
        case UPDATE_NAVIGATION:
            {
                const store = getState();
                const navigationState = store.navigation;
                const tracerState = store.tracer;
                const trackingState = store.tracking;

                if (action.payload.currentPage === navigationState.currentPage) {
                    break;
                }

                let datalayerRest = {};

                if (trackingState.datalayer) {
                    Object.keys(trackingState.datalayer).forEach(key => {
                        datalayerRest = { ...datalayerRest, [key]: undefined };
                    });
                }

                if (
                    !tracerState.consents ||
                    tracerState.consents.length === 0 ||
                    !tracerState.consents.some(t => t.type === TracerTypeValues.PERFORMANCE_TRACER_TYPE && t.value)
                ) {
                    TagManager.dataLayer({ dataLayer: { ...datalayerRest } });
                    if (trackingState.datalayer) {
                        dispatch(trackingModule.action.updateDatalayer(undefined));
                    }
                    break;
                }

                let newPage: NavigationElement | undefined = action.payload.currentPage as NavigationElement;
                let layers: { name: string; type: string; indexed?: boolean }[] = [];

                if (newPage) {
                    if (newPage.name) {
                        layers.push({ name: newPage.name, type: 'page' });
                        if (newPage.additionalType) {
                            layers.push({ name: newPage.additionalType.value, type: newPage.additionalType.typeName });
                        }
                    }

                    while (newPage.parent) {
                        if (newPage.parent.name) {
                            layers.push({ name: newPage.parent.name, type: 'niveau', indexed: true });
                        }

                        newPage = newPage.parent;
                    }
                }

                layers = layers.reverse();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let dataLayer: any = { ...datalayerRest, ...config.tagManager.datalayer };
                for (let i = 0; i < layers.length; i += 1) {
                    const currentLayer = layers[i];
                    dataLayer = {
                        ...dataLayer,
                        [`${currentLayer.type}${currentLayer.indexed ? i + 1 : ''}`]: currentLayer.name,
                    };
                }

                TagManager.dataLayer({ dataLayer });
                dispatch(trackingModule.action.updateDatalayer(dataLayer));
            }
            break;
        default:
            break;
    }

    return next(action);
};
