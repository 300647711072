import { Action, AnyAction } from 'redux';

import { tracerConsentService } from '../../../../services/tracer-consent-service';
import { TracerState } from '..';

export const INITIALIZE_TRACERS = 'INITIALIZE_TRACERS';

interface InitializeTracersAction extends Action {
    type: typeof INITIALIZE_TRACERS;
}

export type InitializeAction = InitializeTracersAction;

const initializeAction = (): InitializeTracersAction => {
    return {
        type: INITIALIZE_TRACERS,
    };
};

function initializeReducer(state: TracerState, action: AnyAction): TracerState {
    switch (action.type) {
        case INITIALIZE_TRACERS:
            return { ...state, consents: tracerConsentService.getAll() };
        default:
            return state;
    }
}

export const initializeModule = {
    reducer: initializeReducer,
    action: {
        initialize: initializeAction,
    },
};
