import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store';

import {
    SUCCESS_LOGIN,
    SUCCESS_REGISTER,
    REQUEST_LOGOUT,
    SUCCESS_INITIALIZE,
} from '../modules/authentication/sub-modules';

import {
    SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY,
    SUCCESS_INITIALIZE_BUFFER_LIST,
    SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY,
    ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY,
    refreshListModule,
    refreshListIntervalModule,
    initializeBufferListModule,
    updateRemoteElementsQuantityModule,
    updateRemoteElementsQuantityTimeoutModule,
    clearListModule,
} from '../modules/list/sub-modules';

import { ON_IDLE_START, ON_IDLE_END } from '../modules';

export const listMiddleware = ({ dispatch, getState }: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch) => (
    action: AnyAction,
) => {
    const thunkDispatch = dispatch as ThunkDispatch<RootState, {}, AnyAction>;
    switch (action.type) {
        case SUCCESS_LOGIN:
        case SUCCESS_REGISTER:
        case SUCCESS_INITIALIZE:
            if (action.payload.token && action.payload.loggedInFolder) {
                thunkDispatch(initializeBufferListModule.action.initializeBufferList(action.payload.loggedInFolder.id));
            }
            break;
        case REQUEST_LOGOUT:
            // Fin polling
            dispatch(refreshListIntervalModule.action.endRefreshListInterval());

            // Synchronisation de la liste tampon
            dispatch(updateRemoteElementsQuantityTimeoutModule.action.stopUpdateRemoteElementsQuantityTimeout());
            thunkDispatch(updateRemoteElementsQuantityModule.action.updateRemoteElementsQuantity()).finally(() => {
                // Vidage de l'état
                dispatch(clearListModule.action.clearList());
            });
            break;
        case SUCCESS_UPDATE_LOCAL_ELEMENTS_QUANTITY:
            dispatch(
                updateRemoteElementsQuantityTimeoutModule.action.startUpdateRemoteElementsQuantityTimeout(() =>
                    thunkDispatch(updateRemoteElementsQuantityModule.action.updateRemoteElementsQuantity()),
                ),
            );

            // Désactivation polling le temps de l'ajout
            dispatch(refreshListIntervalModule.action.endRefreshListInterval());
            break;
        case SUCCESS_UPDATE_REMOTE_ELEMENTS_QUANTITY:
        case ERROR_UPDATE_REMOTE_ELEMENTS_QUANTITY:
            // Réactivation polling
            dispatch(
                refreshListIntervalModule.action.startRefreshListInterval(() =>
                    thunkDispatch(refreshListModule.action.refreshList()),
                ),
            );
            break;
        case SUCCESS_INITIALIZE_BUFFER_LIST:
            // Demarrage polling
            thunkDispatch(refreshListModule.action.refreshList());
            dispatch(
                refreshListIntervalModule.action.startRefreshListInterval(() =>
                    thunkDispatch(refreshListModule.action.refreshList()),
                ),
            );
            break;
        case ON_IDLE_START:
            // Désactivation polling si inactivité
            dispatch(refreshListIntervalModule.action.endRefreshListInterval());
            break;
        case ON_IDLE_END:
            {
                // Réactivation polling si necessaire
                const state = getState();
                if (!state.list.refreshInterval && state.authentication.token && state.authentication.token.isValid) {
                    // Demarrage polling
                    thunkDispatch(refreshListModule.action.refreshList());
                    dispatch(
                        refreshListIntervalModule.action.startRefreshListInterval(() =>
                            thunkDispatch(refreshListModule.action.refreshList()),
                        ),
                    );
                }
            }
            break;
        default:
            break;
    }

    return next(action);
};
