import { config } from '../../../config';
import { concatUrl } from '.';

export const getAssetsUrl = (fileName: string | undefined): string | undefined => {
    if (!fileName) {
        return fileName;
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    return concatUrl([config.cdn.baseUrl, '/assets/images', fileName]);
};

export const getPlistesCDNUrl = (fileName: string | undefined): string | undefined => {
    if (!fileName) {
        return fileName;
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    if (fileName.indexOf('/PLISTES') > -1) {
        return concatUrl([config.cdn.baseUrl, fileName]);
    }

    return concatUrl([config.cdn.baseUrl, '/PLISTES', fileName]);
};

export const getCatalogueCDNUrl = (fileName: string | undefined): string | undefined => {
    if (!fileName) {
        return fileName;
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    return getPlistesCDNUrl(concatUrl(['/img/catalogue', fileName]));
};

export const getProductCDNUrl = (fileName: string | undefined): string | undefined => {
    if (!fileName) {
        return fileName;
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    return getCatalogueCDNUrl(concatUrl(['/produits', fileName]));
};

export const getBrandCDNUrl = (fileName: string | undefined): string | undefined => {
    if (!fileName) {
        return fileName;
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    return getCatalogueCDNUrl(concatUrl(['/marques', fileName]));
};

export const getMenuCDNUrl = (fileName: string | undefined): string | undefined => {
    if (!fileName) {
        return fileName;
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    return getCatalogueCDNUrl(concatUrl(['/menus', fileName]));
};

export const getProfilCDNUrl = (folderId: number): string => {
    if (!folderId) {
        return `${folderId}`;
    }

    return getPlistesCDNUrl(concatUrl(['/img/espace_perso/ImagesEspacePerso', `${folderId}.jpg`])) || `${folderId}`;
};

export const getPCOMCDNUrl = (fileName: string | undefined): string => {
    if (!fileName) {
        return '';
    }

    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
        return fileName;
    }

    return concatUrl([config.cdn.baseUrl, '/WPCOM', fileName]);
};

export const getContractCDNUrl = (): string => {
    return concatUrl([config.cdn.baseUrl, '/Commun/pdf/Contrat.pdf']);
};
