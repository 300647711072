import gql from 'graphql-tag';

export const QUERY = gql([
    `
    query FindHomeContent {
        queryHomeContents {
            data {
                pageTitle { iv }

                imageBlocList: largeBlocs {
                    iv {
                        title
                        subTitle
                        textColor
                        ctaLink
                        ctaText
                        ctaStyle
                        picture {
                            url
                            fileSize
                        }
                    }
                }

                mention { iv }
                mentionFontSize { iv }
                mentionTextColor { iv }

                seoDescription { iv }
                seoKeywords { iv }
                seoThumbnail {
                    iv {
                        url
                    }
                }
            }
        }
    }
`,
]);
