import { PureComponent } from 'react';

import { ScrollRestorationProps } from './scroll-restoration-type';

import enhancer from './scroll-restoration-enhancer';

class InnerScrollRestoration extends PureComponent<ScrollRestorationProps, { yposition?: number }> {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    componentDidUpdate = ({ location: prevLocation }: ScrollRestorationProps) => {
        const { location } = this.props;
        if (location.pathname !== prevLocation.pathname) window.scrollTo(0, 0);
    };

    render = () => {
        const { children } = this.props;
        return children;
    };
}

export const ScrollRestoration = enhancer(InnerScrollRestoration);
