import React from 'react';
import { Route as BaseRoute } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { navigationModule } from '../../../redux';

import { NotifiedRouteProps } from './notified-route-type';

export const NotifiedRoute: React.FC<NotifiedRouteProps> = ({
    navigationTree,
    layout: Layout,
    ...rest
}: NotifiedRouteProps) => {
    const dispatch = useDispatch();
    dispatch(navigationModule.action.updateNavigation(navigationTree));

    if (Layout) {
        return (
            <Layout>
                <BaseRoute {...rest} />
            </Layout>
        );
    }
    return <BaseRoute {...rest} />;
};
