import { config } from '../../../../../config';
import { securedRestClient } from '.';
import { UpdateSponsorship } from '../../../models';

export const sponsorshipService = {
    UpdateSponsorship: async (data: UpdateSponsorship) => {
        const response = await securedRestClient.post<void>(`${config.backend.rest.listUrl}/sponsorship`, data);
        return response.data;
    },
};
