import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindBannerGroupContent($filter: String!) {
    bannerGroups : queryBannerGroupContents(top: 100, skip: 0, filter: $filter) {
        id
        data {
            banners {
                iv { 
                    id
                    data {
                        text { iv }
                        backgroundColor { iv }
                        textColor { iv }
                        startDate { iv }
                        endDate { iv }
                        linkText { iv }
                        linkUrl { iv }
                        linkStyle { iv }
                    }
                }
            }
        }
    }
}
`,
]);
