import { Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import reduceReducers from 'reduce-reducers';

import { FastUpsertListElement } from '../../../models';
import { RootState } from '../../store';
import { Module } from '../../utils/type';

import {
    refreshListModule,
    refreshListIntervalModule,
    updateLocalElementsQuantityModule,
    UpdateLocalElementsQuantityAction,
    updateRemoteElementsQuantityModule,
    updateRemoteElementsQuantityTimeoutModule,
    initializeBufferListModule,
    clearListModule,
} from './sub-modules';
import { ListState, initialState } from './list-state';

export interface ListActionDescriptor {
    updateElementsQuantity: (
        updatedElements: FastUpsertListElement[],
    ) => ThunkAction<void, RootState, {}, UpdateLocalElementsQuantityAction>;
}

export const listModule: Module<ListState, ListActionDescriptor> = {
    reducer: reduceReducers<ListState>(
        initialState,
        refreshListModule.reducer,
        refreshListIntervalModule.reducer,
        updateLocalElementsQuantityModule.reducer,
        updateRemoteElementsQuantityModule.reducer,
        updateRemoteElementsQuantityTimeoutModule.reducer,
        initializeBufferListModule.reducer,
        clearListModule.reducer,
    ) as Reducer<ListState>,
    action: {
        updateElementsQuantity: updateLocalElementsQuantityModule.action.updateLocalElementsQuantity,
    },
    storeName: 'list',
    workInProgress: (state: ListState): boolean =>
        state.isUpdating || (state.bufferList && state.bufferList.length > 0),
};
