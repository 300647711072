import React, { useState, useEffect } from 'react';
import { Label, Popup } from 'vendors/semantic-ui';
import { Link } from 'react-router-dom';

import { routes } from 'config';
import { ListButtonProps } from './list-button-type';

import enhancer from './list-button-enhancer';

import styles from './list-button-component.module.css';

const InnerListButton: React.FC<ListButtonProps> = ({
    productCount,
    productQuantity,
    isAuthenticated,
}: ListButtonProps) => {
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [previousProductCount, setPreviousProductCount] = useState<number | null>(null);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (previousProductCount !== null) {
            if (productCount && productCount > 0 && productCount > previousProductCount) {
                setShowNotification(true);
                timeout = setTimeout(() => {
                    setShowNotification(false);
                }, 2500);
            } else {
                setShowNotification(false);
            }
        }

        if (productCount !== null) {
            setPreviousProductCount(productCount);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [productCount]);

    if (!isAuthenticated) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <Popup
                header="Gérer ma liste"
                basic
                trigger={
                    <Link className={styles.listBtn} to={routes.espacePerso.client.maListe.gerer}>
                        <Label circular className={`${styles.counter} ${showNotification ? styles.expanded : ''}`}>
                            <span>{productQuantity || 0}</span>
                            {showNotification && <span className={styles.productAddedAlert}>produit ajouté</span>}
                        </Label>
                    </Link>
                }
            />
        </React.Fragment>
    );
};

export const ListButton = enhancer(InnerListButton);
