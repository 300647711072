import { Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import reduceReducers from 'reduce-reducers';

import { Module } from '../../utils/type';
import { RegisterFolder } from '../../../models';

import {
    loginModule,
    LoginAction,
    registerModule,
    RegisterAction,
    logoutModule,
    LogoutAction,
    initializeModule,
    InitializeAction,
} from './sub-modules';
import { AuthenticationState } from './authentication-state';

const initialState: AuthenticationState = {
    loggedInFolder: null,
    token: null,
    error: null,
    isLoading: false,
    logoutRequested: false,
};

interface AuthenticationActionDescriptor {
    login: (folderIdOrEmail: string, password: string) => ThunkAction<Promise<void>, {}, {}, LoginAction>;
    requestLogout: () => LogoutAction;
    logout: () => LogoutAction;
    register: (registerFolderInfos: RegisterFolder) => ThunkAction<Promise<void>, {}, {}, RegisterAction>;
    initialize: () => ThunkAction<Promise<void>, {}, {}, InitializeAction>;
}

export const authenticationModule: Module<AuthenticationState, AuthenticationActionDescriptor> = {
    reducer: reduceReducers<AuthenticationState>(
        initialState,
        loginModule.reducer,
        registerModule.reducer,
        logoutModule.reducer,
        initializeModule.reducer,
    ) as Reducer<AuthenticationState>,
    action: {
        login: loginModule.action.login,
        requestLogout: logoutModule.action.requestLogout,
        logout: logoutModule.action.logout,
        register: registerModule.action.register,
        initialize: initializeModule.action.initialize,
    },
    storeName: 'authentication',
};
