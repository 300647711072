import React from 'react';

import { LargeBlocList } from 'modules/shared-cms/components';

import { MentionContainer } from 'modules/shared-cms-advanced';
import { HomeProps } from './home-type';

import enhancer from './home-enhancer';

import styles from './home-page.module.css';

const InnerHomePage: React.FC<HomeProps> = React.memo(({ ...rest }: HomeProps) => {
    return (
        <div className={styles.rootContainer}>
            <LargeBlocList {...rest} />

            <div className={styles.mentions}>
                <MentionContainer {...rest} />
            </div>
        </div>
    );
});

export const HomePage = enhancer(InnerHomePage);
