import React from 'react';
import styles from './light-title-component.module.css';
import { LightTitleProps } from './light-title-type';

export const LightTitle: React.FC<LightTitleProps> = ({
    title,
    subtitle,
    bordered,
    centered,
    uppercase,
}: LightTitleProps) => {
    const wrapperClass = `${styles.pageTitle} ${centered ? styles.center : ''} ${uppercase ? styles.upper : ''}`;
    const mainClass = `${styles.title} ${!subtitle && bordered ? styles.border : ''}`;
    const subClass = `${styles.subtitle} ${subtitle && bordered ? styles.border : ''}`;

    return (
        <React.Fragment>
            <div className={wrapperClass}>
                {title && <div className={mainClass}>{title}</div>}
                {subtitle && <div className={subClass}>{subtitle}</div>}
            </div>
        </React.Fragment>
    );
};
