import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Breadcrumb as BaseBreadcrumb } from 'vendors/semantic-ui';
import { Link } from 'react-router-dom';

import { RootState } from '../../redux/store';
import { NavigationElement } from '../../models';

import styles from './breadcrumb-component.module.css';

interface Section {
    href?: string;
    content?: string;
    active: boolean;
    link: boolean;
}

export const Breadcrumb: React.FC = React.memo(() => {
    const currentPage = useSelector<RootState, NavigationElement | undefined>(state => state.navigation.currentPage);

    if (!currentPage) {
        return <React.Fragment />;
    }

    const sections: Section[] = [];

    let navigationTree: NavigationElement | undefined = currentPage;
    do {
        if (navigationTree.name && !navigationTree.isModule) {
            sections.push({
                content: navigationTree.name,
                href: navigationTree.path,
                active: navigationTree.path === currentPage.path,
                link: navigationTree.path !== currentPage.path,
            });
        }

        navigationTree = navigationTree.parent;
    } while (navigationTree);

    return (
        <div className="elementNotToPrint">
            <Container fluid id={styles['breadcrumb-container']}>
                <BaseBreadcrumb>
                    {sections.reverse().map((s, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={index}>
                                {s.href && !s.active && (
                                    <React.Fragment>
                                        <BaseBreadcrumb.Section>
                                            <Link to={s.href}>{s.content}</Link>
                                        </BaseBreadcrumb.Section>
                                        <BaseBreadcrumb.Divider icon="right angle" />
                                    </React.Fragment>
                                )}
                                {s.active && <BaseBreadcrumb.Section active={s.active} content={s.content} />}
                            </React.Fragment>
                        );
                    })}
                </BaseBreadcrumb>
            </Container>
        </div>
    );
});
