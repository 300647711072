import React, { ComponentType } from 'react';

import { Omit } from '../../types/type-utils';

export const WithPropertyMerge = <AdditionalProps extends {}>(additionalProps: AdditionalProps) => {
    return <P extends AdditionalProps>(Component: ComponentType<P>) => {
        type WrapperType = Omit<P, keyof AdditionalProps>;
        const WithPropertyMergeComponent: React.FC<WrapperType> = (props: WrapperType) => {
            const innerProps = { ...props, ...additionalProps };
            return <Component {...(innerProps as P)} />;
        };

        return WithPropertyMergeComponent;
    };
};
