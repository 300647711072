import React from 'react';

const isReactElement = (
    toBeDetermined: Exclude<React.ReactNode, boolean | null | undefined>,
): toBeDetermined is React.ReactElement => {
    if (typeof (toBeDetermined as React.ReactElement).type !== 'undefined') {
        return true;
    }
    return false;
};

// TODO : mettre un commentaire
export const flattenChildren = (children: React.ReactElement[]): React.ReactElement[] => {
    const result: React.ReactElement[] = [];
    return React.Children.toArray(children)
        .filter(isReactElement)
        .reduce((flatChildren, child): React.ReactElement[] => {
            if (child.type === React.Fragment) {
                return flatChildren.concat(flattenChildren(child.props.children));
            }
            flatChildren.push(child);
            return flatChildren;
        }, result);
};
