import React from 'react';

import { getDefaultUrlPicture, readCMSValue } from 'modules/shared-cms/utils';

import { CtaImageProps } from './cta-image-type';

import styles from './cta-image-component.module.css';

export const CtaImage: React.FC<CtaImageProps> = React.memo(({ link, target, picture }: CtaImageProps) => {
    const linkValue = readCMSValue(link);
    const targetValue = readCMSValue(target);
    const pictureValue = getDefaultUrlPicture(picture);
    if (linkValue) {
        return (
            <a href={linkValue} target={targetValue}>
                {pictureValue && <img src={pictureValue} alt="" className={styles.img} />}
            </a>
        );
    }
    return pictureValue ? <img src={pictureValue} alt="" className={styles.img} /> : <></>;
});
