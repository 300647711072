/* eslint-disable react/no-danger */
import React from 'react';
import { HtmlContainerProps } from './html-container-type';

import styles from './html-container-component.module.css';

export const HtmlContainer: React.FC<HtmlContainerProps> = ({
    htmlContent,
    node = 'div',
    useDefaultClass = true,
    className = '',
    style,
}: HtmlContainerProps) => {
    let rootNode;
    if (node === 'div') {
        rootNode = (
            <div
                style={style}
                className={(useDefaultClass ? `${styles.htmlContainer} ` : '') + className}
                dangerouslySetInnerHTML={htmlContent ? { __html: htmlContent } : undefined}
            />
        );
    } else if (node === 'h1') {
        rootNode = (
            <h1
                style={{ fontFamily: 'UrbanistRegular, Arial, Helvetica, sans-serif', fontWeight: 'normal', ...style }}
                className={(useDefaultClass ? `${styles.htmlContainer} ` : '') + className}
                dangerouslySetInnerHTML={htmlContent ? { __html: htmlContent } : undefined}
            />
        );
    } else {
        rootNode = (
            <p
                style={style}
                className={(useDefaultClass ? `${styles.htmlContainer} ` : '') + className}
                dangerouslySetInnerHTML={htmlContent ? { __html: htmlContent } : undefined}
            />
        );
    }
    return rootNode;
};
