import { AnyAction, Store } from 'redux';
import { RootState } from '../store';
import { idleModule } from '../modules';

export const eventList = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mouseWheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
];

export const idleWatcher = (
    store: Store<RootState, AnyAction> & {
        dispatch: unknown;
    },
): void => {
    let timeout: NodeJS.Timeout;
    eventList.map(eventName =>
        document.addEventListener(eventName, () => {
            if (store.getState().idle.isIdle) {
                store.dispatch(idleModule.action.onIdleEnd());
            } else {
                if (timeout) {
                    clearTimeout(timeout);
                }

                timeout = setTimeout(() => store.dispatch(idleModule.action.onIdleStart()), 60000);
            }
        }),
    );
};
