import { Country, State } from '../models';
import { SelectControlOption } from '../types';

export const getCountryAndStateText = (country: Country, state?: State, language?: 'fr-FR' | 'en-US'): string => {
    let text = !language || language === 'fr-FR' ? country.fr : country.en;
    if (state) {
        text += `, ${!language || language === 'fr-FR' ? state.fr : state.en}`;
    }

    return text;
};

export const getConvertedCountryStatesOptions = (
    states: State[],
    language?: 'fr-FR' | 'en-US',
): SelectControlOption[] => {
    return states.map((state, index) => {
        return {
            key: `${index}`,
            value: `${state.fr.toUpperCase()}`,
            text: !language || language === 'fr-FR' ? state.fr : state.en,
        };
    });
};
