import { CMSImageContainerField, CMSContainerField } from '../cms-base-type';
import { readCMSValue } from './read-cms-value';

export const getDefaultUrlPicture = (
    picture?: CMSImageContainerField[] | CMSContainerField<CMSImageContainerField[]> | undefined,
): string | undefined => {
    const pic = readCMSValue(picture);
    if (pic && pic.length > 0 && pic[0].url) {
        const urlWithouParameters = pic[0].url.split('?')[0];

        return urlWithouParameters;
    }
    return undefined;
};
