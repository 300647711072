import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { PrivateRouteProps } from './private-route-type';
import { Unhautorized } from '../../unhautorized';

import enhancer from './private-route-enhancer';

export const privateRoute: React.FC<PrivateRouteProps> = ({
    isAuthenticated,
    folder,
    authenticationPath,
    allowedFolderType,
    render,
    component: Component,
    ...rest
}: PrivateRouteProps) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (!isAuthenticated || !folder) {
                    if (!authenticationPath) {
                        return <Unhautorized />;
                    }

                    return (
                        <Redirect
                            to={{
                                pathname: authenticationPath,
                                search: `redirectPath=${rest.path}`,
                            }}
                        />
                    );
                }

                if (allowedFolderType && folder.type !== allowedFolderType) {
                    return <Unhautorized />;
                }

                if (Component) {
                    return <Component {...props} />;
                }

                if (render) {
                    return render(props);
                }

                return <React.Fragment />;
            }}
        />
    );
};

export const PrivateRoute = enhancer(privateRoute);
