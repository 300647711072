import queryString from 'query-string';
import { config } from '../../../../../config';
import { restClient } from '.';
import {
    LocalizableLightFolder,
    PublicFolder,
    PublicDetailedFolder,
    SearchPublicFolder,
    GetPublicFolder,
    SearchResult,
    PublicFolderPicture,
} from '../../../models';
import { formatTechDate } from '../../../utils';

export const publicFolderService = {
    search: async ({ lastName, firstName, isWedding, privateId, date }: SearchPublicFolder) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const queryParams: any = {
            lastName,
        };

        if (firstName) {
            queryParams.firstName = firstName;
        }

        if (date) {
            queryParams.date = formatTechDate(date);
        }

        if (isWedding !== undefined) {
            queryParams.isWedding = isWedding;
        }

        if (privateId) {
            queryParams.privateId = privateId;
        }

        const response = await restClient.get<SearchResult<LocalizableLightFolder>>(
            `${config.backend.rest.listUrl}/publicfolder?${queryString.stringify(queryParams)}`,
        );

        if (response.data && response.data.items && response.data.items.length > 0) {
            for (let i = 0; i < response.data.items.length; i += 1) {
                response.data.items[i].eventDate = new Date(response.data.items[i].eventDate);
            }
        }

        return response.data;
    },

    get: async ({ id }: GetPublicFolder) => {
        const response = await restClient.get<PublicFolder>(`${config.backend.rest.listUrl}/publicfolder/${id}`);
        return response.data;
    },

    getDetailed: async ({ id }: GetPublicFolder) => {
        const response = await restClient.get<PublicDetailedFolder>(
            `${config.backend.rest.listUrl}/publicfolder/${id}/detailed`,
        );

        return response.data;
    },

    getProfilePicture: async ({ id }: GetPublicFolder) => {
        const response = await restClient.get<PublicFolderPicture>(
            `${config.backend.rest.listUrl}/publicfolder/${id}/profilepicture`,
        );

        return response.data ? response.data.base64 : '';
    },
};
