import { AnyAction } from 'redux';
import { Module } from '../../utils/type';

export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';

const updateHeaderHeight = (data: number): AnyAction => {
    return {
        type: UPDATE_HEADER_HEIGHT,
        payload: {
            headerHeight: data,
        },
    };
};

function layoutReducer(state: LayoutState = { headerHeight: 0 }, action: AnyAction): LayoutState {
    switch (action.type) {
        case UPDATE_HEADER_HEIGHT:
            return { ...state, headerHeight: action.payload.headerHeight };
        default:
            return state;
    }
}

export interface LayoutState {
    headerHeight: number;
}

export interface LayoutActionDescriptor {
    updateHeaderHeight: (data: number) => AnyAction;
}

export const layoutModule: Module<LayoutState, LayoutActionDescriptor> = {
    storeName: 'layout',
    action: {
        updateHeaderHeight,
    },
    reducer: layoutReducer,
};
