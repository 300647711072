import { Action, AnyAction } from 'redux';

import { ListState, initialState } from '../list-state';

const SUCCESS_CLEAR_LIST = 'SUCCESS_CLEAR_LIST';

interface SuccessClearListAction extends Action {
    type: typeof SUCCESS_CLEAR_LIST;
}

export type ClearListAction = SuccessClearListAction;

const successClearListAction = (): SuccessClearListAction => {
    return {
        type: SUCCESS_CLEAR_LIST,
    };
};

function clearListReducer(state: ListState, action: AnyAction): ListState {
    switch (action.type) {
        case SUCCESS_CLEAR_LIST: {
            return { ...initialState };
        }
        default:
            return state;
    }
}

export const clearListModule = {
    action: {
        clearList: successClearListAction,
    },
    reducer: clearListReducer,
};
