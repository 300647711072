import { AnyAction } from 'redux';
import { Module } from '../../utils/type';

export const ON_IDLE_START = 'ON_IDLE_START';
export const ON_IDLE_END = 'ON_IDLE_END';

const onIdleStart = (): AnyAction => {
    return {
        type: ON_IDLE_START,
    };
};

const onIdleEnd = (): AnyAction => {
    return {
        type: ON_IDLE_END,
    };
};
function idleReducer(state: IdleState = { isIdle: false }, action: AnyAction): IdleState {
    switch (action.type) {
        case ON_IDLE_START:
            return { ...state, isIdle: true };
        case ON_IDLE_END:
            return { ...state, isIdle: false };
        default:
            return state;
    }
}

export interface IdleState {
    isIdle: boolean;
}

export interface IdleActionDescriptor {
    onIdleStart: () => AnyAction;
    onIdleEnd: () => AnyAction;
}

export const idleModule: Module<IdleState, IdleActionDescriptor> = {
    storeName: 'idle',
    action: {
        onIdleStart,
        onIdleEnd,
    },
    reducer: idleReducer,
};
