import productsListItemFr from 'modules/shared-lists/components/products-list/product-list-item-infos/locales/default.json';
import productsListItemEn from 'modules/shared-lists/components/products-list/product-list-item-infos/locales/default.en.json';

import languageSelection from 'modules/dons/components/language-selection/locales/default.json';
import landingFr from 'modules/dons/pages/landing/locales/default.json';
import landingEn from 'modules/dons/pages/landing/locales/default.en.json';
import searchListResultFr from 'modules/dons/pages/search-list-result/locales/default.json';
import searchListResultEn from 'modules/dons/pages/search-list-result/locales/default.en.json';
import complementarySearchFr from 'modules/dons/pages/complementary-search/locales/default.json';
import complementarySearchEn from 'modules/dons/pages/complementary-search/locales/default.en.json';
import selectGiftFr from 'modules/dons/pages/select-gift/locales/default.json';
import selectGiftEn from 'modules/dons/pages/select-gift/locales/default.en.json';
import recapGiftFr from 'modules/dons/pages/recap-gift/locales/default.json';
import recapGiftEn from 'modules/dons/pages/recap-gift/locales/default.en.json';
import contactInformationsFr from 'modules/dons/pages/contact-informations/locales/default.json';
import contactInformationsEn from 'modules/dons/pages/contact-informations/locales/default.en.json';
import recapContactInformationsFr from 'modules/dons/pages/recap-contact-informations/locales/default.json';
import recapContactInformationsEn from 'modules/dons/pages/recap-contact-informations/locales/default.en.json';
import paymentFr from 'modules/dons/pages/payment/locales/default.json';
import paymentEn from 'modules/dons/pages/payment/locales/default.en.json';
import paymentConfirmationFr from 'modules/dons/pages/payment-confirmation/locales/default.json';
import paymentConfirmationEn from 'modules/dons/pages/payment-confirmation/locales/default.en.json';
import giftDetailsFr from 'modules/dons/components/gift-details/locales/default.json';
import giftDetailsEn from 'modules/dons/components/gift-details/locales/default.en.json';
import giftSummaryFr from 'modules/dons/components/gift-summary/locales/default.json';
import giftSummaryEn from 'modules/dons/components/gift-summary/locales/default.en.json';
import en from './default.en.json';
import fr from './default.json';
/* Shared */
fr['fr-FR'].shared.languageSelection = languageSelection;
fr['fr-FR'].shared.product.productsList.item = productsListItemFr;
en.en.shared.product.productsList.item = productsListItemEn;

/* Dons */
fr['fr-FR'].dons.landing = landingFr;
en.en.dons.landing = landingEn;
fr['fr-FR'].dons.searchListResult = searchListResultFr;
en.en.dons.searchListResult = searchListResultEn;
fr['fr-FR'].dons.complementarySearch = complementarySearchFr;
en.en.dons.complementarySearch = complementarySearchEn;
fr['fr-FR'].dons.selectGift = selectGiftFr;
en.en.dons.selectGift = selectGiftEn;
fr['fr-FR'].dons.recapGift = recapGiftFr;
en.en.dons.recapGift = recapGiftEn;
fr['fr-FR'].dons.contactInformations = contactInformationsFr;
en.en.dons.contactInformations = contactInformationsEn;
fr['fr-FR'].dons.recapContactInformations = recapContactInformationsFr;
en.en.dons.recapContactInformations = recapContactInformationsEn;
fr['fr-FR'].dons.payment = paymentFr;
en.en.dons.payment = paymentEn;
fr['fr-FR'].dons.paymentConfirmation = paymentConfirmationFr;
en.en.dons.paymentConfirmation = paymentConfirmationEn;
fr['fr-FR'].dons.giftDetails = giftDetailsFr;
en.en.dons.giftDetails = giftDetailsEn;
fr['fr-FR'].dons.giftSummary = giftSummaryFr;
en.en.dons.giftSummary = giftSummaryEn;

export { fr, en };
