import { PageTitleProps } from 'modules/shared-cms/cms-base-type';
import { readCMSValue } from './read-cms-value';

export const readPageTitle = (data?: PageTitleProps): string | undefined => {
    if (data) {
        const { pageTitle } = data;
        return readCMSValue(pageTitle);
    }

    return undefined;
};
