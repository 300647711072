import { FastUpsertListElement, LightList, LightListElement } from '../../../../models';

export const mergeBufferList = (
    oldBufferList: FastUpsertListElement[],
    newBufferList: FastUpsertListElement[],
): FastUpsertListElement[] => {
    if (!newBufferList) {
        return oldBufferList;
    }

    if (!oldBufferList) {
        return newBufferList;
    }

    // On repush les éléments dans le SAS
    oldBufferList.forEach(element => {
        if (newBufferList.findIndex(b => b.variantId === element.variantId) === -1) {
            newBufferList.push(element);
        }
    });

    return newBufferList;
};

export const updateBufferList = (
    currentList: LightList | null,
    bufferList: FastUpsertListElement[],
    elementsToUpdate: FastUpsertListElement[],
): FastUpsertListElement[] => {
    if (!elementsToUpdate || elementsToUpdate.length === 0) {
        return bufferList;
    }

    const currentBufferList = bufferList;
    const currentElements = currentList && currentList.elements ? currentList.elements : [];
    elementsToUpdate.forEach(elementToUpdate => {
        const bufferElementIndex = currentBufferList.findIndex(e => e.variantId === elementToUpdate.variantId);
        const listElementIndex = currentElements.findIndex(e => e.variantId === elementToUpdate.variantId && !!e.id);
        if (bufferElementIndex > -1) {
            if (elementToUpdate.quantity === 0 && listElementIndex === -1) {
                currentBufferList.splice(bufferElementIndex, 1);
            } else {
                currentBufferList[bufferElementIndex] = elementToUpdate;
            }
        } else {
            currentBufferList.push(elementToUpdate);
        }
    });

    return currentBufferList;
};

export const mergeLocalList = (
    currentList: LightList | null,
    elementsToUpdate: FastUpsertListElement[],
): LightList | null => {
    if (!elementsToUpdate || elementsToUpdate.length === 0) {
        return currentList;
    }

    const elements = currentList && currentList.elements ? currentList.elements : [];

    elementsToUpdate.forEach(elementToUpdate => {
        const existingElement = elements.find(e => e.variantId === elementToUpdate.variantId);
        if (existingElement) {
            existingElement.quantity = elementToUpdate.quantity;
        } else {
            elements.push({
                quantity: elementToUpdate.quantity,
                variantId: elementToUpdate.variantId,
                reservedQuantity: 0,
            });
        }
    });

    return {
        elements,
    };
};

export const updateLocalList = (localList: LightList | null, updatedElements: LightListElement[]): LightList | null => {
    if (!updatedElements) {
        return localList;
    }

    if (!localList || !localList.elements) {
        return {
            elements: updatedElements,
        };
    }

    updatedElements.forEach(e => {
        const existingElement = localList.elements.find(ee => e.variantId === ee.variantId);
        if (existingElement) {
            existingElement.id = e.id;
            existingElement.quantity = e.quantity;
        } else {
            localList.elements.push(e);
        }
    });

    return localList;
};
