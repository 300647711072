import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Header, Icon } from 'vendors/semantic-ui';
import { AlertProps, AlertState } from './alert-type';

import styles from './alert-component.module.css';

let resolve: (result: boolean) => void;
const defaultProps = {
    title: 'Information',
    message: '',
};

class AlertDialog extends Component<AlertProps, AlertState> {
    static create(): AlertDialog {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        const element = React.createRef<AlertDialog>();
        ReactDOM.render(<AlertDialog ref={element} />, containerElement);
        if (!element.current) {
            throw new Error('Error on alert initialisation');
        }

        return element.current;
    }

    constructor(props: AlertProps) {
        super(props);
        this.state = {
            isOpen: false,
            message: defaultProps.message,
        };
    }

    handleConfirm = (): void => {
        this.setState({ isOpen: false });
        resolve(true);
    };

    show = (message: string): Promise<boolean> => {
        this.setState({ isOpen: true, message });
        return new Promise(res => {
            resolve = res;
        });
    };

    render = (): JSX.Element => {
        const { isOpen, message } = this.state;
        return (
            <Modal size="small" open={isOpen}>
                <Header icon="info circle" content={defaultProps.title} className={styles.header} />
                <Modal.Content>
                    <p className={styles.msg}>{message}</p>
                </Modal.Content>
                <Modal.Actions className={styles.actions}>
                    <button type="button" className={styles.positiveButton} onClick={this.handleConfirm}>
                        <Icon name="checkmark" /> Ok
                    </button>
                </Modal.Actions>
            </Modal>
        );
    };
}

export default AlertDialog;
