import { cmsAuthenticationService } from './cms-authentication-service';
import { AuthToken } from '../../../../../models';
import { config } from 'config';
import { concatUrl } from 'modules/shared/utils';

export const cmsTokenService = {
    getToken: async (): Promise<string> => {
        const tokenValue = localStorage.getItem('cms_access_token');
        if (!tokenValue) {
            const token = await cmsAuthenticationService.authenticate();
            localStorage.setItem('cms_access_token', JSON.stringify(token));
            return token.value;
        }

        const token = JSON.parse(tokenValue) as AuthToken;
        let tokenContent = null;
        try {
            tokenContent = JSON.parse(atob(token.value.split('.')[1]));
        } catch (e) {}

        const issUrls = [config.cms.baseUrl].concat(config.cms.infraUrl ? [config.cms.infraUrl] : []).map(u => concatUrl([u, config.cms.auth.issUrl]))
        if (new Date(token.expiration) < new Date() || (issUrls && issUrls.length > 0 && (!tokenContent || !tokenContent.iss || issUrls.indexOf(tokenContent.iss.toLowerCase()) === -1))) {
            const refreshedToken = await cmsAuthenticationService.authenticate();
            localStorage.setItem('cms_access_token', JSON.stringify(refreshedToken));
            return refreshedToken.value;
        }

        return token.value;
    },
};
