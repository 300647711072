import React, { ComponentType, useState, useCallback, useMemo, useRef, useEffect } from 'react';

import { Omit } from '../../types/type-utils';

export interface WithSessionStorageInjectedProps<T> {
    sessionData: T;
    updateSessionData: (updatedData: Partial<T> | null) => void;
    clearSessionData: () => void;
}

export const WithSessionStorage = <T extends {}>(sessionKey: string) => {
    return <P extends WithSessionStorageInjectedProps<T>>(Component: ComponentType<P>) => {
        type WrapperType = Omit<P, keyof WithSessionStorageInjectedProps<T>>;
        const WithSessionStorageComponent: React.FC<WrapperType> = (props: WrapperType) => {
            const [isFirstRun, setIsFirstRun] = useState(true);
            const lastSessionData = useRef<T | null>(null);
            const [sessionData, setSessionData] = useState<T | null>(null);
            useEffect(() => {
                const initialValueStr = sessionStorage.getItem(sessionKey);
                if (initialValueStr) {
                    const initialValue = JSON.parse(initialValueStr) as T;
                    if (initialValue) {
                        lastSessionData.current = initialValue;
                        setSessionData(initialValue);
                    }
                }

                setIsFirstRun(false);
            }, []);

            const updateSessionData = useCallback((updatedData: Partial<T> | null) => {
                const updatedSessionData = { ...lastSessionData.current, ...updatedData };
                lastSessionData.current = updatedSessionData as T;
                setSessionData(updatedSessionData as T);
                if (!lastSessionData.current) {
                    sessionStorage.removeItem(sessionKey);
                } else {
                    sessionStorage.setItem(sessionKey, JSON.stringify(lastSessionData.current));
                }
            }, []);

            const clearSessionData = useCallback(() => {
                setSessionData(null);
                lastSessionData.current = null;
                sessionStorage.removeItem(sessionKey);
            }, []);

            const innerProps = useMemo(() => {
                return { ...props, sessionData, updateSessionData, clearSessionData };
            }, [props, sessionData, updateSessionData, clearSessionData]);

            if (isFirstRun) {
                return <React.Fragment />;
            }
            return <Component {...(innerProps as P)} />;
        };

        return WithSessionStorageComponent;
    };
};
