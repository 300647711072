/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Redirect } from 'react-router-dom';

import { Omit, pick } from '../../types/type-utils';
import { PagingParams, PaginatedList } from '../../models';
import { ErrorMessage, LocalLoader } from '../../components';

import { Error } from '../../models/error';

interface InnerStatelessDatasFetchingComponentProps<T, P> {
    Component: ComponentType<P>;
    LoaderComponent: React.FunctionComponent | undefined;
    props: P;
    remoteFetchAction: () => Promise<T> | T;
    updateDataAction?: (data: T) => Promise<T> | T;
    notFoundBehavior: 'error' | 'redirect' | 'ignore';
    outputFieldName: string | number | symbol;
    resetFieldName?: string | number | symbol;
    updateDataFieldName?: string | number | symbol;
    errorMessages?: { title: string; message: string; code: number }[];
}

function InnerStatelessDatasFetchingComponent<T, P>({
    Component,
    LoaderComponent,
    outputFieldName,
    resetFieldName,
    updateDataFieldName,
    props,
    notFoundBehavior,
    remoteFetchAction,
    updateDataAction,
    errorMessages,
}: InnerStatelessDatasFetchingComponentProps<T, P>): React.ReactElement<
    InnerStatelessDatasFetchingComponentProps<T, P>
> {
    const [data, setData] = useState<T | null>(null);
    const isInitialized = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | string | null>('');

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async (): Promise<void> => {
            if (isSubscribed) {
                setIsLoading(true);
            }
            try {
                const result = await remoteFetchAction();
                if (isSubscribed) {
                    setData(result);
                }
            } catch (err) {
                if (isSubscribed) {
                    setError(err);
                }
            } finally {
                isInitialized.current = true;
            }

            if (isSubscribed) {
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            isSubscribed = false;
        };
    }, [remoteFetchAction]);

    const reloadData = useCallback(
        async (bypassLoading: boolean = false): Promise<void> => {
            if (!bypassLoading) {
                setIsLoading(true);
            }
            try {
                const result = await remoteFetchAction();
                setData(result);
            } catch (err) {
                setError(err);
            }

            if (!bypassLoading) {
                setIsLoading(false);
            }
        },
        [remoteFetchAction],
    );

    const updateData = useCallback(
        async (bypassLoading: boolean = false): Promise<void> => {
            if (!bypassLoading) {
                setIsLoading(true);
            }

            if (!data || !updateDataAction) {
                await reloadData();
                return;
            }

            try {
                const result = await updateDataAction(data);
                setData(result);
            } catch (err) {
                setError(err);
            }

            if (!bypassLoading) {
                setIsLoading(false);
            }
        },
        [reloadData, updateDataAction, data],
    );

    if (error && error !== '') {
        if (typeof error === 'string') {
            return <ErrorMessage error={error} />;
        }

        if (error.code === 404 && notFoundBehavior === 'redirect') {
            return <Redirect to="/nofound" />;
        }

        if (error.code !== 404 || notFoundBehavior === 'error') {
            if (errorMessages && errorMessages.length > 0) {
                const errorMessage = errorMessages.find(e => e.code === error.subCode);
                if (errorMessage) {
                    return <ErrorMessage title={errorMessage.title} error={errorMessage.message} />;
                }
            }

            return <ErrorMessage error={error.message} />;
        }
    }

    if (isLoading || !isInitialized.current) {
        if (LoaderComponent) {
            return <LoaderComponent />;
        }

        return <LocalLoader />;
    }

    const dataProps = { [outputFieldName]: data };
    let resetProps;
    if (resetFieldName) {
        resetProps = { [resetFieldName]: reloadData };
    }

    let updateDataProps;
    if (updateDataFieldName) {
        updateDataProps = { [updateDataFieldName]: updateData };
    }

    return <Component {...props} {...dataProps} {...resetProps} {...updateDataProps} />;
}

export const WithStatelessDatasFetching = <T, InjectedProps>(
    remoteFetchAction: () => Promise<T> | T,
    LoaderComponent: React.FunctionComponent | undefined,
    notFoundBehavior: 'error' | 'redirect' | 'ignore',
    outputFieldName: keyof InjectedProps,
    resetFieldName?: keyof InjectedProps,
    errorMessages?: { title: string; message: string; code: number }[],
) => {
    return <P extends InjectedProps>(
        Component: ComponentType<P>,
    ): React.FunctionComponent<Omit<P, keyof InjectedProps>> => (props: Omit<P, keyof InjectedProps>) => {
        return (
            <InnerStatelessDatasFetchingComponent
                Component={Component}
                LoaderComponent={LoaderComponent}
                props={props as any}
                remoteFetchAction={remoteFetchAction}
                notFoundBehavior={notFoundBehavior}
                outputFieldName={outputFieldName}
                resetFieldName={resetFieldName}
                errorMessages={errorMessages}
            />
        );
    };
};

export const WithStatelessDatasFetchingByParameters = <T, InjectedProps, SearchParamsProps>(
    remoteFetchAction: (params: SearchParamsProps) => Promise<T> | T,
    LoaderComponent: React.FunctionComponent | undefined,
    notFoundBehavior: 'error' | 'redirect' | 'ignore',
    outputFieldName: keyof InjectedProps,
    searchFieldNames: (keyof SearchParamsProps)[],
    resetFieldName?: keyof InjectedProps,
    waitForSearchParams?: boolean,
    checkParams?: (searchProps: SearchParamsProps) => boolean,
    errorMessages?: { title: string; message: string; code: number }[],
) => {
    return <P extends InjectedProps>(
        Component: ComponentType<P>,
    ): React.FunctionComponent<Omit<P, keyof InjectedProps> & SearchParamsProps> => {
        const WithStatelessDatasFetchingComponent: React.FC<Omit<P, keyof InjectedProps> & SearchParamsProps> = (
            props: Omit<P, keyof InjectedProps> & SearchParamsProps,
        ) => {
            const filterProps: SearchParamsProps = useMemo(() => pick(props, searchFieldNames) as SearchParamsProps, [
                props,
            ]);

            const remoteFetchActionWrapper = useCallback(() => {
                return remoteFetchAction(filterProps);
            }, [filterProps]);

            if (waitForSearchParams) {
                let missingParams = false;
                if (checkParams) {
                    missingParams = !checkParams(props);
                } else {
                    searchFieldNames.forEach(s => {
                        if (props[s] === undefined) {
                            missingParams = true;
                        }
                    });
                }

                if (missingParams) {
                    return <Component {...(props as any)} />;
                }
            }

            return (
                <InnerStatelessDatasFetchingComponent
                    Component={Component}
                    LoaderComponent={LoaderComponent}
                    props={props as any}
                    remoteFetchAction={remoteFetchActionWrapper}
                    notFoundBehavior={notFoundBehavior}
                    outputFieldName={outputFieldName}
                    resetFieldName={resetFieldName}
                    errorMessages={errorMessages}
                />
            );
        };

        return WithStatelessDatasFetchingComponent;
    };
};

export const WithStatelessDatasFetchingByParametersWithPaging = <
    T,
    U extends PaginatedList<T>,
    InjectedProps,
    SearchParamsProps
>(
    remoteFetchAction: (params: SearchParamsProps, paginParams: PagingParams) => Promise<U> | U,
    remoteFetchMoreAction: (params: SearchParamsProps, paginParams: PagingParams) => Promise<T[]> | T[],
    LoaderComponent: React.FunctionComponent | undefined,
    notFoundBehavior: 'error' | 'redirect' | 'ignore',
    outputFieldName: keyof InjectedProps,
    searchFieldNames: (keyof SearchParamsProps)[],
    fetchMoreFieldName: keyof InjectedProps,
    resetFieldName?: keyof InjectedProps,
    waitForSearchParams?: boolean,
    errorMessages?: { title: string; message: string; code: number }[],
) => {
    return <P extends InjectedProps>(
        Component: ComponentType<P>,
    ): React.FunctionComponent<Omit<P, keyof InjectedProps> & SearchParamsProps> => {
        const WithStatelessDatasFetchingComponent: React.FC<Omit<P, keyof InjectedProps> & SearchParamsProps> = (
            props: Omit<P, keyof InjectedProps> & SearchParamsProps,
        ) => {
            const filterProps: SearchParamsProps = useMemo(() => pick(props, searchFieldNames) as SearchParamsProps, [
                props,
            ]);

            const [serverPageIndex, setServerPageIndex] = useState(0);

            const remoteFetchActionWrapper = useCallback(() => {
                return remoteFetchAction(filterProps, { pageIndex: 0 });
            }, [filterProps]);

            const remoteFetchMoreActionWrapper = useCallback(
                async (data: U): Promise<U> => {
                    if (data && serverPageIndex === data.pageCount - 1) {
                        return data;
                    }

                    const currentItems = data ? data.items : [];
                    const newPageIndex = serverPageIndex + 1;
                    const nextItems = await remoteFetchMoreAction(filterProps, { pageIndex: newPageIndex });
                    setServerPageIndex(newPageIndex);
                    return {
                        ...data,
                        items: [...currentItems, ...nextItems],
                    };
                },
                [filterProps, serverPageIndex],
            );

            if (waitForSearchParams) {
                let missingParams = false;
                searchFieldNames.forEach(s => {
                    if (!props[s]) {
                        missingParams = true;
                    }
                });

                if (missingParams) {
                    return <Component {...(props as any)} />;
                }
            }

            return (
                <InnerStatelessDatasFetchingComponent
                    Component={Component}
                    LoaderComponent={LoaderComponent}
                    props={props as any}
                    remoteFetchAction={remoteFetchActionWrapper}
                    updateDataAction={remoteFetchMoreActionWrapper}
                    notFoundBehavior={notFoundBehavior}
                    outputFieldName={outputFieldName}
                    resetFieldName={resetFieldName}
                    updateDataFieldName={fetchMoreFieldName}
                    errorMessages={errorMessages}
                />
            );
        };

        return WithStatelessDatasFetchingComponent;
    };
};
