import React from 'react';

import { buildComponentArray } from 'modules/shared/utils';
import { readCMSValue } from '../../utils';

import { LargeBloc, LargeBlocProps, LargeBlocListContainer } from '..';
import { LargeBlocListProps } from './large-bloc-list-type';

export const LargeBlocList: React.FC<LargeBlocListProps> = React.memo(({ imageBlocList }: LargeBlocListProps) => {
    const uwImageBlocList = readCMSValue(imageBlocList);
    return (
        <LargeBlocListContainer>
            {uwImageBlocList &&
                buildComponentArray<LargeBlocProps>(
                    uwImageBlocList,
                    (childItem: LargeBlocProps, key: number): JSX.Element => {
                        return <LargeBloc {...childItem} key={key} />;
                    },
                )}
        </LargeBlocListContainer>
    );
});
