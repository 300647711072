import React, { useState, useEffect } from 'react';

import styles from './back-to-top-component.module.css';

export const BackToTop: React.FC = () => {
    const [visible, setVisible] = useState(false);

    const handleScroll = (): void => {
        if (window.pageYOffset > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = (): void => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="elementNotToPrint">
            {visible && <button type="button" className={styles.btn} onClick={scrollToTop} />}
        </div>
    );
};
