import React from 'react';
import { Responsive, Sidebar } from 'vendors/semantic-ui';

import { configUI } from '../../../../config/config-ui';
import { MenuProps } from './menu-type';
import { DesktopMenu, MobileMenu } from './responsive';

import styles from './menu-component.module.css';

export const Menu: React.FC<MenuProps> = React.memo(({ elements, visible, top, verticalDisplay }: MenuProps) => {
    return (
        <React.Fragment>
            <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                <nav className={styles.nav}>
                    <DesktopMenu elements={elements} top={top} verticalDisplay={verticalDisplay} />
                </nav>
            </Responsive>
            <Responsive as={React.Fragment} maxWidth={configUI.responsive.computer.minWidth}>
                <Sidebar animation="overlay" direction="top" visible={visible} className={styles.sideBar}>
                    <div className={styles.sideBarContent} style={{ paddingTop: top }}>
                        <div className={styles.mobileMenu}>
                            <MobileMenu elements={elements} />
                        </div>
                    </div>
                </Sidebar>
            </Responsive>
        </React.Fragment>
    );
});
