import axios from 'axios';

import { config } from 'config';
import { Error } from 'modules/shared/models';
import { concatUrl } from 'modules/shared/utils';

const restClient = axios.create({
    baseURL: concatUrl([config.backend.baseUrl, config.backend.rest.url]),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function errorResponseHandler(error: any): Promise<Error> {
    const returnedError: Error = {
        message: error.response && error.response.data ? error.response.data.content : error.message,
        code: error.response ? error.response.status : -1,
        subCode: error.response && error.response.data ? error.response.data.code : -1,
        subCodeLib: error.response && error.response.data ? error.response.data.title : -1,
    };

    return Promise.reject(returnedError);
}

restClient.interceptors.response.use(
    response => response,
    error => errorResponseHandler(error),
);

export { restClient };
