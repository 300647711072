import { Action, AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { dateIsExpiredBuilder } from 'modules/shared/utils';

import { globalLoaderModule } from '../../global-loader';
import { contextService } from '../../../../services';

import { logoutModule } from './logout-module';

import { AuthenticationState } from '../authentication-state';

import { AuthToken, LightFolder } from '../../../../models';

export const REQUEST_INITIALIZE = 'REQUEST_INITIALIZE';
export const SUCCESS_INITIALIZE = 'SUCCESS_INITIALIZE';
export const ERROR_INITIALIZE = 'ERROR_INITIALIZE';

interface RequestInitializeAction extends Action {
    type: typeof REQUEST_INITIALIZE;
}

interface SuccessInitializeAction extends Action {
    type: typeof SUCCESS_INITIALIZE;
    payload: { loggedInFolder: LightFolder | null; token: AuthToken | null };
}

interface ErrorInitializeAction extends Action {
    type: typeof ERROR_INITIALIZE;
    payload: { error: Error };
}

export type InitializeAction = RequestInitializeAction | SuccessInitializeAction | ErrorInitializeAction;

// Action creators
const requestAction = (): RequestInitializeAction => {
    return { type: REQUEST_INITIALIZE };
};

const successAction = (loggedInFolder: LightFolder | null, token: AuthToken | null): SuccessInitializeAction => {
    return {
        type: SUCCESS_INITIALIZE,
        payload: {
            loggedInFolder,
            token: token
                ? {
                      value: token.value,
                      expiration: token.expiration,
                      isValid: dateIsExpiredBuilder(token.expiration),
                  }
                : null,
        },
    };
};

const failureAction = (error: Error): ErrorInitializeAction => {
    return {
        type: ERROR_INITIALIZE,
        payload: {
            error,
        },
    };
};

const initializeAction = (): ThunkAction<Promise<void>, {}, {}, InitializeAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, InitializeAction>): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            globalLoaderModule.action.showLoading();

            dispatch(requestAction());

            try {
                const data = await contextService.getLoggedInUserInfos();
                dispatch(successAction(data ? data.folder : null, data && data.token ? data.token : null));
                resolve();
            } catch (error) {
                logoutModule.action.logout();
                dispatch(failureAction(error));
                reject(error);
            } finally {
                globalLoaderModule.action.hideLoading();
            }
        });
    };
};

function initializeReducer(state: AuthenticationState, action: AnyAction): AuthenticationState {
    switch (action.type) {
        case REQUEST_INITIALIZE:
            return { ...state, isLoading: true };
        case SUCCESS_INITIALIZE:
            return {
                ...state,
                isLoading: false,
                loggedInFolder: action.payload.loggedInFolder,
                token: action.payload.token,
            };
        case ERROR_INITIALIZE:
            return { ...state, isLoading: false, error: action.payload.error };
        default:
            return state;
    }
}

export const initializeModule = {
    reducer: initializeReducer,
    action: {
        initialize: initializeAction,
    },
};
