import { createStore, combineReducers, applyMiddleware, AnyAction, StoreEnhancer } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { connectRouter } from 'connected-react-router';
import logger from 'redux-logger';
import { history } from 'global';

import { bufferListService } from 'modules/shared/services';
import {
    globalLoaderModule,
    GlobalLoadingState,
    structureModule,
    FetchStructureState,
    navigationModule,
    NavigationState,
    authenticationModule,
    AuthenticationState,
    authenticationMiddleware,
    listModule,
    ListState,
    listMiddleware,
    layoutModule,
    LayoutState,
    tracerModule,
    TracerState,
    tracerMiddleware,
    cacheMiddleware,
    IdleState,
    idleModule,
    idleWatcher,
    trackingMiddleware,
    trackingModule,
    TrackingState,
} from '.';

export interface RootState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    router: any;
    globalLoader: GlobalLoadingState;
    structure: FetchStructureState;
    navigation: NavigationState;
    authentication: AuthenticationState;
    list: ListState;
    layout: LayoutState;
    tracer: TracerState;
    tracking: TrackingState;
    idle: IdleState;
}

const localApplyMiddleware = (): StoreEnhancer => {
    const middlewares = [
        thunk,
        authenticationMiddleware,
        listMiddleware,
        cacheMiddleware,
        tracerMiddleware,
        trackingMiddleware,
    ];
    if (process.env.REACT_APP_STAGE !== 'preprod' && process.env.REACT_APP_STAGE !== 'preprodbis' && process.env.REACT_APP_STAGE !== 'prod') {
        middlewares.push(logger);
    }

    return applyMiddleware(...middlewares);
};

export const store = createStore(
    combineReducers<RootState>({
        router: connectRouter(history),
        globalLoader: globalLoaderModule.reducer,
        structure: structureModule.reducer,
        navigation: navigationModule.reducer,
        authentication: authenticationModule.reducer,
        list: listModule.reducer,
        layout: layoutModule.reducer,
        tracer: tracerModule.reducer,
        tracking: trackingModule.reducer,
        idle: idleModule.reducer,
    }),
    localApplyMiddleware(),
);

store.subscribe(() => {
    const currentState = store.getState();
    const authenticationState = currentState.authentication;

    // On sauvegarde l'état de la collection buffer
    if (authenticationState && !!authenticationState.loggedInFolder) {
        bufferListService.update(authenticationState.loggedInFolder.id, store.getState().list.bufferList);
    }

    // Gestion de la déconnexion
    if (authenticationState.logoutRequested) {
        const canLogout = !listModule.workInProgress || !listModule.workInProgress(currentState.list);
        if (canLogout) {
            store.dispatch(authenticationModule.action.logout());
        }
    }
});

(store.dispatch as ThunkDispatch<RootState, {}, AnyAction>)(authenticationModule.action.initialize());
store.dispatch(tracerModule.action.initialize());
idleWatcher(store);
