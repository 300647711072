export const buildComponentArray = <T>(
    items: T[],
    itemRenderer: (item: T, key: number) => JSX.Element,
): JSX.Element[] => {
    const result: JSX.Element[] = [];
    if (items) {
        result.push(...items.map(itemRenderer));
    }

    return result;
};
