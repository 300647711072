import React from 'react';
import enhancer from './global-loader-enhancer';
import { StateProps } from './global-loader-type';
import styles from './global-loader-component.module.css';

const globalLoader: React.FC<StateProps> = React.memo(({ count }: StateProps) => {
    return (
        <React.Fragment>
            {count > 0 && (
                <div className={styles.loaderContainer}>
                    <div className={styles.loader} />
                </div>
            )}
        </React.Fragment>
    );
});

export const GlobalLoader = enhancer(globalLoader);
