import { Action, AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { dateIsExpiredBuilder } from 'modules/shared/utils';

import { globalLoaderModule } from '../../global-loader';
import { contextService } from '../../../../services';

import { AuthenticationResult } from '../../../../models/customer';
import { AuthenticationState } from '../authentication-state';

import { AuthToken, LightFolder, Error } from '../../../../models';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const ERROR_LOGIN = 'ERROR_LOGIN';

interface RequestLoginAction extends Action {
    type: typeof REQUEST_LOGIN;
}

interface SuccessLoginAction extends Action {
    type: typeof SUCCESS_LOGIN;
    payload: { loggedInFolder: LightFolder; token: AuthToken };
}

interface ErrorLoginAction extends Action {
    type: typeof ERROR_LOGIN;
    payload: { error: Error };
}

export type LoginAction = RequestLoginAction | SuccessLoginAction | ErrorLoginAction;

// Action creators
const requestAction = (): RequestLoginAction => {
    return { type: REQUEST_LOGIN };
};

const successAction = (authenticationResult: AuthenticationResult): SuccessLoginAction => {
    return {
        type: SUCCESS_LOGIN,
        payload: {
            loggedInFolder: authenticationResult.folder,
            token: {
                value: authenticationResult.accessToken,
                expiration: authenticationResult.expireAt,
                isValid: dateIsExpiredBuilder(authenticationResult.expireAt),
            },
        },
    };
};

const failureAction = (error: Error): ErrorLoginAction => {
    return {
        type: ERROR_LOGIN,
        payload: {
            error,
        },
    };
};

const loginAction = (folderIdOrEmail: string, password: string): ThunkAction<Promise<void>, {}, {}, LoginAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, LoginAction>): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            globalLoaderModule.action.showLoading();

            dispatch(requestAction());

            try {
                const data = await contextService.authenticateInternalUser(folderIdOrEmail, password);
                dispatch(successAction(data));
                resolve();
            } catch (error) {
                dispatch(failureAction(error));
                reject(error);
            } finally {
                globalLoaderModule.action.hideLoading();
            }
        });
    };
};

function loginReducer(state: AuthenticationState, action: AnyAction): AuthenticationState {
    switch (action.type) {
        case REQUEST_LOGIN:
            return { ...state, isLoading: true };
        case SUCCESS_LOGIN:
            return {
                ...state,
                isLoading: false,
                loggedInFolder: action.payload.loggedInFolder,
                token: action.payload.token,
            };
        case ERROR_LOGIN:
            return { ...state, isLoading: false, error: action.payload.error };
        default:
            return state;
    }
}

export const loginModule = {
    reducer: loginReducer,
    action: {
        login: loginAction,
    },
};
