import { config } from '../../../../../config';
import { restClient } from '.';
import { LightStore, Store, GetStore } from '../../../models';

export const storeService = {
    getAll: async () => {
        const response = await restClient.get<LightStore[]>(`${config.backend.rest.listUrl}/store`);
        return response.data;
    },
    get: async ({ id }: GetStore) => {
        const response = await restClient.get<Store>(`${config.backend.rest.listUrl}/Store/${id}`);
        return response.data;
    },
};
