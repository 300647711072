export const dateIsExpired = (date: Date): boolean => {
    if (!date) {
        return false;
    }

    const now = new Date();
    return date < now;
};

export const dateIsExpiredBuilder = (date: Date) => {
    return () => !!date && !dateIsExpired(date);
};

export const parseDate = (date: string): Date | undefined => {
    if (!date) {
        return undefined;
    }

    return new Date(date.split('T')[0]);
};

export const formatTechDate = (date: Date | string): string => {
    if (!date) {
        return '';
    }

    let dateVal: Date;
    if (typeof date === 'string') {
        dateVal = new Date(date);
    } else {
        dateVal = date;
    }

    return [dateVal.getFullYear(), `0${dateVal.getMonth() + 1}`.slice(-2), `0${dateVal.getDate()}`.slice(-2)].join('-');
};

export const formatTechTime = (date: Date | string): string => {
    if (!date) {
        return '';
    }

    let dateVal: Date;
    if (typeof date === 'string') {
        dateVal = new Date(date);
    } else {
        dateVal = date;
    }

    return `${formatTechDate(dateVal)}T${dateVal
        .getHours()
        .toString()
        .padStart(2, '0')}:${dateVal
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${dateVal
        .getSeconds()
        .toString()
        .padStart(2, '0')}Z`;
};

export const formatDate = (date: Date | string, language?: 'fr-FR' | 'en-US'): string => {
    if (!date) {
        return '';
    }

    let dateVal: Date;
    if (typeof date === 'string') {
        dateVal = new Date(date);
    } else {
        dateVal = date;
    }

    if (language === 'en-US') {
        return dateVal.toLocaleDateString('en');
    }

    return dateVal.toLocaleDateString('fr');
};

export const formatTime = (date: Date | string, options?: Intl.DateTimeFormatOptions): string => {
    if (!date) {
        return '';
    }

    let dateVal: Date;
    if (typeof date === 'string') {
        dateVal = new Date(date);
    } else {
        dateVal = date;
    }

    // TODO gerer la langue
    return dateVal.toLocaleTimeString('fr', options);
};

export const formatSimpleTime = (date: Date | string): string => {
    return formatTime(date, { hour: '2-digit', minute: '2-digit' });
};

export const formatCurrentDate = (): string => {
    return formatDate(new Date());
};

export const formatCurrentTime = (): string => {
    return formatTime(new Date());
};

export const calculateAge = (birthdate: Date | string): number => {
    const diff = Date.now() - new Date(birthdate).getTime();
    const age = new Date(diff);

    return Math.abs(age.getUTCFullYear() - 1970);
};
