/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { Popup } from 'vendors/semantic-ui';

import { routes } from 'config';

import { confirmService } from 'modules/shared-ui/components';

import { useRouter } from '../../../hooks';

import { UserButtonProps } from './user-button-type';

import styles from './user-button-component.module.css';

export const UserButton: React.FC<UserButtonProps> = ({ isAuthenticated, logout }: UserButtonProps) => {
    const { history } = useRouter();
    return (
        <Popup
            trigger={
                <button
                    type="button"
                    className={`${styles.userBtn} ${isAuthenticated ? styles.logged : ''}`}
                    onClick={async () => {
                        if (isAuthenticated) {
                            const result = await confirmService.show('Voulez-vous vraiment vous déconnecter ?');
                            if (result) {
                                logout();
                                history.push('/');
                            }
                        } else {
                            history.push(routes.espacePerso.login);
                        }
                    }}
                />
            }
            content={isAuthenticated ? 'Déconnexion' : 'Connexion'}
            basic
        />
    );
};
