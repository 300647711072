import * as React from 'react';
import { ErrorMessage } from '../error-message';

export interface ErrorBoundaryState {
    isError: boolean;
    errorInfo: React.ErrorInfo | null;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    constructor(p: {}) {
        super(p);
        this.state = { isError: false, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ isError: true, errorInfo });
    }

    render(): React.ReactNode {
        const { isError, errorInfo } = this.state;
        const { children } = this.props;

        if (isError) {
            const errorMessage = errorInfo ? errorInfo.componentStack : 'Error without info';
            return <ErrorMessage error={errorMessage} />;
        }

        return children;
    }
}
