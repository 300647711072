import * as React from 'react';

import { PageTitle } from 'modules/shared-ui/components';

import { ErrorMessageProps } from './error-message-type';

import styles from './error-message-component.module.css';

export const ErrorMessage: React.FC<ErrorMessageProps> = React.memo(
    ({ error, title = 'Une erreur est survenue' }: ErrorMessageProps) => {
        return (
            <div className={styles.rootContainer}>
                <div className={styles.container}>
                    <PageTitle title={title} />
                    <div>
                        <div className={styles.message}>{error}</div>
                    </div>
                </div>
            </div>
        );
    },
);
