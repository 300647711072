import { Reducer } from 'redux';
import reduceReducers from 'reduce-reducers';

import { Module } from '../../utils/type';
import { TracerConsent } from '../../../models/tracer';

import {
    initializeModule,
    InitializeAction,
    updateConsentModule,
    UpdateConsentAction,
    adminPanelModule,
    AdminPanelAction,
} from './sub-modules';
import { TracerState } from './tracer-state';

interface TracerActionDescriptor {
    initialize: () => InitializeAction;
    consents: {
        updateAll: (consents: TracerConsent[]) => UpdateConsentAction;
        acceptAll: () => UpdateConsentAction;
        rejectAll: () => UpdateConsentAction;
    };
    adminPanel: {
        show: () => AdminPanelAction;
        hide: () => AdminPanelAction;
    };
}

export const tracerModule: Module<TracerState, TracerActionDescriptor> = {
    reducer: reduceReducers<TracerState>(
        {
            consents: [],
            adminPanelEnabled: false,
        },
        initializeModule.reducer,
        updateConsentModule.reducer,
        adminPanelModule.reducer,
    ) as Reducer<TracerState>,
    action: {
        initialize: initializeModule.action.initialize,
        consents: {
            updateAll: updateConsentModule.action.updateAll,
            acceptAll: updateConsentModule.action.acceptAll,
            rejectAll: updateConsentModule.action.rejectAll,
        },
        adminPanel: {
            show: adminPanelModule.action.show,
            hide: adminPanelModule.action.hide,
        },
    },
    storeName: 'tracer',
};
