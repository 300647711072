import React, { useState } from 'react';
import { Modal, Checkbox } from 'vendors/semantic-ui';
import { HashLink } from 'react-router-hash-link';
import { TracerTypeValues } from '../../../models/tracer';
import { TracerAdminPanelProps } from './tracer-admin-panel-type';
import enhancer from './tracer-admin-panel-enhancer';

import tracerLabels from '../tracer.json';

import styles from './tracer-admin-panel-component.module.css';

const InnerTracerAdminPanel: React.FC<TracerAdminPanelProps> = ({
    isVisible,
    consents,
    acceptAll,
    rejectAll,
    updateAllConsents,
    hideAdminPanel,
}: TracerAdminPanelProps) => {
    const [localConsents, setlocalConsents] = useState(consents);

    if (!isVisible) {
        return <React.Fragment />;
    }

    return (
        <Modal open onClose={hideAdminPanel} size="small" closeIcon className={styles.tracerModal}>
            <Modal.Header>Gérer les préférences de cookies</Modal.Header>
            <Modal.Content>
                <p className={styles.message}>
                    Lorsque vous consultez notre site Web, des données peuvent être stockées dans votre navigateur ou
                    récupérées à partir de celui-ci par listes.printemps.com, généralement sous la forme de cookies. Ils
                    peuvent être activés par nos équipes, ou par des tiers dont les services sont utilisés sur les pages
                    de notre site Web. Ces informations peuvent porter sur vous, sur vos préférences ou sur votre
                    appareil et sont principalement utilisés pour s&apos;assurer que le site Web fonctionne
                    correctement. Les informations ne permettent généralement pas de vous indentifier directement, mais
                    peuvent vous permettre de bénéficier d&apos;une expérience Web personnalisée.
                    <br />
                    <br />
                    Parce que nous respectons votre droit à la vie privée, nous vous donnons la possibilité de ne pas
                    autoriser certains types de cookies qui ne sont pas strictement nécessaires au fonctionnement du
                    site.
                    <br />
                    <br />
                    Cliquez sur les différentes catégories pour obtenir plus de détails sur chacune d&apos;entre elles,
                    et modifier les paramètres par défaut. Toutefois, si vous bloquez certains types de cookies, votre
                    expérience de navigation et les services que nous sommes en mesure de vous offrir peuvent être
                    impactés.
                    <br />
                    <br />
                    Pour plus d&apos;information, consultez notre{' '}
                    <HashLink to="/mention-legale#gestion-des-cookies">
                        <span className={styles.underlined}>
                            <b>Politique de gestion des cookies</b>
                        </span>
                    </HashLink>
                    .
                </p>
                <div className={styles.tracersContainer}>
                    {Object.values(TracerTypeValues)
                        .filter(tr => tr !== TracerTypeValues.UKNOWN)
                        .map(tracer => {
                            // Pour compiler
                            if (!tracer) {
                                return <React.Fragment />;
                            }
                            const consent = localConsents ? localConsents.find(c => c.type === tracer) : undefined;
                            return (
                                <div className={styles.tracerContainer} key={tracer}>
                                    <HashLink to={`/centre-de-preferences#${tracerLabels[tracer].id}`}>
                                        <span className={styles.link}>{tracerLabels[tracer].title}</span>
                                    </HashLink>
                                    <Checkbox
                                        toggle
                                        indeterminate={consent && consent.value === undefined}
                                        checked={consent ? !!consent.value : true}
                                        disabled={!consent || consent.readonly}
                                        onChange={(_e, data) => {
                                            if (consent) {
                                                consent.value = data.checked;
                                                setlocalConsents([...localConsents]);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        })}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <button className={`${styles.button} ${styles.withBorder}`} type="button" onClick={rejectAll}>
                    Je refuse
                </button>
                <button
                    className={`${styles.button} ${styles.withBorder}`}
                    type="button"
                    onClick={() => {
                        updateAllConsents(consents);
                        hideAdminPanel();
                    }}
                >
                    Enregister
                </button>
                <button className={`${styles.button} ${styles.withBorder}`} type="button" onClick={acceptAll}>
                    J&apos;accepte tout
                </button>
            </Modal.Actions>
        </Modal>
    );
};

export const TracerAdminPanel = enhancer(InnerTracerAdminPanel);
