import React, { useState, CSSProperties } from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import defaultImg from 'assets/images/produit-indispo.png';

import styles from './loadable-image-component.module.css';

export const LoadableImage: React.FC<React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> & {
    placeholderClassName?: string;
    placeholderStyle?: CSSProperties;
}> = ({
    style,
    className,
    src,
    alt,
    placeholderClassName,
    placeholderStyle,
    onLoad,
    onError,
    ...rest
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    placeholderClassName?: string;
    placeholderStyle?: CSSProperties;
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const result = (
        <React.Fragment>
            {!isLoaded && !isError && (
                <Placeholder fluid style={{ width: '100%', height: '100%' }}>
                    <Placeholder.Image
                        className={`${className}${placeholderClassName ? ` ${placeholderClassName}` : ''}`}
                        style={{ ...style, ...placeholderStyle }}
                    />
                </Placeholder>
            )}
            <img
                {...rest}
                src={isError ? defaultImg : src}
                alt={alt}
                className={className}
                style={{
                    ...(style || {}),

                    display: isLoaded ? 'block' : 'none',
                    ...(isError ? { maxWidth: '50px', maxHeight: '50px' } : {}),
                }}
                onLoad={e => {
                    setIsLoaded(true);
                    if (onLoad) {
                        onLoad(e);
                    }
                }}
                onError={e => {
                    setIsError(true);
                    if (onError) {
                        onError(e);
                    }
                }}
            />
        </React.Fragment>
    );

    if (isError) {
        return (
            <div className={styles.errorImgContainer}>
                {result}
                {isError && <span>Image non trouvée</span>}
            </div>
        );
    }
    return result;
};
