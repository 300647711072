import React from 'react';

import styles from './separator-component.module.css';

export const Separator: React.FC = React.memo(() => {
    return (
        <div className={`${styles.separator}`}>
            <div className={styles.mask} />
        </div>
    );
});
