import { connect } from 'react-redux';
import { authenticationModule, AuthenticationState } from '../../../redux';
import { PrivateRouteStateProps, PrivateRouteOwnProps } from './private-route-type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (states: any): PrivateRouteStateProps => {
    const subState = states[authenticationModule.storeName] as AuthenticationState;
    return {
        isAuthenticated: !!subState.token && subState.token.isValid(),
        folder: subState.loggedInFolder,
    };
};

const enhancer = connect<PrivateRouteStateProps, undefined, PrivateRouteOwnProps>(mapStateToProps);
export default enhancer;
