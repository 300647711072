/* FolderState */
export type FolderState = 'A' | 'B' | 'F' | 'O' | 'T';
const ACTIVE: FolderState = 'A';
const BLOQUE: FolderState = 'B';
const FERME: FolderState = 'F';
const OUVERT: FolderState = 'O';
const TRANSFERT: FolderState = 'T';

export const FolderStateValues = {
    ACTIVE,
    BLOQUE,
    FERME,
    OUVERT,
    TRANSFERT,
};

/* FolderType */
export type FolderType = 0 | 1 | 2;
const UNKNOWN_FOLDER_TYPE: FolderType = 0;
const PROSPECT: FolderType = 1;
const CUSTOMER: FolderType = 2;

export const FolderTypeValues = {
    UNKNOWN: UNKNOWN_FOLDER_TYPE,
    PROSPECT,
    CUSTOMER,
};

/* UniverseType */
export type UniverseStrType = 'unknown' | 'mariage' | 'naissance' | 'cadeaux' | 'voyage';
export type UniverseIntType = 0 | 1 | 2 | 3 | 4;

const UNIVERS_UNKNOWN_STR: UniverseStrType = 'unknown';
const UNIVERS_MARIAGE_STR: UniverseStrType = 'mariage';
const UNIVERS_NAISSANCE_STR: UniverseStrType = 'naissance';
const UNIVERS_CADEAUX_STR: UniverseStrType = 'cadeaux';
const UNIVERS_VOYAGE_STR: UniverseStrType = 'voyage';

const UNIVERS_UNKNOWN_INT: UniverseIntType = 0;
const UNIVERS_MARIAGE_INT: UniverseIntType = 1;
const UNIVERS_NAISSANCE_INT: UniverseIntType = 2;
const UNIVERS_CADEAUX_INT: UniverseIntType = 3;
const UNIVERS_VOYAGE_INT: UniverseIntType = 4;

export const isUniverseStrType = (str: string): str is UniverseStrType => {
    return str === 'unknown' || str === 'mariage' || str === 'voyage' || str === 'naissance' || str === 'cadeaux';
};

export const readUniverseStrType = (str?: string): UniverseStrType | undefined => {
    if (!str) {
        return undefined;
    }

    if (isUniverseStrType(str)) {
        return str;
    }

    return undefined;
};

export const UniverseTypeValues = {
    UNIVERS_UNKNOWN: {
        valInt: UNIVERS_UNKNOWN_INT,
        valStr: UNIVERS_UNKNOWN_STR,
    },
    UNIVERS_MARIAGE: {
        valInt: UNIVERS_MARIAGE_INT,
        valStr: UNIVERS_MARIAGE_STR,
    },
    UNIVERS_NAISSANCE: {
        valInt: UNIVERS_NAISSANCE_INT,
        valStr: UNIVERS_NAISSANCE_STR,
    },
    UNIVERS_CADEAUX: {
        valInt: UNIVERS_CADEAUX_INT,
        valStr: UNIVERS_CADEAUX_STR,
    },
    UNIVERS_VOYAGE: {
        valInt: UNIVERS_VOYAGE_INT,
        valStr: UNIVERS_VOYAGE_STR,
    },
    getIntByString: (str: string): UniverseIntType | undefined => {
        const universeStr = readUniverseStrType(str);
        if (!universeStr) {
            return undefined;
        }

        switch (universeStr) {
            case UNIVERS_UNKNOWN_STR:
                return UNIVERS_UNKNOWN_INT;
            case UNIVERS_MARIAGE_STR:
                return UNIVERS_MARIAGE_INT;
            case UNIVERS_NAISSANCE_STR:
                return UNIVERS_NAISSANCE_INT;
            case UNIVERS_CADEAUX_STR:
                return UNIVERS_CADEAUX_INT;
            case UNIVERS_VOYAGE_STR:
                return UNIVERS_VOYAGE_INT;
            default:
                return undefined;
        }
    },
    getStringByInt: (n: number): UniverseStrType | undefined => {
        switch (n) {
            case UNIVERS_UNKNOWN_INT:
                return UNIVERS_UNKNOWN_STR;
            case UNIVERS_MARIAGE_INT:
                return UNIVERS_MARIAGE_STR;
            case UNIVERS_NAISSANCE_INT:
                return UNIVERS_NAISSANCE_STR;
            case UNIVERS_CADEAUX_INT:
                return UNIVERS_CADEAUX_STR;
            case UNIVERS_VOYAGE_INT:
                return UNIVERS_VOYAGE_STR;
            default:
                return undefined;
        }
    },
};

/* Civility */
export type Civility = 0 | 1 | 2 | 3 | 4;
type CivilityStr = 'inconnu' | 'mademoiselle' | 'madame' | 'monsieur' | 'autre';
type CivilityStrShort = '' | 'Mrs' | 'Ms' | 'Mr';

export const UNKNOWN_CIVILITY_TYPE: Civility = 0;
export const MISS: Civility = 1;
export const MRS: Civility = 2;
export const MR: Civility = 3;
export const OTHER: Civility = 4;

const UNKNOWN_CIVILITY_TYPE_STR: CivilityStr = 'inconnu';
const MISS_STR: CivilityStr = 'mademoiselle';
const MRS_STR: CivilityStr = 'madame';
const MR_STR: CivilityStr = 'monsieur';
const OTHER_STR: CivilityStr = 'autre';

const UNKNOWN_CIVILITY_TYPE_STR_SHORT: CivilityStrShort = '';
const MISS_STR_SHORT: CivilityStrShort = 'Ms';
const MRS_STR_SHORT: CivilityStrShort = 'Mrs';
const MR_STR_SHORT: CivilityStrShort = 'Mr';
const OTHER_STR_SHORT: CivilityStrShort = '';

export const isCivilityStrType = (str: string): str is CivilityStr => {
    return str === 'inconnu' || str === 'mademoiselle' || str === 'madame' || str === 'monsieur' || str === 'autre';
};

export const readCivilityStrType = (str?: string): CivilityStr | undefined => {
    if (!str) {
        return undefined;
    }

    if (isCivilityStrType(str)) {
        return str;
    }

    return undefined;
};
export const CivilityTypeValues = {
    UNKNOWN: UNKNOWN_CIVILITY_TYPE,
    MISS,
    MRS,
    MR,
    OTHER,

    getIntByString: (str: string): Civility | undefined => {
        const civilityStr = readCivilityStrType(str);
        if (!civilityStr) {
            return undefined;
        }

        switch (civilityStr) {
            case UNKNOWN_CIVILITY_TYPE_STR:
                return UNKNOWN_CIVILITY_TYPE;
            case MISS_STR:
                return MISS;
            case MRS_STR:
                return MRS;
            case MR_STR:
                return MR;
            case OTHER_STR:
                return OTHER;
            default:
                return undefined;
        }
    },
    getStringByInt: (valueInt: Civility | undefined): string => {
        switch (valueInt) {
            case UNKNOWN_CIVILITY_TYPE:
                return UNKNOWN_CIVILITY_TYPE_STR;
            case MISS:
                return MISS_STR;
            case MRS:
                return MRS_STR;
            case MR:
                return MR_STR;
            case OTHER:
                return OTHER_STR;
            default:
                return '';
        }
    },
    toString: (civility?: Civility): string => {
        switch (civility) {
            case UNKNOWN_CIVILITY_TYPE:
                return UNKNOWN_CIVILITY_TYPE_STR_SHORT;
            case MISS:
                return MISS_STR_SHORT;
            case MRS:
                return MRS_STR_SHORT;
            case MR:
                return MR_STR_SHORT;
            case OTHER:
                return OTHER_STR_SHORT;
            default:
                return '';
        }
    },
};

/* Denominator */
export type DenominatorType = 0 | 1 | 2 | 3;
const UNKNOWN_DENOMINATOR_TYPE: DenominatorType = 0;
const MARIED: DenominatorType = 1;
const PARENTS: DenominatorType = 2;
const RECIPIENT: DenominatorType = 3;

export const DenominatorTypeValues = {
    UNKNOWN: UNKNOWN_DENOMINATOR_TYPE,
    MARIED,
    PARENTS,
    RECIPIENT,
};

export type GiftState = 'A' | 'E' | 'G' | 'I' | 'P' | 'R' | 'T';
const ANNULE: GiftState = 'A';
const EMPORTE: GiftState = 'E';
const GENERE: GiftState = 'G';
const PERIME: GiftState = 'I';
const PAYE: GiftState = 'P';
const RESERVE: GiftState = 'R';
const EN_ATTENTE: GiftState = 'T';

export const GiftStateValues = {
    ANNULE,
    EMPORTE,
    GENERE,
    PERIME,
    PAYE,
    RESERVE,
    EN_ATTENTE,
};

export type OptinType = 0 | 1 | 2 | 3 | 4;
const UNKNOWN_OPTIN_TYPE: OptinType = 0;
const PRINTEMPS_LISTES: OptinType = 1;
const PRINTEMPS_VOYAGES: OptinType = 2;
const PRINTEMPS: OptinType = 3;
const PRINTEMPS_PARTENAIRES: OptinType = 4;

export const OptinTypeValues = {
    UNKNOWN: UNKNOWN_OPTIN_TYPE,
    PRINTEMPS_LISTES,
    PRINTEMPS_VOYAGES,
    PRINTEMPS,
    PRINTEMPS_PARTENAIRES,
};

export type OptinPreference = 0 | 1 | 2 | 3 | 4 | 5;
type OptinPreferenceStr = 'inconnu' | 'mariage' | 'naissance' | 'cadeaux' | 'voyage' | 'robes';
const UNKNOWN_OPTIN_PREFERENCE: OptinPreference = 0;
const MARIAGE: OptinPreference = 1;
const NAISSANCE: OptinPreference = 2;
const CADEAUX: OptinPreference = 3;
const VOYAGE: OptinPreference = 4;
const ROBES: OptinPreference = 5;

const UNKNOWN_OPTIN_PREFERENCE_TYPE_STR: OptinPreferenceStr = 'inconnu';
const MARIAGE_STR: OptinPreferenceStr = 'mariage';
const NAISSANCE_STR: OptinPreferenceStr = 'naissance';
const CADEAUX_STR: OptinPreferenceStr = 'cadeaux';
const VOYAGE_STR: OptinPreferenceStr = 'voyage';
const ROBES_STR: OptinPreferenceStr = 'robes';

export const isOptinPreferenceStrType = (str: string): str is OptinPreferenceStr => {
    return (
        str === UNKNOWN_OPTIN_PREFERENCE_TYPE_STR ||
        str === MARIAGE_STR ||
        str === NAISSANCE_STR ||
        str === CADEAUX_STR ||
        str === VOYAGE_STR ||
        str === ROBES_STR
    );
};
export const readOptinPreferenceStrType = (str?: string): OptinPreferenceStr | undefined => {
    if (!str) {
        return undefined;
    }

    if (isOptinPreferenceStrType(str)) {
        return str;
    }

    return undefined;
};

export const OptinPreferenceValues = {
    UNKNOWN: UNKNOWN_OPTIN_PREFERENCE,
    MARIAGE,
    NAISSANCE,
    CADEAUX,
    VOYAGE,
    ROBES,
    getIntByString: (str: string): OptinPreference | undefined => {
        const optinPreferenceStr = readOptinPreferenceStrType(str);
        if (!optinPreferenceStr) {
            return undefined;
        }

        switch (optinPreferenceStr) {
            case UNKNOWN_OPTIN_PREFERENCE_TYPE_STR:
                return UNKNOWN_OPTIN_PREFERENCE;
            case MARIAGE_STR:
                return MARIAGE;
            case NAISSANCE_STR:
                return NAISSANCE;
            case CADEAUX_STR:
                return CADEAUX;
            case VOYAGE_STR:
                return VOYAGE;
            case ROBES_STR:
                return ROBES;
            default:
                return undefined;
        }
    },
};
