import Responsive from 'semantic-ui-react/dist/commonjs/addons/Responsive';
import Select from 'semantic-ui-react/dist/commonjs/addons/Select';

import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';
import Checkbox, { CheckboxProps as LocalCheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import { DropdownProps as LocalDropDownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { DropdownItemProps as LocalDropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Breadcrumb from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb';

import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Placeholder from 'semantic-ui-react/dist/commonjs/elements/Placeholder';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';

export type CheckboxProps = LocalCheckboxProps;
export type DropdownProps = LocalDropDownProps;
export type DropdownItemProps = LocalDropdownItemProps;

export { Responsive, Select };

export { Sidebar, Popup, Modal, Checkbox, Dimmer };

export { Grid, Breadcrumb };

export { Container, Icon, Placeholder, Loader, Header, Label };
