import React, { useCallback, useState } from 'react';
import { KeyValueFilter } from '../../models';
import { Omit } from '../../types/type-utils';
import { WithFilteringInjectedProps } from '../types';

export const WithInMemoryFiltering = <T extends {} = string>() => <P extends WithFilteringInjectedProps<T>>(
    Component: React.ComponentType<P>,
) => {
    type WrapperType = Omit<P, keyof WithFilteringInjectedProps<T>>;
    const WithQuerystringFilteringComponent: React.FC<WrapperType> = (props: WrapperType) => {
        const [filters, setFilters] = useState<KeyValueFilter<T>[]>([]);

        const handleFiltersSelection = useCallback((updatedFilters: KeyValueFilter<T>[]): void => {
            setFilters(updatedFilters);
        }, []);

        return <Component {...(props as P)} filters={filters} handleFiltersSelection={handleFiltersSelection} />;
    };

    return WithQuerystringFilteringComponent;
};
